import React, { useLayoutEffect, useState } from 'react';
import { css } from '@emotion/core';
import useResizeObserver from 'use-resize-observer/polyfilled';
import VerticalStorytelling from './VerticalStorytelling';
import SvgDiv from './SvgDiv';

const textElement = css`
    padding-left: 0.8rem;
    transition: border-left 300ms ease-in-out, padding-left 300ms ease-in-out;
    border-left: 0.2rem solid rgb(255, 82, 82);
    &:hover {
      padding-left: 0.5rem;
      border-left: 0.5rem solid rgb(255, 82, 82);
    }
    background-color: transparent;
`;

const componentElement = css`
    margin-bottom: 25px;
`;

const titleElement = css`
    padding: 3px 6px;
    border-radius: 5px;
    transform: translate(0px, 4px);
    background-color: rgb(26, 29, 116);
    color: white;
    width: 150px;
    text-align: center;
    @media screen 
    and (min-width: 0px) 
    and (max-width: 480px) {
      visibility: hidden;
    }
`;
const rankElement = css`
    width: 32px;
    height: 32px;
    transform: translate(60px, 8px) rotate(45deg);
    background-color: #eaeaeb;
    border-radius: 3px;
    border: 2px solid rgb(26, 29, 116);
`;
const innerRankElement = css`
    color: rgb(255, 82, 82);
    text-align: center;
    font-size: 18px;
    text-anchor: middle;
    transform: translate(-1px,-3px) rotate(-45deg);
`;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};
const Title = ({ children }) => (
  <SvgDiv position={{ x: -76, y: -20 }}>
    {children}
  </SvgDiv>
);
const PmfTimeline = () => {
  const [isSmallScreen, setSmallScreen] = useState(false);
  useLayoutEffect(() => {
    const handleResize = () => {
      if (getWindowDimensions().width < 480) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const leftSectionWidth = isSmallScreen ? '60px' : '190px';
  const timelineX = isSmallScreen ? 30 : 90;

  const elements = [
    {
      title: <Title>
        <div css={rankElement}><div css={innerRankElement}>{1}</div></div>
        <div css={titleElement}>The survey</div>
      </Title>,
      components: <div css={componentElement}>
        <div css={textElement}>
          <div>Start a survey with your existing (and active) customers and ask them the following questions:</div>
          <br />
          <ol>
            <li>
              <b>How would you feel if you could no longer use 'Product name'? </b>
              <div style={{ color: 'rgb(26, 29, 116)' }}> • Very disappointed • Somewhat disappointed • Not disappointed</div>
            </li>
            <li><b>What type of people do you think would most benefit from 'Product name'?</b></li>
            <li><b>What is the main benefit you receive from 'Product name'?</b></li>
            <li><b>How can we improve 'Product name' for you?</b></li>
          </ol>
        </div>
        <div>
          Analyze the results, If more than 40% of your users would feel
          <span style={{ color: 'rgb(26, 29, 116)' }}> very disappointed </span>
            without your product, Congratulations 🏆, you've reached product market fit.
          - See
          <a href="https://www.startup-marketing.com/the-startup-pyramid/"> this blog post </a>
          by Sean Ellis
        </div>
      </div>,
    },
    {
      title: <Title>
        <div css={rankElement}><div css={innerRankElement}>{2}</div></div>
        <div css={titleElement}>Segmentation</div>
      </Title>,
      components: <div css={componentElement}>
        <div css={textElement}>
          Create categories for your respondents depending on what's relevant to your business. It can be the occupation of your respondents
          (CEO, manager, product manager, freelance, teacher ...).
          <br />
          Label the survey answers with these categories.
          <br />
          <br />
          Now, select the categories that maximize the
          <span style={{ color: 'rgb(26, 29, 116)' }}> very disappointed </span>
           response rate
          and minimize the
          <span style={{ color: 'rgb(26, 29, 116)' }}> Somewhat disappointed </span>
          and
          <span style={{ color: 'rgb(26, 29, 116)' }}> Not disappointed </span>
response rates.
          <br />
        </div>
        <br />
        <div>
          Let's focus on the group that would be
          <span style={{ color: 'rgb(26, 29, 116)' }}> Very disappointed </span>
            without your product.
          These are the customers which get the most value from your product.
          Analyzing their answers to questions
          <b> 2 </b>
            and
          <b> 3 </b>
            will give us valuable insights.
        </div>
      </div>,

    },
    {
      title: <Title>
        <div css={rankElement}><div css={innerRankElement}>{3}</div></div>
        <div css={titleElement}>High-expectation customers</div>
      </Title>,
      components: <div css={componentElement}>
        <div css={textElement}>
           Answers to question
          <b> 2 - What type of people do you think would most benefit from 'Product name'? </b>
          by customers that get the most value from our product
          will give us a description of our target market with words that resonate with them. These are our
          <a href="https://firstround.com/review/what-i-learned-from-developing-branding-for-airbnb-dropbox-and-thumbtack/"> High expectations customers </a>
          .
          You can use these descriptions in your marketing materials.
          <br />
        </div>
      </div>,
    },
    {
      title: <Title>
        <div css={rankElement}><div css={innerRankElement}>{4}</div></div>
        <div css={titleElement}>Main Benefits</div>
      </Title>,
      components: <div css={componentElement}>
        <div css={textElement}>
          Answers to question
          <b> 3 - What is the main benefit you receive from 'Product name'? </b>
will reveal the main benefits that make our customers stay.
          You can use a word cloud tool on the answers to immediately see the important benefits (
          <a href="https://worditout.com/word-cloud/create">Example tool</a>
)
        </div>
      </div>,
    },
    {
      title: <Title>
        <div css={rankElement}><div css={innerRankElement}>{5}</div></div>
        <div css={titleElement}>Missing features</div>
      </Title>,
      components: <div css={componentElement}>
        <div css={textElement}>
          In order to find our missing features, we need to analyze the answers to question
          <b> 4 - How can we improve 'Product name' for you? </b>
          from the group of people who like our product, and understand its benefits but have a blocker or are annoyed by something.
          How can we find these people ? We need to look at answers of customers that were
          <span style={{ color: 'rgb(26, 29, 116)' }}> Somewhat disappointed </span>
to lose the product (question 1)
           AND used the same words to describe the benefits of the product (question 3) as those who were
          <span style={{ color: 'rgb(26, 29, 116)' }}> Very disappointed</span>
.
        </div>
      </div>,
    },
    {
      title: <Title>
        <div css={rankElement}><div css={innerRankElement}>{6}</div></div>
        <div css={titleElement}>Roadmap</div>
      </Title>,
      components: <div css={componentElement}>
        <div css={textElement}>
           Now that we have all this information about our main benefits and our missing features, we can start making our Roadmap toward
          product-market fit. Rahul's advice is to spend half your time doubling down on what your customers already love and half the time on missing features.
        </div>
      </div>,
    },
    {
      title: <Title>
        <div css={rankElement}><div css={innerRankElement}>{7}</div></div>
        <div css={titleElement}>Track the metric</div>
             </Title>,
      components: <div css={componentElement}>
        <div css={textElement}>
            Do not stop here ! Iterate and keep interrogating your new customers while following the evolution of your new metric, which is the rate of
            customers that would be
          <span style={{ color: 'rgb(26, 29, 116)' }}> very disappointed </span>
without your product.
        </div>
      </div>,
    },
  ];
  return (
    <VerticalStorytelling
      elements={elements}
      leftSectionWidth={leftSectionWidth}
      timelineX={timelineX}
    />
  );
};

export default PmfTimeline;
