import loadable from '@loadable/component'
import React from 'react';

const DiagramViewerClientDataHistory = () => {
  const DiagramViewerComponentDataHistory = loadable(() => import('./DiagramViewerComponentDataHistory'))
  return (
    <DiagramViewerComponentDataHistory/>
  );
};

export default DiagramViewerClientDataHistory;
