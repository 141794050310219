const colors = {
  darkBlue: '#21212c',
  red: '#FF5252',
  white: '#fbfaf4',
  yellow: '#ffcd00',
  purple: '#EB80FC', // purple
  lightGreen: '#6BEFAE', // light green
  green: '#2bbc8a', // green
  blue: '#1a1d74', // green
};
export default colors;
