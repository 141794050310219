/* eslint-disable */
import * as d3 from 'd3';
import regression from 'regression';
import textures from 'textures';
import * as Bounce from 'bounce.js';

const datanice = {};
datanice.darkBlue = '#114B5F';
datanice.darkGreen = '#1A936F';
datanice.lightGreen = '#88D498';
datanice.lightBlue = '#6ACEEB';
datanice.grey = '#C6DABF';
datanice.lightGrey = '#F3E9D2';

function extend(ChildClass, ParentClass) {
  ChildClass.prototype = new ParentClass();
  ChildClass.prototype.constructor = ChildClass;
}

datanice.Parent = function () {
  this.param = 5;
};
datanice.Child = function () {
  datanice.Parent.call(this);
  this.param2 = 6;
};
extend(datanice.Child, datanice.Parent);


datanice.Plot = function () {

};
datanice.FunctionPlot = function () {

};
datanice.Grid = function (params, parent) {
  if (parent) {
    this.container = parent.container;
    this.svg = parent.svg;
  }
  this.params = params;
  this.setScale(this.params);
  this.setLine();
  const gElement = document.createElementNS(d3.namespaces.svg, 'g');
  this.element = d3.select(gElement).attr('transform', `translate(${this.params.margin.left},${this.params.margin.top})`);
  this.xAx = this.element.append('g');
  this.yAx = this.element.append('g');


  this.gridX = this.element.append('g');
  this.gridY = this.element.append('g');
  this.setAxis();


  this.lab = this.element.append('svg:text')
    .attr('class', '')
    .attr('text-anchor', 'middle')
    .style('height', 20)
    .attr('x', this.x_scale(4))
    .attr('y', this.params.h + this.params.margin.bottom - 5);

  this._setElement(this.element);
};


datanice.Grid.prototype.setScale = function () {
  this.x_scale = d3.scaleLinear()
    .range([0, this.params.w])
    .domain([0, this.params.maxX]);

  this.y_scale = d3.scaleLinear()
    .range([this.params.h, 0])
    .domain([0, this.params.maxY]);
};
datanice.Grid.prototype.setLine = function () {
  const { x_scale } = this;
  const { y_scale } = this;
  this.line = d3.line()
    .x((d) => x_scale(d[0]))
    .y((d) => y_scale(d[1]));
};

datanice.Grid.prototype.setAxis = function () {
  this.xAxis = d3.axisBottom()
    .scale(this.x_scale)
    .ticks(5);

  this.yAxis = d3.axisLeft()
    .scale(this.y_scale)
    .ticks(5);

  this.xAx
    .attr('class', 'x axis')
    .attr('transform', `translate(0,${this.params.h})`)
    .call(this.xAxis);

  this.yAx
    .attr('class', 'y axis')
    .call(this.yAxis);

  this.gridX
    .attr('class', 'grid')
    .attr('transform', `translate(0,${this.params.h})`)
    .call(this.xAxis
      .tickSize(-this.params.h, 0, 0)
      .tickFormat(''));
  if (this.hidingGridX) {
    this.gridX.style('opacity', 0);
    this.xAx.style('opacity', 0);
  }

  this.gridY
    .attr('class', 'grid')
    .call(this.yAxis
      .tickSize(-this.params.w, 0, 0)
      .tickFormat(''));

  if (this.hidingGridY) {
    this.gridY.style('opacity', 0);
    this.yAx.style('opacity', 0);
  }
};

datanice.Grid.prototype.hideGridX = function () {
  this.hidingGridX = true;
  // this.resize()
};

datanice.Grid.prototype.hideGridY = function () {
  this.hidingGridY = true;
  // this.resize()
};

datanice.Grid.prototype.getNodeFunction = function () {
  const node = this.element.node();
  return function () {
    return node;
  };
};

datanice.Grid.prototype._setElement = function (element) {
  this.element = element;
};

datanice.Grid.prototype.append = function (element) {
  return this.element.append(element);
};
datanice.Grid.prototype.resize = function (element) {
  this.params.w = parseInt(d3.select('#parentContainer').style('width'), 10) / 3 - this.params.margin.left - this.params.margin.right;
  // this.params.h = parseInt(d3.select(this.container).style('height'), 10) - this.params.margin.top - this.params.margin.bottom;
  this.setScale();
  this.svg
    .attr('width', this.params.w + this.params.margin.left + this.params.margin.right)
    .attr('height', this.params.h + this.params.margin.top + this.params.margin.bottom);
  this.setAxis();

  this.lab
    .attr('x', this.x_scale(2.5))
    .attr('y', this.params.h + this.params.margin.bottom - 5);
  this.setLine();
};


datanice.RegularizationPlot = function (container, params, plotPoints) {
  this.container = `#${container}`;
  this.svg = d3.select(this.container).append('svg')
    .attr('width', params.w + params.margin.left + params.margin.right)
    .attr('height', params.h + params.margin.top + params.margin.bottom);
  this.grid = new datanice.Grid(params, this);
  this.svg.append(this.grid.getNodeFunction());

  function genFunction(result) {
    const t = [];
    for (let i = 0; i <= params.maxX; i += 0.01) {
      let line_y = 0;
      for (let j = 0; j < result.equation.length; j++) {
        line_y += result.equation[result.equation.length - 1 - j] * (i ** j);
      }
      const line_x = i;
      t.push([line_x, line_y]);
    }
    return t;
  }

  this.t = genFunction(plotPoints);
  this.path = this.grid.append('svg:path')
    .attr('fill', 'none')
    .attr('stroke', datanice.darkBlue)
    .attr('stroke-width', '3px')
    .attr('d', this.grid.line(this.t))
    .attr('id', 'function-line');
  this.grid.lab.text(`Polynomial : Degree ${plotPoints.equation.length - 1}`);
};

datanice.RegularizationPlot.prototype.resize = function () {
  this.grid.resize();
  this.path.attr('d', this.grid.line(this.t));

  const { x_scale } = this.grid;
  const { y_scale } = this.grid;
  this.grid.element.selectAll('circle')
    .attr('cx', (d) => x_scale(d[0]))
    .attr('cy', (d) => y_scale(d[1]));
};
datanice.RegularizationPlot.prototype.addPoints = function (data) {
  const { x_scale } = this.grid;
  const { y_scale } = this.grid;
  this.grid.element.selectAll('circle')
    .data(data)
    .enter()
    .append('circle')
    .attr('cx', (d) => x_scale(d[0]))
    .attr('cy', (d) => y_scale(d[1]))
    .attr('r', 5)
    .style('fill', datanice.lightBlue);
};

datanice.RegularizationBarPlot = function (container, params, data, d, lambda) {
  this.container = `#${container}`;
  this.svg = d3.select(this.container).append('svg')
    .attr('width', params.w + params.margin.left + params.margin.right)
    .attr('height', params.h + params.margin.top + params.margin.bottom);

  const texture = textures.lines()
    .size(8)
    .strokeWidth(4).stroke(datanice.lightGreen);

  this.svg.append('g').call(texture);


  this.grid = new datanice.Grid(params, this);
  this.grid.hideGridX();
  this.svg.append(this.grid.getNodeFunction());

  this.data = data;
  const obj = this.getDataForRegression(data, d, lambda);
  const { grid } = this;
  const { x_scale } = this.grid;
  const { y_scale } = this.grid;

  // fills

  this.width = 100;
  this.rects = this.grid.element.selectAll('rect')
    .data((d) => obj)
    .enter().append('rect')
    .attr('width', this.width)
    .attr('y', (d) => y_scale(d.y0))
    .attr('height', (d) => grid.params.h - y_scale(-d.y1 + d.y0))
    .style('fill', (polynomial, i) => { if (i == 0) { return texture.url(); } return datanice.darkBlue; });
};


datanice.RegularizationBarPlot.prototype.getDataForRegression = function (data, d, lambda) {
  this.result = regression.polynomial(data, { order: d });
  let error = 0;
  for (let i = 0; i < data.length; i++) {
    let point_value = 0;
    for (let j = 0; j < this.result.equation.length; j++) {
      point_value += this.result.equation[this.result.equation.length - 1 - j] * Math.pow(data[i][0], j);
    }
    error += Math.pow(Math.abs(point_value - data[i][1]), 2);
  }
  this.error = error / data.length;
  const complexityTerm = this.getComplexity(lambda);
  return this.getBarData(this.error, complexityTerm);
};

datanice.RegularizationBarPlot.prototype.getComplexity = function (lambda) {
  let complexityTerm = 0;
  for (let j = 0; j < this.result.equation.length - 1; j++) {
    complexityTerm += lambda * Math.pow(this.result.equation[j], 2);
  }
  this.complexityTerm = complexityTerm / (40 * this.data.length);
  return this.complexityTerm;
};

datanice.RegularizationBarPlot.prototype.getTotalCost = function (lambda) {
  let totalCost = 0;
  if (this.complexityTerm) {
    totalCost += this.complexityTerm;
  } else {
    totalCost += this.getComplexity(lambda);
  }
  totalCost += this.error;
  return totalCost;
};

datanice.RegularizationBarPlot.prototype.sliderChange = function (lambda) {
  const complexityTerm = this.getComplexity(lambda);
  const barData = this.getBarData(this.error, complexityTerm);

  const { grid } = this;

  const { y_scale } = this.grid;
  this.rects
    .data(barData)
    .transition()
    .attr('y', (d) => y_scale(d.y0))
    .attr('height', (d) => grid.params.h - y_scale(-d.y1 + d.y0));
};

datanice.RegularizationBarPlot.prototype.getBarData = function (error, complexityTerm) {
  return [{ y0: error + complexityTerm, y1: error }, { y0: error, y1: 0 }];
};
datanice.RegularizationBarPlot.prototype.resize = function () {
  this.grid.resize();
  const { grid } = this;
  const { y_scale } = this.grid;
  this.width = grid.params.w * 8 / 10;
  this.rects
    .attr('width', this.width)
    .attr('x', (grid.params.w - this.width) / 2)
    .attr('y', (d) => y_scale(d.y0))
    .attr('height', (d) => grid.params.h - y_scale(-d.y1 + d.y0));
};

datanice.BarPlotGroup = function (list) {
  this.barPlotGroup = list;
};
datanice.BarPlotGroup.prototype.sliderChange = function (lambda) {
  const totalCost = [];
  for (let i = 0; i < this.barPlotGroup.length; i++) {
    this.barPlotGroup[i].sliderChange(lambda);
    totalCost[i] = Math.round(this.barPlotGroup[i].getTotalCost(lambda) * 10000);
  }
  const width = this.barPlotGroup[0].grid.params.w + this.barPlotGroup[0].grid.params.margin.left + this.barPlotGroup[0].grid.params.margin.right;
  this.indexMin = totalCost.indexOf(Math.min(totalCost[0], totalCost[1], totalCost[2]));
  this.bestBox = document.querySelectorAll('.jelly-best-target');
  this.bestBox[0].setAttribute('style', `opacity:1;margin-left:${width * this.indexMin + 70}px`);
  document.getElementById('lambdaValue').innerHTML = lambda;
};

datanice.BarPlotGroup.prototype.resize = function () {
  for (let i = 0; i < this.barPlotGroup.length; i++) {
    this.barPlotGroup[i].resize();
  }
  const width = this.barPlotGroup[0].grid.params.w + this.barPlotGroup[0].grid.params.margin.left + this.barPlotGroup[0].grid.params.margin.right;
  const { indexMin } = this;
  this.bestBox = document.querySelectorAll('.jelly-best-target');
  this.bestBox[0].setAttribute('style', `margin-left:${width * this.indexMin + 70}px`);
};

datanice.JellyEffect = function () {
  const bounce = new Bounce();
  bounce
    .scale({
      from: { x: 1, y: 0.5 },
      to: { x: 1, y: 1 },
      easing: 'bounce',
      duration: 1000,
      delay: 0,
      stiffness: 1,
      bounces: 4,
    })
    .scale({
      from: { x: 0.5, y: 1 },
      to: { x: 1, y: 1 },
      easing: 'bounce',
      duration: 1000,
      delay: 0,
      stiffness: 4,
      bounces: 6,
    });
  return bounce;
};

datanice.focusEffect = function () {
  const bounce = new Bounce();
  bounce
    .scale({
      from: { x: 1.3, y: 1.3 },
      to: { x: 1, y: 1 },
      easing: 'bounce',
      duration: 2000,
      delay: 0,
      stiffness: 1,
      bounces: 1,
    });
  return bounce;
};

export default datanice;
