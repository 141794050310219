import InlineLabel from "../../../../content/blog/aws-good-parts/LocalInlineLabel.js";
import DynamoAnimation from "../../../../content/blog/aws-good-parts/DynamoAnimation.js";
import S3Animation from "../../../../content/blog/aws-good-parts/S3Animation.js";
import Ec2Animation from "../../../../content/blog/aws-good-parts/Ec2Animation.js";
import LambdaAnimation from "../../../../content/blog/aws-good-parts/LambdaAnimation.js";
import ELB1Animation from "../../../../content/blog/aws-good-parts/ELB1Animation.js";
import ELB2Animation from "../../../../content/blog/aws-good-parts/ELB2Animation.js";
import * as React from 'react';
export default {
  InlineLabel,
  DynamoAnimation,
  S3Animation,
  Ec2Animation,
  LambdaAnimation,
  ELB1Animation,
  ELB2Animation,
  React
};