import React from 'react';
import { css } from '@emotion/core';
import { motion } from 'framer-motion';
import Tooltip from './Tooltip';


const LambdaAnimation = () => {
  const bigCloud = {
    animation: { scale: [1, 1.3] },
    transition: {
      duration: 2, times: [0, 1], yoyo: Infinity, ease: 'easeIn',
    },
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg1234"
      viewBox="0 0 347.90637 146.06736"
      height="70.06735mm"
      width="100.90637mm"
    >
      <defs
        id="defs1228"
      />
      <g
        css={css`"display:none`}
        transform="translate(0,10.050628)"
        id="layer7"
      />
      <g
        id="layer2"
      >
        <motion.g
          id="g6488"
          animate={bigCloud.animation}
          transition={bigCloud.transition}
        >
          <path
            css={css`fill:none;fill-opacity:1;stroke:#1a1d74;stroke-width:1.05833328;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;`}
            d="m 204.07084,23.74078 c 8.8e-4,-0.07831 0.007,-0.155212 0.007,-0.233801 -6e-5,-10.940922 -9.37807,-19.8101957 -20.94589,-19.8101957 -6.48873,0 -12.28749,2.791295 -16.12968,7.1728847 -2.06102,-0.8804717 -4.34135,-1.3820347 -6.74958,-1.3820347 -8.55211,0 -15.57264,6.1798627 -16.3561,14.0746387 -0.38015,-0.03341 -0.76327,-0.05529 -1.15262,-0.05529 -6.82229,-4e-6 -12.35277,5.230623 -12.35277,11.683019 0,6.452397 5.53048,11.683023 12.35277,11.683023 h 58.86296 c 6.82229,0 12.35277,-5.230626 12.35277,-11.683023 3e-4,-5.654162 -4.24668,-10.369191 -9.88814,-11.449222 z"
            id="path5158"
          />
          <g
            id="g5166"
            transform="matrix(0.31266622,0,0,0.29237168,96.277332,-29.144477)"
            css={css`fill:#eef8fe;fill-opacity:1;`}
          >
            <path
              css={css`fill:#eef8fe;fill-opacity:1;`}
              d="m 193.64957,144.26209 c 6.395,0 12.63,1.35 18.53,4.015 l 7.115,3.213 4.98,-6.008 c 1.361,-1.641 2.811,-3.186 4.318,-4.658 l -6.173,-2.787 c -5.9,-2.665 -12.135,-4.015 -18.53,-4.015 -15.08,0 -28.428,7.308 -36.626,18.715 7.428,-5.337 16.534,-8.475 26.386,-8.475 z"
              id="path5160"
            />
            <path
              css={css`fill:#eef8fe;fill-opacity:1;`}
              d="m 136.347,196.02 c 0.985,0 1.96,0.062 2.925,0.153 l 10.195,0.955 0.955,-10.192 c 0.029,-0.307 0.093,-0.601 0.127,-0.905 l -1.037,-0.097 c -0.965,-0.09 -1.94,-0.153 -2.925,-0.153 -11.273,0 -21.148,5.994 -26.682,14.943 4.789,-2.964 10.41,-4.704 16.442,-4.704 z"
              id="path5162"
            />
            <path
              css={css`fill:#eef8fe;fill-opacity:1;`}
              d="m 349.51457,186.412 -8.36,-1.692 0.175,-9.18 c 0,-33.272 -27.07,-60.34 -60.345,-60.34 -18.015,0 -34.95,7.965 -46.47,21.853 l -1.18,1.423 c 10.564,-8.369 23.663,-13.036 37.41,-13.036 33.275,0 60.345,27.068 60.345,60.34 l -0.175,9.18 8.36,1.692 c 14.549,2.945 25.115,15.878 25.115,30.753 0,6.032 -1.74,11.653 -4.702,16.442 8.947,-5.535 14.942,-15.41 14.942,-26.682 0,-14.875 -10.565,-27.808 -25.115,-30.753 z"
              id="path5164"
            />
          </g>
        </motion.g>
        <g
          id="g6741"
          transform="matrix(0.3185945,0,0,0.3185945,133.20688,25.127976)"
        >
          <path
            id="path6723"
            d="M 198.613,142.552 H 57.387 c -4.95,0 -9,-4.05 -9,-9 V 23.597 c 0,-4.95 4.05,-9 9,-9 h 141.225 c 4.95,0 9,4.05 9,9 v 109.955 c 0.001,4.95 -4.049,9 -8.999,9 z"
            css={css`fill:#1a1d74;fill-opacity:1`}
          />
          <g
            css={css`fill:#ff5252;fill-opacity:1`}
            id="g6727"
          >
            <path
              id="path6725"
              d="m 207.613,23.593 c 0,-4.948 -4.048,-8.996 -8.996,-8.996 H 57.384 c -4.948,0 -8.996,4.048 -8.996,8.996 V 44.282 H 207.614 V 23.593 Z"
              css={css`fill:#ff5252;fill-opacity:1`}
            />
          </g>
          <g
            id="g6739"
          >
            <g
              id="g6735"
              css={css`fill:#ffcd00;fill-opacity:1`}
            >
              <circle
                css={css`fill:#ffcd00;fill-opacity:1`}
                id="circle6729"
                r="6.2080002"
                cy="29.440001"
                cx="67.073997"
              />
              <circle
                css={css`fill:#ffcd00;fill-opacity:1`}
                id="circle6731"
                r="6.2080002"
                cy="29.440001"
                cx="86.100998"
              />
              <circle
                css={css`fill:#ffcd00;fill-opacity:1`}
                id="circle6733"
                r="6.2080002"
                cy="29.440001"
                cx="105.128"
              />
            </g>
            <path
              id="path6737"
              d="m 192.93,32.126 h -34.203 c -1.212,0 -2.203,-0.992 -2.203,-2.203 v -1.028 c 0,-1.212 0.992,-2.203 2.203,-2.203 h 34.203 c 1.212,0 2.203,0.992 2.203,2.203 v 1.028 c 10e-4,1.212 -0.991,2.203 -2.203,2.203 z"
              css={css`fill:#1a1d74;fill-opacity:1`}
            />
          </g>
        </g>
        <path
          id="path6743"
          d="m 152.9384,43.502858 h 42.09717 V 65.345059 H 152.9384 Z"
          css={css`fill:#ffcd00;fill-opacity:1;stroke-width:0.31859449`}
        />
        <g
          id="g6751"
          css={css`fill:#1a1d74;fill-opacity:1`}
          transform="matrix(0.3185945,0,0,0.3185945,133.20688,26.715477)"
        >
          <path
            css={css`fill:#1a1d74;fill-opacity:1`}
            id="path6745"
            d="M 103.125,99.045 92.619,87.715 c -0.412,-0.444 -0.413,-1.13 -0.003,-1.576 l 10.509,-11.434 c 0.22,-0.239 0.53,-0.376 0.855,-0.376 h 8.99 c 1.012,0 1.54,1.203 0.855,1.948 l -9.062,9.861 c -0.41,0.446 -0.408,1.132 0.003,1.576 l 9.039,9.75 c 0.689,0.744 0.162,1.952 -0.852,1.952 h -8.977 c -0.323,0.001 -0.631,-0.134 -0.851,-0.371 z"
          />
          <path
            css={css`fill:#1a1d74;fill-opacity:1`}
            id="path6747"
            d="m 138.09,63.372 -11.147,47.538 c -0.124,0.524 -0.592,0.895 -1.131,0.895 h -6.98 c -0.75,0 -1.303,-0.7 -1.131,-1.429 l 11.15,-47.538 c 0.124,-0.524 0.592,-0.895 1.131,-0.895 h 6.978 c 0.749,-10e-4 1.302,0.699 1.13,1.429 z"
          />
          <path
            css={css`fill:#1a1d74;fill-opacity:1`}
            id="path6749"
            d="m 142.821,74.33 h 8.992 c 0.325,0 0.635,0.136 0.855,0.376 l 10.507,11.434 c 0.41,0.446 0.408,1.132 -0.003,1.576 l -10.504,11.33 c -0.22,0.237 -0.529,0.372 -0.852,0.372 h -8.979 c -1.014,0 -1.542,-1.208 -0.852,-1.952 l 9.041,-9.75 c 0.412,-0.444 0.413,-1.13 0.003,-1.576 l -9.064,-9.861 c -0.684,-0.746 -0.156,-1.949 0.856,-1.949 z"
          />
        </g>
      </g>
      <g transform="translate(-150,7)">
        <foreignObject
          width={400}
          height={400}
        >
          <Tooltip text="Most simple way to run code in the cloud" width={100} />
        </foreignObject>
      </g>
      <g transform="translate(95,0)">
        <foreignObject
          width={400}
          height={400}
        >
          <Tooltip text="Better adapted for small code that doesn't change often" width={100} />
        </foreignObject>
      </g>
    </svg>

  );
};

export default LambdaAnimation;
