import React from 'react';
import { css } from '@emotion/core';

import { motion } from 'framer-motion';
import Tooltip from './Tooltip';


const a1 = css`display:inline`;
const a2 = css`display:inline;fill:#eef8fe;fill-opacity:1;stroke:#1a1d74;stroke-width:2.47960019;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a3 = css`display:inline;fill:#b7ddf9;fill-opacity:1;stroke:none;stroke-width:1.60265851;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a4 = css`fill-opacity:1;stroke:#1a1d74;stroke-width:2.47960019;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a5 = css`display:inline;fill:none;fill-opacity:1;stroke:#1a1d74;stroke-width:2.4699223;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a6 = css`display:inline;fill:none;fill-opacity:1;stroke:#1a1d74;stroke-width:2.4699223;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a7 = css`opacity:1;fill:#ffcd00;fill-opacity:1;stroke:#ffcd00;stroke-width:3.58472466;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a8 = css`display:inline;opacity:0.1;fill:#000000;fill-opacity:1;stroke:none;stroke-width:7.12182093;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a9 = css`display:inline;fill:#1a1d74;fill-opacity:1`;
const a10 = css`opacity:1;fill:#1a1d74;fill-opacity:1;stroke:none;stroke-width:1.46994877;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a11 = css`display:inline;opacity:1;fill:#1a1d74;fill-opacity:1;stroke:none;stroke-width:0.51293361;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a12 = css`display:inline;opacity:1;fill:#fbfbfb;fill-opacity:1;stroke:#1a1d74;stroke-width:1.99759603;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a13 = css`display:inline;fill:none;fill-opacity:1;stroke:#ffcd00;stroke-width:2.99999976;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1`;
const a14 = css`display:inline;fill:#ffcd00;fill-opacity:1;stroke:#ffcd00;stroke-width:3;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a15 = css`opacity:1;fill:#ffcd00;fill-opacity:1;stroke:#ffcd00;stroke-width:3;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a16 = css`fill:#ffcd00;fill-opacity:1;stroke:#ffcd00;stroke-width:16.36301613;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a17 = css`display:inline;fill:#ffcd00;fill-opacity:1;stroke:#ffcd00;stroke-width:3.77715468;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a18 = css`fill:#ffcd00;fill-opacity:1;stroke:#ffcd00;stroke-width:3.77715468;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a19 = css`fill:#ffcd00;fill-opacity:1;stroke:#ffcd00;stroke-width:20.60188103;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a20 = css`display:inline;fill:#ffcd00;fill-opacity:1;stroke:#ffcd00;stroke-width:3;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a21 = css`fill:#ffcd00;fill-opacity:1;stroke:#ffcd00;stroke-width:3;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a22 = css`fill:#ffcd00;fill-opacity:1;stroke:#ffcd00;stroke-width:16.36301613;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a23 = css`display:inline;fill:#ffcd00;fill-opacity:1;stroke:#ffcd00;stroke-width:3.77715468;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a24 = css`opacity:1;fill:#ffcd00;fill-opacity:1;stroke:#ffcd00;stroke-width:3.77715468;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
const a25 = css`fill:#ffcd00;fill-opacity:1;stroke:#ffcd00;stroke-width:20.60188103;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;


// const list = {
//   visible: {
//     opacity: 1,
//     transition: {
//       loop: Infinity,
//     },
//   },
//   hidden: {
//     opacity: 0,
//   },
// };
// const circleDown = {
//   start: {
//     y: 100,
//     transition: {
//       loop: Infinity,
//     },
//   },
//   end: {
//     y: 0,
//   },
// };

const DynamoAnimation = () => {
  const circleAnimation = {
    animation: { cy: [-230, -150, -150, -150], rx: [13, 10, 10, 10], ry: [13, 10, 10, 10] },
    transition: {
      duration: 3, delay: 0, times: [0, 0.2, 0.3, 1], loop: Infinity, ease: 'easeOut',
    },
  };
  const DBAnimation = {
    animation: { fill: ['#ffffff', '#ffcd00', '#ffcd00', '#ffcd00'] },
    transition: {
      duration: 3, delay: 0, times: [0, 0.2, 0.3, 1], loop: Infinity, ease: 'easeOut',
    },
  };
  const lineLongAnimation = {
    animation: { width: [0, 60] },
    transition: {
      duration: 3, times: [0.1, 0.4], loop: Infinity,
    },
  };
  const showArrow3 = {
    animation: { opacity: [0, 1] },
    transition: {
      duration: 3, times: [0.4, 0.5], loop: Infinity,
    },
  };
  const showArrow4 = {
    animation: { opacity: [0, 1] },
    transition: {
      duration: 3, times: [0.4, 0.5], loop: Infinity,
    },
  };
  const lineLShortAnimation1 = {
    animation: { width: [0, 60] },
    transition: {
      duration: 3, times: [0.06, 0.14], loop: Infinity,
    },
  };
  const showArrow1 = {
    animation: { opacity: [0, 1] },
    transition: {
      duration: 3, times: [0.14, 0.19], loop: Infinity,
    },
  };
  const lineLShortAnimation2 = {
    animation: { width: [0, 60] },
    transition: {
      duration: 3, times: [0.1, 0.2], loop: Infinity,
    },
  };

  const showArrow2 = {
    animation: { opacity: [0, 1] },
    transition: {
      duration: 3, times: [0.2, 0.3], loop: Infinity,
    },
  };
  const dataAnim = {
    animation: { pathLength: [0, 1] },
    transition: {
      duration: 3, times: [0.2, 0.6], loop: Infinity,
    },
  };
  // const sequence = async () => {
  //   await circleControls.start({ y: 90 })
  // }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg1234"
      version="1.1"
      viewBox="0 0 347.90637 270.06736"
      height="70.06735mm"
      width="100.90637mm"
    >
      <defs
        id="defs1228"
      />
      <g
        transform="translate(36.369845,76.266838)"
        id="layer1"
      >
        <g
          transform="matrix(0.79114486,0,0,0.82231971,256.64998,103.78841)"
          id="g1093"
          css={a1}
        >
          <path
            css={a2}
            d="m -368.50738,-150.12151 v 90.44097 c -0.15495,0.48068 -0.2396,0.96337 -0.25367,1.44654 3e-4,8.65019 22.14051,15.6625 49.45172,15.66248 27.31121,2e-5 48.7189,-7.37883 48.7192,-16.02903 l -0.0285,-2.27391 v -89.24705 z"
            id="rect930"
          />
          <path
            css={a3}
            d="m -292.26536,-151.29418 v 104.5962 c 12.1371,-2.84383 20.08547,-7.45509 20.5884,-12.56047 v -2.78898 -89.24675 z"
            id="rect930-8"
          />
          <motion.ellipse
            animate={DBAnimation.animation}
            transition={DBAnimation.transition}
            css={a4}
            id="path886"
            cx="-319.6716"
            cy="-148.88997"
            rx="49.463028"
            ry="15.219396"
          />
          <path
            css={a5}
            d="m -270.44115,-120.00489 a 49.053725,15.226826 0 0 1 -49.03336,14.78819 49.053725,15.226826 0 0 1 -49.03341,-14.78852"
            id="path886-2"
          />
          <path
            css={a6}
            d="m -270.53224,-88.76727 a 49.053726,15.226826 0 0 1 -49.03337,14.78818 49.053726,15.226826 0 0 1 -49.03341,-14.78852"
            id="path886-2-4"
          />
          <motion.ellipse
            animate={circleAnimation.animation}
            transition={circleAnimation.transition}
            ry="14.776019"
            rx="15.903512"
            cy="-201.10477"
            cx="-319.94852"
            id="path1317"
            css={a7}
          />
        </g>
        <g
          id="layer4"
        >
          <ellipse
            ry="5.0270834"
            rx="54.620762"
            cy="48.104679"
            cx="241.85522"
            id="path1319"
            css={a8}
          />
        </g>
        <g
          css={a9}
          id="g1116"
          transform="matrix(1.007848,0,0,0.98982932,-39.373681,-4.160228)"
        >
          <rect
            y="3.9424775"
            x="268.0885"
            height="45.119469"
            width="21.026548"
            id="rect1097"
            css={a10}
          />
          <rect
            y="49.938053"
            x="259.7655"
            height="3.0663717"
            width="37.672565"
            id="rect1097-3"
            css={a11}
          />
        </g>
        <rect
          css={a12}
          id="rect1095"
          width="112.13869"
          height="71.977234"
          x="186.2282"
          y="-37.203846"
        />
        <motion.path
          animate={dataAnim.animation}
          transition={dataAnim.transition}
          css={a13}
          d="m 195.22797,-3.419959 c 4.16148,0.01426 2.92368,12.982263 6.34136,13.169279 3.41771,0.187012 3.95327,-23.652036 8.55994,-23.460946 4.60669,0.191087 1.71161,19.113632 6.1703,19.128912 4.45874,0.01529 1.54496,-15.596457 5.25989,-15.4084257 3.71494,0.188032 -0.0936,24.8923647 5.85132,24.9127407 5.94492,0.02037 7.41778,-35.911203 12.91748,-36.067655 5.49977,-0.156451 3.58963,33.494717 7.60183,33.68377 3.26694,0.153934 1.85999,-20.3284957 4.9811,-20.3177987 3.1211,0.01069 1.46465,12.1686977 5.00835,11.9376057 4.22386,-0.275447 1.70677,-19.101917 4.67923,-19.09173 2.97249,0.01019 -0.10948,29.099526 3.16024,29.110731 3.26972,0.01119 4.07681,-16.990187 11.21077,-16.965737 7.13396,0.02445 14.12189,-0.652804 14.12189,-0.652804"
          id="path1152"
        />
        <g
          css={a14}
          transform="translate(38.946239,1.2154333)"
          id="g1160"
        >
          <motion.rect
            animate={lineLShortAnimation2.animation}
            transition={lineLShortAnimation2.transition}
            css={a15}
            id="rect1154"
            width="60.451328"
            height="2.6283185"
            x="45.995571"
            y="-14.893805"
          />
          <motion.path
            animate={showArrow2.animation}
            transition={showArrow2.transition}
            css={a16}
            id="path1156"
            d="m 124.40708,8.7610621 -18.7776,9.9651439 -18.777591,9.965143 0.75873,-21.2444467 0.758731,-21.2444463 18.01887,11.2793034 z"
            transform="matrix(0.18111547,0,0,0.18559243,89.912676,-14.97892)"
          />
        </g>
        <g
          transform="matrix(0.63083091,0,0,1,87.533666,35.712673)"
          id="g1160-9"
          css={a17}
        >
          <motion.rect
            animate={lineLShortAnimation1.animation}
            transition={lineLShortAnimation1.transition}
            css={a18}
            id="rect1154-0"
            width="60.451328"
            height="2.6283185"
            x="45.995571"
            y="-14.893805"
          />
          <motion.path
            animate={showArrow1.animation}
            transition={showArrow1.transition}
            css={a19}
            id="path1156-5"
            d="m 124.40708,8.7610621 -18.7776,9.9651439 -18.777591,9.965143 0.75873,-21.2444467 0.758731,-21.2444463 18.01887,11.2793034 z"
            transform="matrix(0.18111547,0,0,0.18559243,89.912676,-14.97892)"
          />
        </g>
        <g
          transform="translate(35.50928,52.067831)"
          id="g1160-2"
          css={a20}
        >
          <motion.rect

            animate={lineLongAnimation.animation}
            transition={lineLongAnimation.transition}
            css={a21}
            id="rect1154-2"
            width="60.451328"
            height="2.6283185"
            x="45.995571"
            y="-14.893805"
          />
          <motion.path
            animate={showArrow3.animation}
            transition={showArrow3.transition}
            css={a22}
            id="path1156-7"
            d="m 124.40708,8.7610621 -18.7776,9.9651439 -18.777591,9.965143 0.75873,-21.2444467 0.758731,-21.2444463 18.01887,11.2793034 z"
            transform="matrix(0.18111547,0,0,0.18559243,89.912676,-14.97892)"
          />
        </g>
        <g
          transform="matrix(0.63083091,0,0,1,64.754906,19.522221)"
          id="g1160-9-3"
          css={a23}
        >
          <motion.rect
            animate={lineLongAnimation.animation}
            transition={lineLongAnimation.transition}
            css={a24}
            id="rect1154-0-7"
            width="60.451328"
            height="2.6283185"
            x="45.995571"
            y="-14.893805"
          />
          <motion.path
            animate={showArrow4.animation}
            transition={showArrow4.transition}
            css={a25}
            id="path1156-5-9"
            d="m 124.40708,8.7610621 -18.7776,9.9651439 -18.777591,9.965143 0.75873,-21.2444467 0.758731,-21.2444463 18.01887,11.2793034 z"
            transform="matrix(0.18111547,0,0,0.18559243,89.912676,-14.97892)"
          />
        </g>
      </g>
      <g transform="translate(-150,155)">
        <foreignObject
          width={400}
          height={400}
        >
          <Tooltip text="Data is highly durable" width={100} />
        </foreignObject>
      </g>
      <g transform="translate(-40,140)">
        <foreignObject
          width={400}
          height={400}
        >
          <Tooltip text="Data needs to be sent across the network" width={100} />
        </foreignObject>
      </g>
      <g transform="translate(85,150)">
        <foreignObject
          width={400}
          height={400}
        >
          <Tooltip text="Data is immediately consistent" width={100} />
        </foreignObject>
      </g>
    </svg>
  );
};

export default DynamoAnimation;
