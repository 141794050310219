import React, { useLayoutEffect, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { css } from '@emotion/core';

const textContainer = css`
  width:fit-content
`;
const foreignContainer = css`
  position: absolute;
  pointerEvents: none;
`;

// const useWatchDimensions = (dependencies) => {
//   if (!dependencies) {
//     throw new Error('Please provide a dependency array to the useWatchDimensions hook');
//   }
//   const ref = useRef();
//   const [dimensions, setDimensions] = useState({});
//   useLayoutEffect(() => {
//     if (ref.current) {
//       setDimensions(ref.current.getBoundingClientRect());
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, dependencies);
//
//   return [ref, dimensions];
// };

const SvgDiv = ({
  position: { x, y }, maxWidth, children, parentBoundaries,
}) => {
  const { ref, width, height } = useResizeObserver();
  const dimensions = {
    width,
    height,
  };
  const myMaxWidth = maxWidth || (parentBoundaries ? parentBoundaries.width - x : 'none');
  return (
    <g
      css={foreignContainer}
    >
      <foreignObject
        transform={`translate(${x},${y})`}
        width={!dimensions.width || dimensions.width === 0 ? 0 : dimensions.width + 20}
        height={!dimensions.height || dimensions.height === 0 ? 0 : dimensions.height + 25}
      >
        <div ref={ref} css={textContainer} style={{ maxWidth: myMaxWidth }}>
          {children}
        </div>
      </foreignObject>
    </g>
  );
};

// const ResponsiveSvg = () => {
//   const { ref, width } = useResizeObserver();
//   const parentBoundaries = ref && ref.current ? ref.current.getBoundingClientRect() : null;
//   console.log();
//   return (
//     <div ref={ref} style={{ width: '100%' }}>
//       { width && (
//       <svg preserveAspectRatio="xMinYMin meet" viewBox={`0 0 ${width} ${300}`} width={width} height={300}>
//         <circle cx={50} cy={50} r={9} />
//         <text x={100} y={100}>
//           {width}
//         </text>
//         <SvgDiv parentBoundaries={parentBoundaries} position={{ x: 200, y: 200 }}>
//           <div>
//             With the multiplication of online services, technical choices can get very time consuming. No one has the time to test every
//           </div>
//         </SvgDiv>
//       </svg>
//       )}
//     </div>
//   );
// };

export default SvgDiv;
