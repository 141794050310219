import React from 'react';
import { css } from '@emotion/core';


const DataWarehouse = () => (
<svg
   xmlns="http://www.w3.org/2000/svg"
   id="svg917"
   version="1.1"
   viewBox="0 10 100 36"
   height="auto"
   width="100%">
  <defs
     id="defs911">
    <rect
       id="rect2304"
       height="3.4017856"
       width="15.497024"
       y="29.765625"
       x="72.665924" />
    <rect
       x="72.665924"
       y="29.765625"
       width="15.497024"
       height="3.4017856"
       id="rect2304-6" />
    <rect
       x="72.665924"
       y="29.765625"
       width="15.497024"
       height="3.4017856"
       id="rect2317" />
    <rect
       x="72.665924"
       y="29.765625"
       width="15.497024"
       height="3.4017856"
       id="rect2304-1" />
    <rect
       x="72.665924"
       y="29.765625"
       width="15.497024"
       height="3.4017856"
       id="rect2346" />
    <rect
       id="rect2304-1-0"
       height="3.4017856"
       width="15.497024"
       y="29.765625"
       x="72.665924" />
    <rect
       id="rect2375"
       height="3.4017856"
       width="15.497024"
       y="29.765625"
       x="72.665924" />
    <rect
       id="rect2304-6-2"
       height="3.4017856"
       width="15.497024"
       y="29.765625"
       x="72.665924" />
    <rect
       id="rect2404"
       height="3.4017856"
       width="15.497024"
       y="29.765625"
       x="72.665924" />
    <rect
       id="rect2304-6-6"
       height="3.4017856"
       width="15.497024"
       y="29.765625"
       x="72.665924" />
    <rect
       id="rect2434"
       height="3.4017856"
       width="15.497024"
       y="29.765625"
       x="72.665924" />
    <rect
       x="72.665924"
       y="29.765625"
       width="15.497024"
       height="3.4017856"
       id="rect2304-6-6-9" />
    <rect
       x="72.665924"
       y="29.765625"
       width="15.497024"
       height="3.4017856"
       id="rect2464" />
    <rect
       id="rect2304-1-4"
       height="3.4017856"
       width="15.497024"
       y="29.765625"
       x="72.665924" />
    <rect
       id="rect2499"
       height="3.4017856"
       width="15.497024"
       y="29.765625"
       x="72.665924" />
  </defs>
  <g
     id="layer1">
    <g
       transform="matrix(0.02070534,0,0,0.02241663,17.595701,20.596472)"
       id="g1580">
      <path
         d="M 353.54297,412.91016 H 94.09375 c -5.921875,0 -10.722656,-4.79688 -10.722656,-10.71875 v -65.64454 c 0,-5.92187 4.800781,-10.72265 10.722656,-10.72265 h 259.44922 c 5.92187,0 10.72265,4.80078 10.72265,10.72265 v 65.64454 c 0,5.92187 -4.80078,10.71875 -10.72265,10.71875 z m 0,0"
         fill="#f4efed"
         id="path1492" />
      <path
         css={css`fill:#ffcd00;fill-opacity:1;`}
         d="M 319.67969,382.50391 H 190.84375 c -8.01953,0 -14.51563,-6.5 -14.51563,-14.51563 0,-8.01562 6.4961,-14.51562 14.51563,-14.51562 h 128.83594 c 8.01562,0 14.51172,6.5 14.51172,14.51562 0.004,8.01563 -6.4961,14.51563 -14.51172,14.51563 z m 0,0"
         fill="#7bac51"
         id="path1494" />
      <path
         d="M 319.67969,386.50391 H 190.84375 c -10.20703,0 -18.51563,-8.3086 -18.51563,-18.51954 0,-10.20703 8.30469,-18.51171 18.51563,-18.51171 h 128.83594 c 10.20703,0 18.51562,8.30468 18.51562,18.51171 0,10.21094 -8.30859,18.51954 -18.51562,18.51954 z M 190.84375,357.47266 c -5.79688,0 -10.51563,4.71484 -10.51563,10.51171 0,5.80079 4.71485,10.51954 10.51563,10.51954 h 128.83594 c 5.79687,0 10.51562,-4.71875 10.51562,-10.51954 0,-5.79296 -4.71875,-10.51171 -10.51562,-10.51171 z m 0,0"
         fill="#690589"
         id="path1496" />
      <path
         d="m 142.47266,367.98828 c 0,8.01563 -6.5,14.51563 -14.51563,14.51563 -8.01562,0 -14.51172,-6.5 -14.51172,-14.51563 0,-8.01562 6.4961,-14.51562 14.51172,-14.51562 8.01563,0 14.51563,6.5 14.51563,14.51562 z m 0,0"
         fill="#ff5252"
         id="path1498" />
      <path
         d="m 127.95703,386.50391 c -10.21094,0 -18.51562,-8.3086 -18.51562,-18.51954 0,-10.20703 8.30468,-18.51171 18.51562,-18.51171 10.20703,0 18.51563,8.30468 18.51563,18.51171 0,10.21094 -8.3086,18.51954 -18.51563,18.51954 z m 0,-29.03125 c -5.79687,0 -10.51562,4.71484 -10.51562,10.51171 0,5.80079 4.71484,10.51954 10.51562,10.51954 5.79688,0 10.51563,-4.71875 10.51563,-10.51954 0,-5.79296 -4.71485,-10.51171 -10.51563,-10.51171 z m 0,0"
         fill="#690589"
         id="path1500" />
      <path
         d="M 353.54297,500 H 94.09375 c -5.921875,0 -10.722656,-4.80078 -10.722656,-10.71875 v -65.64844 c 0,-5.92187 4.800781,-10.72265 10.722656,-10.72265 h 259.44922 c 5.92187,0 10.72265,4.80078 10.72265,10.72265 v 65.64844 c 0,5.91797 -4.80078,10.71875 -10.72265,10.71875 z m 0,0"
         fill="#f4efed"
         id="path1502" />
      <path
         css={css`fill:#ffcd00;fill-opacity:1;`}
         d="M 319.67969,469.58984 H 190.84375 c -8.01953,0 -14.51563,-6.5 -14.51563,-14.51562 0,-8.01563 6.4961,-14.51563 14.51563,-14.51563 h 128.83594 c 8.01562,0 14.51172,6.5 14.51172,14.51563 0.004,8.01562 -6.4961,14.51562 -14.51172,14.51562 z m 0,0"
         fill="#7bac51"
         id="path1504" />
      <path
         d="M 319.67969,473.58984 H 190.84375 c -10.20703,0 -18.51563,-8.30468 -18.51563,-18.51562 0,-10.21094 8.30469,-18.51563 18.51563,-18.51563 h 128.83594 c 10.20703,0 18.51562,8.30469 18.51562,18.51563 0,10.21094 -8.30859,18.51562 -18.51562,18.51562 z M 190.84375,444.55859 c -5.79688,0 -10.51563,4.71875 -10.51563,10.51563 0,5.79687 4.71485,10.51562 10.51563,10.51562 h 128.83594 c 5.79687,0 10.51562,-4.71875 10.51562,-10.51562 0,-5.79688 -4.71875,-10.51563 -10.51562,-10.51563 z m 0,0"
         fill="#690589"
         id="path1506" />
      <path
         d="m 142.47266,455.07422 c 0,8.01562 -6.5,14.51562 -14.51563,14.51562 -8.01562,0 -14.51172,-6.5 -14.51172,-14.51562 0,-8.01563 6.4961,-14.51563 14.51172,-14.51563 8.01563,0 14.51563,6.5 14.51563,14.51563 z m 0,0"
         fill="#ff5252"
         id="path1508" />
      <path
         d="m 127.95703,473.58984 c -10.21094,0 -18.51562,-8.30468 -18.51562,-18.51562 0,-10.21094 8.30468,-18.51563 18.51562,-18.51563 10.20703,0 18.51563,8.30469 18.51563,18.51563 0,10.21094 -8.3086,18.51562 -18.51563,18.51562 z m 0,-29.03125 c -5.79687,0 -10.51562,4.71875 -10.51562,10.51563 0,5.79687 4.71484,10.51562 10.51562,10.51562 5.79688,0 10.51563,-4.71875 10.51563,-10.51562 0,-5.79688 -4.71485,-10.51563 -10.51563,-10.51563 z m 0,0"
         fill="#690589"
         id="path1510" />
      <path
         d="M 353.54297,325.82422 H 94.09375 c -5.921875,0 -10.722656,-4.79688 -10.722656,-10.71875 v -65.64844 c 0,-5.91797 4.800781,-10.71875 10.722656,-10.71875 h 259.44922 c 5.92187,0 10.72265,4.80078 10.72265,10.71875 v 65.64844 c 0,5.92187 -4.80078,10.71875 -10.72265,10.71875 z m 0,0"
         fill="#f4efed"
         id="path1512" />
      <g
         fill="#d6d1cf"
         id="g1520">
        <path
           d="m 353.54297,238.73828 h -21.4375 c 5.91797,0 10.71875,4.80078 10.71875,10.71875 v 65.64844 c 0,5.92187 -4.80078,10.71875 -10.71875,10.71875 h 21.4375 c 5.92187,0 10.72265,-4.79688 10.72265,-10.71875 v -65.64844 c 0,-5.91797 -4.80078,-10.71875 -10.72265,-10.71875 z m 0,0"
           id="path1514" />
        <path
           d="m 353.54297,325.82422 h -21.4375 c 5.91797,0 10.71875,4.80078 10.71875,10.72265 v 65.64844 c 0,5.91797 -4.80078,10.71875 -10.71875,10.71875 h 21.4375 c 5.92187,0 10.72265,-4.80078 10.72265,-10.71875 v -65.64844 c 0,-5.92187 -4.80078,-10.72265 -10.72265,-10.72265 z m 0,0"
           id="path1516" />
        <path
           d="m 353.54297,412.91016 h -21.4375 c 5.91797,0 10.71875,4.80078 10.71875,10.72265 v 65.64844 c 0,5.91797 -4.80078,10.71875 -10.71875,10.71875 h 21.4375 c 5.92187,0 10.72265,-4.80078 10.72265,-10.71875 v -65.64844 c 0,-5.92187 -4.80078,-10.72265 -10.72265,-10.72265 z m 0,0"
           id="path1518" />
      </g>
      <path
         d="m 94.09375,500 h 21.4375 c -5.91797,0 -10.71875,-4.80078 -10.71875,-10.71875 v -65.64844 c 0,-5.92187 4.80078,-10.72265 10.71875,-10.72265 h -21.4375 c -5.921875,0 -10.722656,4.80078 -10.722656,10.72265 v 65.64844 c 0,5.91797 4.800781,10.71875 10.722656,10.71875 z m 0,0"
         fill="#ffffff"
         id="path1522" />
      <path
         d="m 94.09375,412.91016 h 21.4375 c -5.91797,0 -10.71875,-4.79688 -10.71875,-10.71875 v -65.64844 c 0,-5.91797 4.80078,-10.71875 10.71875,-10.71875 h -21.4375 c -5.921875,0 -10.722656,4.80078 -10.722656,10.71875 v 65.64844 c 0,5.92187 4.800781,10.71875 10.722656,10.71875 z m 0,0"
         fill="#ffffff"
         id="path1524" />
      <path
         d="m 94.09375,325.82422 h 21.4375 c -5.91797,0 -10.71875,-4.79688 -10.71875,-10.71875 v -65.64844 c 0,-5.91797 4.80078,-10.71875 10.71875,-10.71875 h -21.4375 c -5.921875,0 -10.722656,4.80078 -10.722656,10.71875 v 65.64844 c 0,5.92187 4.800781,10.71875 10.722656,10.71875 z m 0,0"
         fill="#ffffff"
         id="path1526" />
      <path
         css={css`fill:#ffcd00;fill-opacity:1;`}
         d="M 319.67969,295.41406 H 190.84375 c -8.01953,0 -14.51563,-6.5 -14.51563,-14.51562 0,-8.01563 6.4961,-14.51172 14.51563,-14.51172 h 128.83594 c 8.01562,0 14.51172,6.49609 14.51172,14.51172 0.004,8.01562 -6.4961,14.51562 -14.51172,14.51562 z m 0,0"
         fill="#7bac51"
         id="path1528" />
      <path
         d="M 353.54297,329.82422 H 94.09375 c -8.121094,0 -14.722656,-6.60156 -14.722656,-14.71875 v -65.64453 c 0,-8.11719 6.605468,-14.72266 14.722656,-14.72266 h 259.45312 c 8.11719,0 14.71875,6.60547 14.71875,14.72266 v 65.64453 c 0,8.11719 -6.60546,14.71875 -14.72265,14.71875 z M 94.09375,242.73828 c -3.707031,0 -6.722656,3.01563 -6.722656,6.71875 v 65.64844 c 0,3.70312 3.015625,6.71875 6.722656,6.71875 h 259.45312 c 3.70704,0 6.71875,-3.01563 6.71875,-6.71875 v -65.64844 c 0,-3.70312 -3.01562,-6.71875 -6.71875,-6.71875 z m 0,0"
         fill="#690589"
         id="path1530" />
      <path
         d="M 319.67969,299.41406 H 190.84375 c -10.20703,0 -18.51563,-8.30469 -18.51563,-18.51562 0,-10.20703 8.30469,-18.51563 18.51563,-18.51563 h 128.83594 c 10.20703,0 18.51562,8.3086 18.51562,18.51563 0,10.21093 -8.30859,18.51562 -18.51562,18.51562 z M 190.84375,270.38281 c -5.79688,0 -10.51563,4.71875 -10.51563,10.51563 0,5.79687 4.71485,10.51562 10.51563,10.51562 h 128.83594 c 5.79687,0 10.51562,-4.71484 10.51562,-10.51562 0,-5.79688 -4.71875,-10.51563 -10.51562,-10.51563 z m 0,0"
         fill="#690589"
         id="path1532" />
      <path
         d="m 142.47266,280.89844 c 0,8.01562 -6.5,14.51562 -14.51563,14.51562 -8.01562,0 -14.51172,-6.5 -14.51172,-14.51562 0,-8.01563 6.4961,-14.51172 14.51172,-14.51172 8.01563,0 14.51563,6.49609 14.51563,14.51172 z m 0,0"
         fill="#ff5252"
         id="path1534" />
      <path
         d="m 127.95703,299.41406 c -10.21094,0 -18.51562,-8.30469 -18.51562,-18.51562 0,-10.20703 8.30468,-18.51563 18.51562,-18.51563 10.20703,0 18.51563,8.3086 18.51563,18.51563 0,10.21093 -8.3086,18.51562 -18.51563,18.51562 z m 0,-29.03125 c -5.79687,0 -10.51562,4.71875 -10.51562,10.51563 0,5.79687 4.71484,10.51562 10.51562,10.51562 5.79688,0 10.51563,-4.71484 10.51563,-10.51562 0,-5.79688 -4.71485,-10.51563 -10.51563,-10.51563 z m 0,0"
         fill="#690589"
         id="path1536" />
      <path
         d="M 353.54297,416.91016 H 94.09375 c -8.121094,0 -14.722656,-6.60157 -14.722656,-14.71485 v -65.64844 c 0,-8.11718 6.605468,-14.71875 14.722656,-14.71875 h 259.45312 c 8.11719,0 14.71875,6.60547 14.71875,14.71875 v 65.64844 c 0,8.11328 -6.60546,14.71485 -14.72265,14.71485 z M 94.09375,329.82422 c -3.707031,0 -6.722656,3.01953 -6.722656,6.72265 v 65.64454 c 0,3.70703 3.015625,6.71875 6.722656,6.71875 h 259.45312 c 3.70704,0 6.71875,-3.01563 6.71875,-6.71875 v -65.64454 c 0,-3.70703 -3.01562,-6.72265 -6.71875,-6.72265 z m 0,0"
         fill="#690589"
         id="path1538" />
      <path
         d="M 353.54297,504 H 94.09375 c -8.121094,0 -14.722656,-6.60547 -14.722656,-14.71875 v -65.64844 c 0,-8.11719 6.605468,-14.71875 14.722656,-14.71875 h 259.45312 c 8.11719,0 14.71875,6.60547 14.71875,14.71875 v 65.64844 c 0,8.11328 -6.60546,14.71875 -14.72265,14.71875 z M 94.09375,416.91016 c -3.707031,0 -6.722656,3.01953 -6.722656,6.72265 v 65.64453 c 0,3.70703 3.015625,6.71875 6.722656,6.71875 h 259.45312 c 3.70704,0 6.71875,-3.01562 6.71875,-6.71875 v -65.64453 c 0,-3.70703 -3.01562,-6.72265 -6.71875,-6.72265 z m 0,0"
         fill="#690589"
         id="path1540" />
    </g>
    <g
       transform="matrix(0.01419798,0,0,0.01471364,75.883599,31.88032)"
       id="g1644">
      <path
         d="M 500,500 H 4 V 4 h 24 v 472 h 472 z m 0,0"
         fill="#f4efed"
         id="path1582" />
      <path
         d="M 4,4 H 24 V 500 H 4 Z m 0,0"
         fill="#ffffff"
         id="path1584" />
      <path
         d="m 480,476 h 20 v 24 h -20 z m 0,0"
         fill="#d6d1cf"
         id="path1586" />
      <path
         d="M 500,504 H 4 c -2.207031,0 -4,-1.79297 -4,-4 V 4 C 0,1.792969 1.792969,0 4,0 h 24 c 2.207031,0 4,1.792969 4,4 v 468 h 468 c 2.20703,0 4,1.79297 4,4 v 24 c 0,2.20703 -1.79297,4 -4,4 z M 8,496 H 496 V 480 H 28 c -2.207031,0 -4,-1.79297 -4,-4 V 8 H 8 Z m 0,0"
         fill="#690589"
         id="path1588" />
      <path
         d="M 28,133.67578 H 500 V 370.32422 H 28 Z m 0,0"
         fill="#ffcd00"
         id="path1590" />
      <path
         d="m 480,133.67578 h 20 v 236.64844 h -20 z m 0,0"
         fill="#dea42e"
         id="path1592" />
      <path
         d="M 28,133.67578 H 48 V 370.32422 H 28 Z m 0,0"
         fill="#fce06a"
         id="path1594" />
      <path
         d="m 463.02734,163.14844 -23.78125,1.1875 8.46094,13.10156 -114.57031,72.61719 -125.85547,-39.88282 c -2.25,-0.71484 -4.70312,-0.39843 -6.69922,0.86719 L 71.175781,293.0625 c -3.730469,2.36719 -4.839843,7.30859 -2.472656,11.03906 1.523437,2.40625 4.113281,3.71875 6.761719,3.71875 1.464844,0 2.945312,-0.40234 4.277344,-1.24609 L 206,226.54688 331.85547,266.43359 c 2.24609,0.71094 4.70312,0.39453 6.69922,-0.87109 l 117.83594,-74.68359 8.69531,13.46093 10.85937,-21.1914 10.86328,-21.1875 z m 0,0"
         fill="#7bac51"
         id="path1596" />
      <path
         d="m 75.46875,311.81641 c -4.140625,0 -7.929688,-2.08204 -10.144531,-5.57422 -3.542969,-5.58985 -1.875,-13.01953 3.710937,-16.5586 L 198.44141,207.66016 c 3,-1.90235 6.66406,-2.37891 10.05078,-1.30079 l 124.07422,39.32032 109.58984,-69.46094 -6.26953,-9.71094 c -0.77735,-1.19922 -0.85156,-2.72265 -0.19922,-3.99609 0.64844,-1.27344 1.92969,-2.09766 3.35937,-2.16797 l 47.5586,-2.375 c 1.42578,-0.0586 2.78125,0.625 3.55469,1.82422 0.77734,1.20312 0.85546,2.72265 0.20312,3.99609 l -21.72266,42.375 c -0.65234,1.27344 -1.93359,2.09766 -3.35937,2.17188 -1.42969,0.0664 -2.78516,-0.625 -3.55859,-1.82422 l -6.54297,-10.12891 -114.48438,72.5625 c -3.0039,1.90625 -6.66015,2.37891 -10.05078,1.30078 L 206.56641,230.92578 81.882812,309.95703 c -1.925781,1.21875 -4.144531,1.85938 -6.414062,1.85938 z m 129.39453,-98.02344 c -0.75,0 -1.48828,0.21094 -2.13672,0.62109 L 73.316406,296.44141 c -1.859375,1.17968 -2.414062,3.65625 -1.234375,5.51953 1.136719,1.79687 3.699219,2.39062 5.519531,1.23437 L 203.85937,223.16797 c 1,-0.63281 2.22657,-0.79297 3.35157,-0.4336 l 125.85547,39.88672 c 1.13281,0.35938 2.34765,0.19532 3.34765,-0.43359 L 454.24609,187.5 c 1.85938,-1.17578 4.3125,-0.63281 5.5,1.20703 l 4.94141,7.64453 15.39844,-30.04687 -33.71875,1.68359 4.70312,7.28125 c 0.57813,0.89453 0.77735,1.98438 0.54688,3.02734 -0.23047,1.04297 -0.86328,1.94922 -1.76563,2.51954 L 335.28125,253.4375 c -0.99609,0.62891 -2.22266,0.78906 -3.35156,0.43359 L 206.07422,213.98437 c -0.39453,-0.1289 -0.80469,-0.1914 -1.21094,-0.1914 z m 0,0"
         fill="#690589"
         id="path1598" />
      <path
         d="m 105.78125,299.81641 c 0,16.74609 -13.574219,30.32031 -30.320312,30.32031 -16.746094,0 -30.320313,-13.57422 -30.320313,-30.32031 0,-16.74219 13.574219,-30.32032 30.320313,-30.32032 16.746093,0 30.320312,13.57813 30.320312,30.32032 z m 0,0"
         fill="#ff5252"
         id="path1600" />
      <path
         d="m 211.91406,188.30469 c -16.28515,-3.89453 -32.64453,6.15234 -36.53906,22.4375 -3.89063,16.28515 6.15625,32.64453 22.44141,36.53906 16.28515,3.89453 32.64453,-6.15234 36.53906,-22.44141 3.89453,-16.28515 -6.15235,-32.64453 -22.44141,-36.53515 z m 0,0"
         fill="#ff5252"
         id="path1602" />
      <path
         d="m 364.60547,258.67969 c -0.0664,-16.7461 -13.69141,-30.26563 -30.4375,-30.20313 -16.7461,0.0625 -30.26953,13.6875 -30.20313,30.4336 0.0625,16.74609 13.6875,30.26953 30.4336,30.20703 16.74609,-0.0664 30.26953,-13.69141 30.20703,-30.4375 z m 0,0"
         fill="#ff5252"
         id="path1604" />
      <g
         fill="#690589"
         id="g1616">
        <path
           d="m 75.460938,334.13672 c -18.925782,0 -34.320313,-15.39453 -34.320313,-34.32031 0,-18.92188 15.394531,-34.32032 34.320313,-34.32032 18.921874,0 34.320312,15.39844 34.320312,34.32032 0,18.92578 -15.398438,34.32031 -34.320312,34.32031 z m 0,-60.64063 c -14.511719,0 -26.320313,11.8086 -26.320313,26.32032 0,14.51171 11.808594,26.32031 26.320313,26.32031 14.511718,0 26.320312,-11.8086 26.320312,-26.32031 0,-14.51172 -11.808594,-26.32032 -26.320312,-26.32032 z m 0,0"
           id="path1606" />
        <path
           d="m 204.86719,252.11719 c -18.92578,0 -34.32032,-15.39844 -34.32032,-34.32422 0,-18.92188 15.39454,-34.32031 34.32032,-34.32031 18.92187,0 34.32031,15.39843 34.32031,34.32031 0,18.92578 -15.39844,34.32422 -34.32031,34.32422 z m 0,-60.64453 c -14.51172,0 -26.32032,11.80859 -26.32032,26.32031 0,14.51562 11.8086,26.32422 26.32032,26.32422 14.51172,0 26.32031,-11.8086 26.32031,-26.32422 0,-14.51172 -11.80859,-26.32031 -26.32031,-26.32031 z m 0,0"
           id="path1608" />
        <path
           d="m 334.27344,293.125 c -18.92188,0 -34.32032,-15.39453 -34.32032,-34.32031 0,-18.92188 15.39844,-34.32032 34.32032,-34.32032 18.92578,0 34.32031,15.39844 34.32031,34.32032 0,18.92578 -15.39453,34.32031 -34.32031,34.32031 z m 0,-60.64063 c -14.51172,0 -26.32032,11.8086 -26.32032,26.32032 0,14.51172 11.8086,26.32031 26.32032,26.32031 14.51562,0 26.32031,-11.80859 26.32031,-26.32031 0,-14.51172 -11.80469,-26.32032 -26.32031,-26.32032 z m 0,0"
           id="path1610" />
        <path
           d="M 500,374.32422 H 28 c -2.207031,0 -4,-1.78906 -4,-4 V 133.67578 c 0,-2.21094 1.792969,-4 4,-4 h 472 c 2.20703,0 4,1.78906 4,4 v 236.64844 c 0,2.21094 -1.79297,4 -4,4 z m -468,-8 H 496 V 137.67578 H 32 Z m 0,0"
           id="path1612" />
        <path
           d="m 44,187.64062 c -2.207031,0 -4,-1.79296 -4,-4 v -24 c 0,-2.20703 1.792969,-4 4,-4 2.207031,0 4,1.79297 4,4 v 24 c 0,2.20704 -1.792969,4 -4,4 z m 0,0"
           id="path1614" />
      </g>
    </g>
    <g
       transform="matrix(0.01603938,0,0,0.01559173,76.059332,16.135446)"
       id="g1882">
      <path
         d="M 66.378906,70.648438 V 141.79687 H 105.625 V 41.007812 Z m 0,0"
         fill="#fce06a"
         id="path1742" />
      <path
         d="M 105.625,145.79687 H 66.378906 c -2.207031,0 -4,-1.78906 -4,-4 V 70.648438 c 0,-1.253907 0.589844,-2.4375 1.589844,-3.191407 l 39.24609,-29.640625 c 1.21094,-0.917968 2.83594,-1.0625 4.19141,-0.386718 1.35938,0.675781 2.21875,2.058593 2.21875,3.578124 V 141.80078 c 0,2.20703 -1.78906,3.99609 -4,3.99609 z m -35.246094,-8 H 101.625 V 49.039062 L 70.378906,72.640625 Z m 0,0"
         fill="#690589"
         id="path1744" />
      <path
         d="m 279.60156,217.97656 h 60.01563 v 158.6836 h -60.01563 z m 0,0"
         fill="#f4efed"
         id="path1746" />
      <path
         css={css`fill:#3f51b5;fill-opacity:1;`}
         d="m 439.98437,154.98828 h 60.01954 v 221.67578 h -60.01954 z m 0,0"
         fill="#7bac51"
         id="path1748" />
      <path
         d="m 359.79297,186.48047 h 60.01562 v 190.17969 h -60.01562 z m 0,0"
         fill="#ffcd00"
         id="path1750" />
      <path
         d="m 279.60547,217.97656 h 13.03125 v 158.6836 h -13.03125 z m 0,0"
         fill="#ffffff"
         id="path1752" />
      <path
         d="m 326.58984,217.97656 h 13.03125 v 158.6836 h -13.03125 z m 0,0"
         fill="#d6d1cf"
         id="path1754" />
      <path
         d="m 359.79297,186.48047 h 13.03125 v 190.17969 h -13.03125 z m 0,0"
         fill="#fce06a"
         id="path1756" />
      <path
         d="m 406.77734,186.48047 h 13.03125 v 190.17969 h -13.03125 z m 0,0"
         fill="#dea42e"
         id="path1758" />
      <path
         css={css`fill:#6070c8;fill-opacity:1;`}
         d="m 439.98437,154.98828 h 13.03516 v 221.67578 h -13.03516 z m 0,0"
         fill="#99ca6f"
         id="path1760" />
      <path
         css={css`fill:#38427b;fill-opacity:1;`}
         d="m 486.96484,154.98828 h 13.03125 v 221.67578 h -13.03125 z m 0,0"
         fill="#5d8e33"
         id="path1762" />
      <g
         fill="#690589"
         id="g1770">
        <path
           d="m 339.62109,380.66016 h -60.01953 c -2.20703,0 -4,-1.78907 -4,-4 v -158.6836 c 0,-2.20703 1.79297,-4 4,-4 h 60.01953 c 2.20704,0 4,1.79297 4,4 v 158.6836 c 0,2.21093 -1.79296,4 -4,4 z m -56.01953,-8 h 52.01953 v -150.6836 h -52.01953 z m 0,0"
           id="path1764" />
        <path
           d="m 419.80859,380.66016 h -60.01562 c -2.20703,0 -4,-1.78907 -4,-4 V 186.48047 c 0,-2.20703 1.79297,-4 4,-4 h 60.01562 c 2.21094,0 4,1.79297 4,4 v 190.17969 c 0,2.21093 -1.78906,4 -4,4 z m -56.01562,-8 h 52.01562 V 190.48047 h -52.01562 z m 0,0"
           id="path1766" />
        <path
           d="m 500,380.66016 h -60.01563 c -2.20703,0 -4,-1.78907 -4,-4 V 154.98828 c 0,-2.20703 1.79297,-4 4,-4 H 500 c 2.20703,0 4,1.79297 4,4 v 221.67188 c 0,2.21093 -1.79297,4 -4,4 z m -56.01563,-8 H 496 V 158.98828 h -52.01563 z m 0,0"
           id="path1768" />
      </g>
      <path
         css={css`fill:#3f51b5;fill-opacity:1;`}
         d="m 294.76172,304.94141 h -76.53125 c 0.0469,-0.78516 0.12109,-1.56641 0.12109,-2.36329 v -77.01171 h -84.41797 v 77.01171 c 0,0.79688 0.0742,1.57813 0.1211,2.36329 H 57.523438 C 27.960938,304.94141 4,328.90625 4,358.46484 V 468.12891 H 348.28516 V 358.46484 c 0,-29.55859 -23.96485,-53.52343 -53.52344,-53.52343 z m 0,0"
         fill="#7bac51"
         id="path1772" />
      <path
         d="m 218.27344,304.76172 0.0781,-79.19531 h -84.41797 l 0.0781,79.19531 c 0.0234,23.25 18.8789,42.08594 42.1289,42.08594 23.25,0 42.10547,-18.83594 42.13282,-42.08594 z m 0,0"
         fill="#e18152"
         id="path1774" />
      <path
         d="m 84.886719,125.01172 h -6.515625 c -16.84375,0 -30.496094,13.65234 -30.496094,30.49609 0,16.84375 13.652344,30.4961 30.496094,30.4961 h 6.515625 z m 0,0"
         fill="#ffbd8e"
         id="path1776" />
      <path
         d="m 273.91406,125.01172 h -6.51562 v 60.99219 h 6.51562 c 16.84375,0 30.4961,-13.65235 30.4961,-30.4961 0,-16.84375 -13.65235,-30.49609 -30.4961,-30.49609 z m 0,0"
         fill="#e18152"
         id="path1778" />
      <path
         css={css`fill:#323e82;fill-opacity:1;`}
         d="m 294.76172,304.94141 h -18.77735 c 29.5625,0 53.52344,23.96093 53.52344,53.52343 V 468.125 h 18.77735 V 358.46484 c 0,-29.55859 -23.96485,-53.52343 -53.52344,-53.52343 z m 0,0"
         fill="#5d8e33"
         id="path1780" />
      <path
         css={css`fill:#5669d1;fill-opacity:1;`}
         d="m 57.523438,304.94141 h 18.773437 c -29.558594,0 -53.519531,23.96093 -53.519531,53.52343 V 468.125 H 4 V 358.46484 c 0,-29.55859 23.960938,-53.52343 53.523438,-53.52343 z m 0,0"
         fill="#99ca6f"
         id="path1782" />
      <path
         d="M 348.28516,472.125 H 4 c -2.207031,0 -4,-1.78906 -4,-4 V 358.46484 c 0,-31.71875 25.804688,-57.52343 57.523438,-57.52343 h 72.410152 v -75.375 c 0,-2.20704 1.78907,-4 4,-4 h 84.41797 c 2.20703,0 4,1.79296 4,4 v 75.375 h 72.41016 c 31.71484,0 57.52344,25.80468 57.52344,57.52343 V 468.125 c 0,2.21094 -1.79297,4 -4,4 z M 8,464.125 H 344.28516 V 358.46484 c 0,-27.30468 -22.21875,-49.52343 -49.52344,-49.52343 h -76.53516 c -1.09765,0 -2.15234,-0.45313 -2.90625,-1.25391 -0.7539,-0.79688 -1.14844,-1.875 -1.08594,-2.97266 l 0.0469,-0.73437 c 0.0312,-0.46094 0.0703,-0.92969 0.0703,-1.40235 V 229.56641 H 137.9336 v 73.01171 c 0,0.48047 0.0352,0.94922 0.0664,1.41407 l 0.0469,0.71875 c 0.0625,1.09765 -0.32812,2.17578 -1.08593,2.97656 -0.75391,0.80078 -1.8086,1.25391 -2.91016,1.25391 H 57.523438 C 30.214844,308.94141 8,331.16016 8,358.46484 Z m 0,0"
         fill="#690589"
         id="path1784" />
      <path
         d="m 19.019531,384.30078 c -2.207031,0 -4,-1.78906 -4,-4 v -24 c 0,-2.20703 1.792969,-4 4,-4 2.207031,0 4,1.79297 4,4 v 24 c 0,2.21094 -1.789062,4 -4,4 z m 0,0"
         fill="#690589"
         id="path1786" />
      <path
         d="m 176.14062,350.84766 c -25.41015,0 -46.10156,-20.67188 -46.125,-46.08204 l -0.082,-79.19531 c 0,-1.05859 0.42188,-2.07812 1.17188,-2.82812 0.75,-0.75391 1.76562,-1.17578 2.82812,-1.17578 h 84.41797 c 1.0625,0 2.08203,0.42187 2.83203,1.17578 0.75,0.75 1.16797,1.76953 1.16797,2.82812 l -0.0781,79.19531 c -0.0273,25.41016 -20.72266,46.08204 -46.13282,46.08204 z m -38.20312,-121.28125 0.0742,75.1914 c 0.0195,21.00391 17.125,38.08985 38.1289,38.08985 21.00391,0 38.10547,-17.08594 38.12891,-38.08985 l 0.0781,-75.1914 z m 80.33594,75.19531 h 0.0195 z m 0,0"
         fill="#690589"
         id="path1788" />
      <path
         d="m 176.14062,280.03906 c -50.39843,0 -91.253901,-41.39062 -91.253901,-92.4414 v -63.9961 c 0,-51.054685 40.855471,-92.441404 91.253901,-92.441404 50.40235,0 91.25782,41.386719 91.25782,92.441404 v 63.9961 c 0,51.05468 -40.85547,92.4414 -91.25782,92.4414 z m 0,0"
         fill="#ff9f70"
         id="path1790" />
      <path
         d="m 176.14062,31.160156 c -3.50781,0 -6.96093,0.21875 -10.36718,0.613282 45.51172,5.214843 80.88281,44.328124 80.88281,91.828122 v 64 c 0,47.5 -35.37109,86.60938 -80.88281,91.82813 3.40625,0.39062 6.85937,0.60937 10.36718,0.60937 50.40235,0 91.25782,-41.38672 91.25782,-92.4414 v -63.9961 c 0,-51.054685 -40.85547,-92.441404 -91.25782,-92.441404 z m 0,0"
         fill="#e18152"
         id="path1792" />
      <path
         d="m 176.14062,31.160156 c 3.50782,0 6.96485,0.21875 10.3711,0.613282 C 140.99609,36.988281 105.625,76.101562 105.625,123.60156 v 64 c 0,47.5 35.37109,86.60938 80.88672,91.82813 -3.40625,0.39062 -6.86328,0.60937 -10.3711,0.60937 -50.39843,0 -91.253901,-41.38672 -91.253901,-92.4414 v -63.9961 c 0,-51.054685 40.855471,-92.441404 91.253901,-92.441404 z m 0,0"
         fill="#ffbd8e"
         id="path1794" />
      <path
         d="m 176.14062,284.03906 c -52.52343,0 -95.253901,-43.26562 -95.253901,-96.4414 v -63.9961 c 0,-53.179685 42.730471,-96.441404 95.253901,-96.441404 52.52344,0 95.25782,43.261719 95.25782,96.441404 v 63.9961 c 0,53.17578 -42.73438,96.4414 -95.25782,96.4414 z m 0,-248.878904 c -48.11328,0 -87.253901,39.675782 -87.253901,88.441404 v 63.9961 c 0,48.76953 39.140621,88.4414 87.253901,88.4414 48.11719,0 87.25782,-39.67187 87.25782,-88.4414 v -63.9961 c 0,-48.765622 -39.14453,-88.441404 -87.25782,-88.441404 z m 0,0"
         fill="#690589"
         id="path1796" />
      <path
         d="m 84.886719,190.00391 h -6.515625 c -19.023438,0 -34.496094,-15.47266 -34.496094,-34.4961 0,-19.02344 15.472656,-34.49609 34.496094,-34.49609 h 6.515625 c 2.207031,0 4,1.79297 4,4 v 60.99219 c 0,2.20703 -1.792969,4 -4,4 z m -6.515625,-60.99219 c -14.609375,0 -26.496094,11.88672 -26.496094,26.49609 0,14.60938 11.886719,26.4961 26.496094,26.4961 h 2.515625 v -52.99219 z m 0,0"
         fill="#690589"
         id="path1798" />
      <path
         d="m 273.91406,190.00391 h -6.51562 c -2.20703,0 -4,-1.79297 -4,-4 v -60.99219 c 0,-2.20703 1.79297,-4 4,-4 h 6.51562 c 19.02344,0 34.4961,15.47265 34.4961,34.49609 0,19.02344 -15.47657,34.4961 -34.4961,34.4961 z m -2.51562,-8 h 2.51172 c 14.61328,0 26.49609,-11.88672 26.49609,-26.4961 0,-14.60937 -11.88281,-26.49609 -26.49609,-26.49609 h -2.51172 z m 0,0"
         fill="#690589"
         id="path1800" />
      <path
         d="M 246.41016,4.503906 H 36.183594 c 0,48.386719 39.226562,87.613282 87.617186,87.613282 h 102.81641 c 0,18.167972 14.72656,32.898442 32.89453,32.898442 h 27.25 V 44.855469 c 0,-22.289063 -18.0625,-40.351563 -40.35156,-40.351563 z m 0,0"
         fill="#ffcd00"
         id="path1802" />
      <path
         d="m 246.41016,4.503906 h -19.36329 c 22.28516,0 40.35157,18.0625 40.35157,40.347656 v 80.160158 h 19.36328 V 44.851562 c 0,-22.285156 -18.0625,-40.347656 -40.35156,-40.347656 z m 0,0"
         fill="#dea42e"
         id="path1804" />
      <path
         d="m 286.76172,129.01172 h -27.25 c -18.99609,0 -34.68359,-14.42188 -36.67969,-32.894532 h -99.03125 c -50.51953,0 -91.617186,-41.097657 -91.617186,-91.613282 0,-2.210937 1.792968,-4 4,-4 H 246.41406 c 24.45313,0 44.35156,19.890625 44.35156,44.347656 v 80.160158 c -0.004,2.20703 -1.79296,4 -4.0039,4 z M 40.277344,8.503906 c 2.09375,44.257813 38.757812,79.613282 83.523436,79.613282 h 102.81641 c 2.20703,0 4,1.789062 4,4 0,15.929692 12.96094,28.894532 28.89453,28.894532 h 23.25 V 44.851562 C 282.76172,24.808594 266.45703,8.5 246.41016,8.5 H 40.277344 Z m 0,0"
         fill="#690589"
         id="path1806" />
      <path
         d="m 389.80078,4.503906 c -37.33984,0 -67.60937,30.269532 -67.60937,67.609375 0,37.343749 30.26953,67.613279 67.60937,67.613279 37.34375,0 67.61328,-30.26953 67.61328,-67.613279 -0.004,-37.339843 -30.27344,-67.609375 -67.61328,-67.609375 z m 0,118.390624 c -28.04297,0 -50.78125,-22.73828 -50.78125,-50.781249 0,-28.046875 22.73828,-50.78125 50.78125,-50.78125 28.04688,0 50.78516,22.734375 50.78516,50.78125 0,28.042969 -22.73828,50.781249 -50.78516,50.781249 z m 0,0"
         fill="#ffcd00"
         id="path1808" />
      <path
         d="m 409.07422,25.132812 c -25.94141,-10.648437 -55.60938,1.753907 -66.25781,27.699219 -10.64844,25.945313 1.75,55.609379 27.69531,66.257809 25.94531,10.64844 55.60937,-1.75 66.25781,-27.695309 10.64844,-25.949219 -1.75,-55.613281 -27.69531,-66.261719 z m 0,0"
         fill="#f4efed"
         id="path1810" />
      <path
         d="m 389.80078,4.503906 c -1.3125,0 -2.61328,0.042969 -3.91016,0.117188 35.51954,2.027344 63.70313,31.464844 63.70313,67.492187 0,36.023439 -28.18359,65.460939 -63.70313,67.488279 1.29688,0.0742 2.59766,0.1211 3.91016,0.1211 37.33984,0 67.61328,-30.26953 67.61328,-67.613285 -0.004,-37.335937 -30.27344,-67.605469 -67.61328,-67.605469 z m 0,0"
         fill="#dea42e"
         id="path1812" />
      <path
         d="m 389.80078,139.72266 c 1.31641,0 2.61719,-0.043 3.91016,-0.11719 -35.51953,-2.02735 -63.69922,-31.46484 -63.69922,-67.492189 0,-36.023437 28.17969,-65.464843 63.69922,-67.488281 -1.29297,-0.074219 -2.59375,-0.121094 -3.91016,-0.121094 -37.33984,0 -67.60937,30.269532 -67.60937,67.613282 0,37.335942 30.26953,67.605472 67.60937,67.605472 z m 0,0"
         fill="#fce06a"
         id="path1814" />
      <path
         d="m 389.80078,168.67578 c 2.94141,0 5.68359,0.82031 8.03906,2.22656 v -31.67187 c -2.63672,0.3125 -5.3164,0.49219 -8.03906,0.49219 -2.72266,0 -5.39844,-0.17969 -8.03516,-0.49219 v 31.67187 c 2.35157,-1.40625 5.09375,-2.22656 8.03516,-2.22656 z m 0,0"
         fill="#f4efed"
         id="path1816" />
      <path
         d="m 397.83984,174.90234 c -0.70703,0 -1.41797,-0.1875 -2.04687,-0.5625 -3.64844,-2.17968 -8.33594,-2.17968 -11.98047,0 -1.23828,0.73047 -2.77344,0.75 -4.02344,0.0391 -1.25,-0.70704 -2.02344,-2.03907 -2.02344,-3.47657 V 139.2305 c 0,-1.14063 0.48829,-2.22656 1.33985,-2.98828 0.85547,-0.76172 1.98828,-1.10938 3.1289,-0.98047 5.27735,0.62109 9.85938,0.62109 15.13672,0 1.14063,-0.125 2.27344,0.21875 3.125,0.98047 0.85547,0.76172 1.34375,1.84765 1.34375,2.98828 v 31.67187 c 0,1.44141 -0.77343,2.76953 -2.02343,3.47657 -0.61329,0.34765 -1.29688,0.52343 -1.97657,0.52343 z m -12.07812,-31.29687 v 21.49219 c 2.64844,-0.5586 5.42969,-0.5586 8.07812,0 v -21.49219 c -2.75781,0.16015 -5.375,0.16015 -8.07812,0 z m 0,0"
         fill="#690589"
         id="path1818" />
      <path
         d="m 389.80078,126.89453 c -30.20312,0 -54.78125,-24.57422 -54.78125,-54.781249 0,-30.210937 24.57813,-54.785156 54.78125,-54.785156 30.20703,0 54.78125,24.574219 54.78125,54.785156 0,30.207029 -24.57422,54.781249 -54.78125,54.781249 z m 0,-101.562499 c -25.79297,0 -46.78125,20.984375 -46.78125,46.78125 0,25.796875 20.98828,46.781249 46.78125,46.781249 25.79688,0 46.78125,-20.984374 46.78125,-46.781249 0,-25.796875 -20.98437,-46.78125 -46.78125,-46.78125 z m 0,0"
         fill="#690589"
         id="path1820" />
      <path
         d="m 389.80078,143.72266 c -39.48828,0 -71.60937,-32.1211 -71.60937,-71.609379 C 318.19141,32.625 350.3125,0.5 389.80078,0.5 c 39.48828,0 71.61328,32.125 71.61328,71.613281 0,39.488279 -32.125,71.609379 -71.61328,71.609379 z m 0,-135.218754 c -35.07422,0 -63.60937,28.535156 -63.60937,63.609375 0,35.078129 28.53515,63.613279 63.60937,63.613279 35.07813,0 63.61328,-28.53515 63.61328,-63.613279 0,-35.074219 -28.53906,-63.609375 -63.61328,-63.609375 z m 0,0"
         fill="#690589"
         id="path1822" />
      <path
         d="m 389.80078,254.64844 c -8.68359,0 -15.72656,-7.03907 -15.72656,-15.72657 v -54.51953 c 0,-8.6875 7.04297,-15.72656 15.72656,-15.72656 8.6875,0 15.73047,7.04297 15.73047,15.72656 v 54.51953 c -0.004,8.6875 -7.04297,15.72657 -15.73047,15.72657 z m 0,0"
         fill="#ff5252"
         id="path1824" />
      <path
         d="m 389.80078,168.67578 c -1.45312,0 -2.85547,0.21484 -4.19141,0.58203 6.64454,1.83985 11.53125,7.91406 11.53125,15.14453 v 54.51953 c 0,7.23047 -4.88281,13.30469 -11.53125,15.14454 1.33594,0.37109 2.73829,0.58203 4.19141,0.58203 8.6875,0 15.73047,-7.03907 15.73047,-15.72657 v -54.51953 c -0.004,-8.6875 -7.04297,-15.72656 -15.73047,-15.72656 z m 0,0"
         fill="#e12579"
         id="path1826" />
      <path
         d="m 389.80078,258.64844 c -10.875,0 -19.72656,-8.84766 -19.72656,-19.72266 v -54.52344 c 0,-10.875 8.85156,-19.72656 19.72656,-19.72656 10.87891,0 19.72656,8.85156 19.72656,19.72656 v 54.52344 c 0,10.875 -8.84765,19.72266 -19.72656,19.72266 z m 0,-85.97266 c -6.46484,0 -11.72656,5.26172 -11.72656,11.72656 v 54.52344 c 0,6.46484 5.26172,11.72266 11.72656,11.72266 6.46875,0 11.72656,-5.25782 11.72656,-11.72266 v -54.52344 c 0,-6.46484 -5.25781,-11.72656 -11.72656,-11.72656 z m 0,0"
         fill="#690589"
         id="path1828" />
    </g>
    <g
       transform="matrix(0.01294051,0,0,0.0130856,19.677243,33.869113)"
       id="g1949">
      <path
         d="M 387.67187,500 H 4 V 4 h 288.94922 l 94.72265,94.726562 z m 0,0"
         fill="#f4efed"
         id="path1884" />
      <path
         d="M 4,4 H 24 V 500 H 4 Z m 0,0"
         fill="#ffffff"
         id="path1886" />
      <path
         d="M 367.67187,78.726562 V 500 h 20 V 98.726562 Z m 0,0"
         fill="#d6d1cf"
         id="path1888" />
      <path
         d="M 387.67187,98.726562 H 292.94922 V 4 Z m 0,0"
         fill="#ffcd00"
         id="path1890" />
      <path
         css={css`fill:#3f51b5;fill-opacity:1;`}
         d="M 4,321.06641 H 387.67187 V 500 H 4 Z m 0,0"
         fill="#7bac51"
         id="path1892" />
      <path
         css={css`fill:#6271bf;fill-opacity:1;`}
         d="M 4,321.06641 H 24 V 500 H 4 Z m 0,0"
         fill="#99ca6f"
         id="path1894" />
      <path
         css={css`fill:#313d81;fill-opacity:1;`}
         d="m 367.67187,321.06641 h 20 V 500 h -20 z m 0,0"
         fill="#5d8e33"
         id="path1896" />
      <g
         fill="#690589"
         id="g1920">
        <path
           d="M 387.67187,504 H 4 c -2.207031,0 -4,-1.78906 -4,-4 V 4 C 0,1.792969 1.792969,0 4,0 h 288.94922 c 1.05859,0 2.07812,0.421875 2.82812,1.171875 L 390.5,95.898438 c 0.75,0.75 1.17187,1.765624 1.17187,2.828124 V 500 c 0,2.21094 -1.79296,4 -4,4 z M 8,496 H 383.67187 V 100.38281 L 291.28906,8 H 8 Z m 0,0"
           id="path1898" />
        <path
           d="m 20,114.76563 c -2.207031,0 -4,-1.79297 -4,-4 V 86.765625 c 0,-2.207031 1.792969,-4 4,-4 2.207031,0 4,1.792969 4,4 v 24.000005 c 0,2.20703 -1.789062,4 -4,4 z m 0,0"
           id="path1900" />
        <path
           d="m 387.67187,102.72656 h -94.72265 c -2.20703,0 -4,-1.79297 -4,-3.999998 V 4 c 0,-1.617188 0.97265,-3.074219 2.46875,-3.695312 1.49609,-0.621094 3.21484,-0.2773442 4.35937,0.867187 L 390.5,95.898438 c 1.14453,1.144531 1.48828,2.863281 0.86719,4.359372 -0.61719,1.49219 -2.07813,2.46875 -3.69532,2.46875 z m -90.72265,-7.999998 h 81.0664 L 296.94922,13.65625 Z m 0,0"
           id="path1902" />
        <path
           d="M 387.67187,504 H 4 c -2.207031,0 -4,-1.78906 -4,-4 V 321.06641 c 0,-2.21094 1.792969,-4 4,-4 h 383.67187 c 2.20704,0 4,1.78906 4,4 V 500 c 0,2.21094 -1.79296,4 -4,4 z M 8,496 H 383.67187 V 325.06641 H 8 Z m 0,0"
           id="path1904" />
        <path
           d="M 195.83594,65.644531 H 49.507812 c -2.210937,0 -4,-1.792969 -4,-4 0,-2.207031 1.789063,-4 4,-4 H 195.83594 c 2.20703,0 4,1.792969 4,4 0,2.207031 -1.79297,4 -4,4 z m 0,0"
           id="path1906" />
        <path
           d="M 340.3125,134.76953 H 49.507812 c -2.210937,0 -4,-1.78906 -4,-4 0,-2.20703 1.789063,-4 4,-4 H 340.3125 c 2.20703,0 4,1.79297 4,4 0,2.21094 -1.79297,4 -4,4 z m 0,0"
           id="path1908" />
        <path
           d="M 340.3125,203.89453 H 49.507812 c -2.210937,0 -4,-1.79297 -4,-4 0,-2.20703 1.789063,-4 4,-4 H 340.3125 c 2.20703,0 4,1.79297 4,4 0,2.20703 -1.79297,4 -4,4 z m 0,0"
           id="path1910" />
        <path
           d="M 340.3125,273.01953 H 49.507812 c -2.210937,0 -4,-1.78906 -4,-4 0,-2.20703 1.789063,-4 4,-4 H 340.3125 c 2.20703,0 4,1.79297 4,4 0,2.21094 -1.79297,4 -4,4 z m 0,0"
           id="path1912" />
        <path
           css={css`fill:#fdfdfe;fill-opacity:1;`}
           d="M 67.589844,445.88281 V 368.75 c 0,-3.92188 3.136718,-7.19141 7.191406,-7.19141 4.050781,0 7.320312,3.26953 7.320312,7.19141 v 69.8125 h 35.429688 c 3.79297,0 7.19141,3.26953 7.19141,7.32031 0,3.92578 -3.39844,7.19141 -7.19141,7.19141 H 75.171875 c -0.261719,0 -0.261719,0 -0.261719,0 -0.128906,0 -0.128906,0 -0.128906,0 -4.050781,0 -7.191406,-3.26563 -7.191406,-7.19141 z m 0,0"
           id="path1914" />
        <path
           css={css`fill:#fdfdfe;fill-opacity:1;`}
           d="m 208.12891,372.93359 c 8.10546,8.62891 13.20312,20.13282 13.20312,33.07422 0,12.94532 -5.09766,24.58203 -13.20312,33.07813 -7.71485,8.5 -19.21875,13.98828 -31.2461,13.98828 -12.55078,0 -24.05469,-5.48828 -32.03125,-13.98828 -8.10547,-8.4961 -12.94531,-20.13281 -12.94531,-33.07813 0,-12.9414 4.83984,-24.44531 12.94531,-33.07422 7.97266,-8.6289 19.48047,-13.98828 32.03125,-13.98828 12.02735,0 23.53125,5.35938 31.2461,13.98828 z m -1.69922,33.07422 c 0,-8.88672 -3.53125,-17.12109 -8.89063,-22.875 -5.49219,-5.88281 -12.8125,-9.28515 -20.65625,-9.28515 -8.36719,0 -15.6914,3.39843 -21.17969,9.28515 -5.49218,5.75391 -8.89062,13.98828 -8.89062,22.875 0,8.89063 3.39844,17.12891 8.89062,22.75 5.48829,5.88282 12.8125,9.41407 21.17969,9.41407 7.84375,0 15.16406,-3.53125 20.65625,-9.41407 5.35938,-5.62109 8.89063,-13.85937 8.89063,-22.75 z m 0,0"
           id="path1916" />
        <path
           css={css`fill:#fdfdfe;fill-opacity:1;`}
           d="m 243.02734,406.14062 c 0,-26.28125 21.70313,-47.19921 48.24219,-47.19921 11.11328,0 21.70313,3.79297 29.80859,10.19921 3.39454,2.48438 3.92188,7.32422 1.4375,10.58985 -2.48437,3.14062 -7.32031,3.78906 -10.58984,1.17578 -5.625,-4.3125 -12.8125,-7.05859 -20.65625,-7.05859 -9.41406,0 -17.64844,3.53125 -23.53516,9.54296 -6.14453,5.88282 -9.80468,13.85938 -9.80468,22.75 0,8.625 3.66015,16.60157 9.80468,22.48829 5.88282,6.01171 14.1211,9.53906 23.53516,9.53906 6.53516,0 12.67969,-1.95703 17.91016,-4.96485 v -17.91015 h -18.69531 c -4.05469,0 -7.45313,-3.39844 -7.45313,-7.45313 0,-4.18359 3.39844,-7.32422 7.45313,-7.32422 H 316.5 c 4.18359,0 7.58203,3.14063 7.58203,7.32422 v 28.89063 c 0,0.91406 -0.26172,1.4375 -0.39062,2.22265 -0.12891,0.26172 -0.12891,0.39063 -0.26172,0.65235 v 0.1289 c -0.39453,1.17969 -1.17969,2.22266 -2.48438,3.14063 -8.23828,6.40234 -18.5664,10.19531 -29.67968,10.19531 -26.53516,0.004 -48.23829,-20.78125 -48.23829,-46.92969 z m 0,0"
           id="path1918" />
      </g>
    </g>
    <g
       transform="matrix(0.03513006,0,0,0.03486939,42.128827,22.09187)"
       id="g2079">
      <path
         css={css`fill:#3f51b5;fill-opacity:1;`}
         d="M 387.28125,311.6875 H 116.71875 V 96.816406 L 252,4 387.28125,96.816406 Z m 0,0"
         fill="#7bac51"
         id="path1951" />
      <path
         d="m 70.335938,124.08203 c 0,9.52734 -7.726563,17.25 -17.25,17.25 -9.527344,0 -17.25,-7.72266 -17.25,-17.25 0,-9.52734 7.722656,-17.25 17.25,-17.25 9.523437,0 17.25,7.72266 17.25,17.25 z m 0,0"
         fill="#ffcd00"
         id="path1953" />
      <path
         d="m 25.199219,187.42578 c -9.269531,-2.1875 -18.5625,3.55469 -20.75,12.82422 -2.191407,9.27344 3.550781,18.5625 12.824219,20.75391 9.269531,2.1875 18.5625,-3.55469 20.75,-12.82422 2.191406,-9.27344 -3.550782,-18.5625 -12.824219,-20.75391 z m 0,0"
         fill="#ffcd00"
         id="path1955" />
      <path
         d="m 57.03125,267.54687 c -9.273438,-2.1875 -18.5625,3.55469 -20.75,12.82422 -2.191406,9.27344 3.550781,18.5625 12.820312,20.75391 9.273438,2.1875 18.5625,-3.55469 20.753907,-12.82422 2.1875,-9.27344 -3.550781,-18.5625 -12.824219,-20.75391 z m 0,0"
         fill="#ffcd00"
         id="path1957" />
      <path
         d="m 468.16406,124.08203 c 0,9.52734 -7.72265,17.25 -17.25,17.25 -9.52344,0 -17.25,-7.72266 -17.25,-17.25 0,-9.52734 7.72656,-17.25 17.25,-17.25 9.52735,0 17.25,7.72266 17.25,17.25 z m 0,0"
         fill="#ffcd00"
         id="path1959" />
      <path
         d="m 499.49609,200.05078 c -2.27734,-9.25 -11.62109,-14.90625 -20.87109,-12.62891 -9.25,2.27735 -14.90625,11.6211 -12.62891,20.875 2.27735,9.25 11.6211,14.90235 20.875,12.625 9.25,-2.27734 14.90235,-11.62109 12.625,-20.87109 z m 0,0"
         fill="#ffcd00"
         id="path1961" />
      <path
         d="M 467.70703,280.41016 C 465.55078,271.13281 456.27734,265.35937 447,267.51562 c -9.28125,2.16016 -15.05469,11.42969 -12.89453,20.71094 2.15625,9.27735 11.42969,15.05078 20.70703,12.89453 9.28125,-2.16015 15.05469,-11.42968 12.89453,-20.71093 z m 0,0"
         fill="#ffcd00"
         id="path1963" />
      <path
         css={css`fill:#0a47a3;fill-opacity:1;`}
         d="M 368.71484,84.082031 V 311.6875 h 18.56641 V 96.816406 Z m 0,0"
         fill="#5d8e33"
         id="path1965" />
      <path
         css={css`fill:#6574c9;fill-opacity:1;`}
         d="M 135.28516,84.082031 V 311.6875 H 116.71875 V 96.816406 Z m 0,0"
         fill="#99ca6f"
         id="path1967" />
      <path
         d="M 349.95703,183.91797 H 154.04297 c -2.80078,0 -5.07031,-2.26953 -5.07031,-5.07031 v -53.74219 c 0,-2.80078 2.26953,-5.07031 5.07031,-5.07031 h 195.91406 c 2.80078,0 5.07031,2.26953 5.07031,5.07031 v 53.74219 c 0,2.80078 -2.26953,5.07031 -5.07031,5.07031 z m 0,0"
         fill="#f4efed"
         id="path1969" />
      <path
         d="M 322.32031,161.60937 H 227.8125 c -5.87891,0 -10.64844,-4.76562 -10.64844,-10.64843 0,-5.88282 4.76953,-10.64844 10.64844,-10.64844 h 94.50781 c 5.88281,0 10.64844,4.76562 10.64844,10.64844 0,5.88281 -4.76563,10.64843 -10.64844,10.64843 z m 0,0"
         fill="#7bac51"
         id="path1971" />
      <path
         d="M 349.95703,187.91797 H 154.04297 c -5,0 -9.07031,-4.07031 -9.07031,-9.07031 v -53.7461 c 0,-5 4.07031,-9.07031 9.07031,-9.07031 h 195.91406 c 5,0 9.07031,4.07031 9.07031,9.07031 v 53.7461 c 0,5 -4.07031,9.07031 -9.07031,9.07031 z M 154.04297,124.03125 c -0.58985,0 -1.07031,0.48047 -1.07031,1.07031 v 53.7461 c 0,0.58984 0.48046,1.07031 1.07031,1.07031 h 195.91406 c 0.58984,0 1.07031,-0.48047 1.07031,-1.07031 v -53.7461 c 0,-0.58984 -0.48047,-1.07031 -1.07031,-1.07031 z m 0,0"
         fill="#690589"
         id="path1973" />
      <path
         d="m 192.32422,150.96094 c 0,5.88281 -4.76563,10.64843 -10.64453,10.64843 -5.88282,0 -10.64844,-4.76562 -10.64844,-10.64843 0,-5.88282 4.76562,-10.64844 10.64844,-10.64844 5.8789,0 10.64453,4.76562 10.64453,10.64844 z m 0,0"
         fill="#ff5252"
         id="path1977" />
      <path
         d="m 181.67969,165.60937 c -8.07813,0 -14.64844,-6.57421 -14.64844,-14.64843 0,-8.07813 6.57031,-14.64844 14.64844,-14.64844 8.07422,0 14.64453,6.57031 14.64453,14.64844 0,8.07422 -6.57031,14.64843 -14.64453,14.64843 z m 0,-21.29687 c -3.66797,0 -6.64844,2.98437 -6.64844,6.64844 0,3.66797 2.98047,6.64843 6.64844,6.64843 3.66406,0 6.64453,-2.98046 6.64453,-6.64843 0,-3.66407 -2.98047,-6.64844 -6.64453,-6.64844 z m 0,0"
         fill="#690589"
         id="path1979" />
      <path
         d="M 349.95703,247.80078 H 154.04297 c -2.80078,0 -5.07031,-2.26953 -5.07031,-5.07031 v -53.74219 c 0,-2.80078 2.26953,-5.07031 5.07031,-5.07031 h 195.91406 c 2.80078,0 5.07031,2.26953 5.07031,5.07031 v 53.74219 c 0,2.80078 -2.26953,5.07031 -5.07031,5.07031 z m 0,0"
         fill="#f4efed"
         id="path1981" />
      <path
         css={css`fill:#ffcd00;fill-opacity:1;`}
         d="M 322.32031,225.49219 H 227.8125 c -5.87891,0 -10.64844,-4.76563 -10.64844,-10.64453 0,-5.88282 4.76953,-10.64844 10.64844,-10.64844 h 94.50781 c 5.88281,0 10.64844,4.76562 10.64844,10.64844 0,5.8789 -4.76563,10.64453 -10.64844,10.64453 z m 0,0"
         fill="#7bac51"
         id="path1983" />
      <path
         d="M 349.95703,251.80078 H 154.04297 c -5,0 -9.07031,-4.07031 -9.07031,-9.07031 v -53.7461 c 0,-5 4.07031,-9.07031 9.07031,-9.07031 h 195.91406 c 5,0 9.07031,4.07031 9.07031,9.07031 v 53.7461 c 0,5.0039 -4.07031,9.07031 -9.07031,9.07031 z M 154.04297,187.91797 c -0.58985,0 -1.07031,0.48047 -1.07031,1.07031 v 53.74609 c 0,0.58985 0.48046,1.07032 1.07031,1.07032 h 195.91406 c 0.58984,0 1.07031,-0.48047 1.07031,-1.07032 v -53.74609 c 0,-0.58984 -0.48047,-1.07031 -1.07031,-1.07031 z m 0,0"
         fill="#690589"
         id="path1985" />
      <path
         d="m 322.32031,229.49219 h -94.51172 c -8.07422,0 -14.64453,-6.57032 -14.64453,-14.64453 0,-8.07813 6.57031,-14.64844 14.64453,-14.64844 h 94.51172 c 8.07813,0 14.64844,6.57031 14.64844,14.64844 0,8.07421 -6.57031,14.64453 -14.64844,14.64453 z m -94.51172,-21.29297 c -3.66406,0 -6.64453,2.98047 -6.64453,6.64844 0,3.66406 2.98047,6.64453 6.64453,6.64453 h 94.51172 c 3.66797,0 6.64844,-2.98047 6.64844,-6.64453 0,-3.66797 -2.98047,-6.64844 -6.64844,-6.64844 h -51.7067 z"
         fill="#690589"
         id="path1987" />
      <path
         d="m 192.32422,214.84766 c 0,5.8789 -4.76563,10.64453 -10.64453,10.64453 -5.88282,0 -10.64844,-4.76563 -10.64844,-10.64453 0,-5.88282 4.76562,-10.64844 10.64844,-10.64844 5.8789,0 10.64453,4.76562 10.64453,10.64844 z m 0,0"
         fill="#ff5252"
         id="path1989" />
      <path
         d="m 181.67969,229.49219 c -8.07813,0 -14.64844,-6.57032 -14.64844,-14.64453 0,-8.07813 6.57031,-14.64844 14.64844,-14.64844 8.07422,0 14.64453,6.57031 14.64453,14.64844 0,8.07421 -6.57031,14.64453 -14.64453,14.64453 z m 0,-21.29297 c -3.66797,0 -6.64844,2.98047 -6.64844,6.64844 0,3.66406 2.98047,6.64453 6.64844,6.64453 3.66406,0 6.64453,-2.98047 6.64453,-6.64453 0,-3.66797 -2.98047,-6.64844 -6.64453,-6.64844 z m 0,0"
         fill="#690589"
         id="path1991" />
      <path
         d="M 349.95703,311.6875 H 154.04297 c -2.80078,0 -5.07031,-2.26953 -5.07031,-5.07031 V 252.875 c 0,-2.80078 2.26953,-5.07031 5.07031,-5.07031 h 195.91406 c 2.80078,0 5.07031,2.26953 5.07031,5.07031 v 53.74219 c 0,2.80078 -2.26953,5.07031 -5.07031,5.07031 z m 0,0"
         fill="#f4efed"
         id="path1993" />
      <path
         d="M 349.95703,315.6875 H 154.04297 c -5,0 -9.07031,-4.07031 -9.07031,-9.07031 V 252.875 c 0,-5 4.07031,-9.07031 9.07031,-9.07031 h 195.91406 c 5,0 9.07031,4.07031 9.07031,9.07031 v 53.74219 c 0,5 -4.07031,9.07031 -9.07031,9.07031 z M 154.04297,251.80078 c -0.58985,0 -1.07031,0.48047 -1.07031,1.07031 v 53.7461 c 0,0.58984 0.48046,1.07031 1.07031,1.07031 h 195.91406 c 0.58984,0 1.07031,-0.48047 1.07031,-1.07031 v -53.7461 c 0,-0.58984 -0.48047,-1.07031 -1.07031,-1.07031 z m 0,0"
         fill="#690589"
         id="path1997" />
      <path
         d="m 192.32422,278.73047 c 0,5.88281 -4.76563,10.64844 -10.64453,10.64844 -5.88282,0 -10.64844,-4.76563 -10.64844,-10.64844 0,-5.87891 4.76562,-10.64453 10.64844,-10.64453 5.8789,0 10.64453,4.76562 10.64453,10.64453 z m 0,0"
         fill="#ff5252"
         id="path2001" />
      <g
         fill="#690589"
         id="g2029">
        <path
           d="m 181.67969,293.37891 c -8.07813,0 -14.64844,-6.57422 -14.64844,-14.64844 0,-8.07813 6.57031,-14.64844 14.64844,-14.64844 8.07422,0 14.64453,6.57031 14.64453,14.64844 0,8.07422 -6.57031,14.64844 -14.64453,14.64844 z m 0,-21.29297 c -3.66797,0 -6.64844,2.98047 -6.64844,6.64453 0,3.66797 2.98047,6.64844 6.64844,6.64844 3.66406,0 6.64453,-2.98047 6.64453,-6.64844 0,-3.66406 -2.98047,-6.64453 -6.64453,-6.64453 z m 0,0"
           id="path2003" />
        <path
           d="m 387.28125,315.6875 h -270.5625 c -2.20703,0 -4,-1.79297 -4,-4 V 96.816406 c 0,-1.316406 0.65234,-2.550781 1.73828,-3.296875 L 249.73828,0.703125 c 1.36328,-0.9375 3.16016,-0.9375 4.52734,0 l 135.27735,92.816406 c 1.08984,0.746094 1.73828,1.980469 1.73828,3.296875 V 311.6875 c 0,2.20703 -1.79297,4 -4,4 z m -266.5625,-8 h 262.5625 V 98.925781 L 252,8.851562 120.71875,98.925781 Z m 0,0"
           id="path2005" />
        <path
           d="m 131.57031,141.71484 c -2.20703,0 -4,-1.79297 -4,-4 v -24 c 0,-2.20703 1.79297,-4 4,-4 2.20703,0 4,1.79297 4,4 v 24 c 0,2.20703 -1.78906,4 -4,4 z m 0,0"
           id="path2007" />
        <path
           d="m 53.085938,145.33594 c -11.71875,0 -21.25,-9.53516 -21.25,-21.25 0,-11.71875 9.53125,-21.25 21.25,-21.25 11.714843,0 21.25,9.53125 21.25,21.25 0,11.71484 -9.535157,21.25 -21.25,21.25 z m 0,-34.50391 c -7.308594,0 -13.25,5.94531 -13.25,13.25 0,7.30469 5.941406,13.25 13.25,13.25 7.304687,0 13.25,-5.94531 13.25,-13.25 0,-7.30469 -5.945313,-13.25 -13.25,-13.25 z m 0,0"
           id="path2009" />
        <path
           d="m 21.25,225.44922 c -11.714844,0 -21.25,-9.53516 -21.25,-21.25 0,-11.71875 9.535156,-21.25 21.25,-21.25 11.714844,0 21.25,9.53125 21.25,21.25 0,11.71484 -9.53125,21.25 -21.25,21.25 z m 0,-34.5 c -7.304688,0 -13.25,5.9414 -13.25,13.25 0,7.30469 5.945312,13.25 13.25,13.25 7.304688,0 13.25,-5.94531 13.25,-13.25 0,-7.3086 -5.945312,-13.25 -13.25,-13.25 z m 0,0"
           id="path2011" />
        <path
           d="m 53.085938,305.56641 c -11.71875,0 -21.25,-9.53516 -21.25,-21.25 0,-11.71485 9.53125,-21.25 21.25,-21.25 11.714843,0 21.25,9.53515 21.25,21.25 0,11.71484 -9.535157,21.25 -21.25,21.25 z m 0,-34.50391 c -7.308594,0 -13.25,5.94531 -13.25,13.25 0,7.30859 5.941406,13.25 13.25,13.25 7.304687,0 13.25,-5.94141 13.25,-13.25 0,-7.30469 -5.945313,-13.25 -13.25,-13.25 z m 0,0"
           id="path2013" />
        <path
           d="M 91.320312,288.3125 H 70.335938 c -2.210938,0 -4,-1.78906 -4,-4 0,-2.20703 1.789062,-4 4,-4 H 87.316406 V 128.08203 H 70.335938 c -2.210938,0 -4,-1.79297 -4,-4 0,-2.20703 1.789062,-4 4,-4 h 20.984374 c 2.207032,0 4,1.79297 4,4 V 284.3125 c 0,2.21094 -1.792968,4 -4,4 z m 0,0"
           id="path2015" />
        <path
           d="M 116.71875,208.19922 H 38.5 c -2.207031,0 -4,-1.79297 -4,-4 0,-2.21094 1.792969,-4 4,-4 h 78.21875 c 2.21094,0 4,1.78906 4,4 0,2.20703 -1.78906,4 -4,4 z m 0,0"
           id="path2017" />
        <path
           d="m 450.91406,145.33594 c -11.71484,0 -21.25,-9.53516 -21.25,-21.25 0,-11.71875 9.53516,-21.25 21.25,-21.25 11.71875,0 21.25,9.53125 21.25,21.25 0,11.71484 -9.53125,21.25 -21.25,21.25 z m 0,-34.50391 c -7.30469,0 -13.25,5.94531 -13.25,13.25 0,7.30469 5.94531,13.25 13.25,13.25 7.3086,0 13.25,-5.94531 13.25,-13.25 0,-7.30469 -5.9414,-13.25 -13.25,-13.25 z m 0,0"
           id="path2019" />
        <path
           d="m 482.75,225.44922 c -11.71484,0 -21.25,-9.53516 -21.25,-21.25 0,-11.71485 9.53516,-21.25 21.25,-21.25 11.71875,0 21.25,9.53515 21.25,21.25 0,11.71484 -9.53516,21.25 -21.25,21.25 z m 0,-34.5 c -7.30469,0 -13.25,5.9414 -13.25,13.25 0,7.30469 5.94531,13.25 13.25,13.25 7.30469,0 13.25,-5.94531 13.25,-13.25 0,-7.3086 -5.94531,-13.25 -13.25,-13.25 z m 0,0"
           id="path2021" />
        <path
           d="m 450.91406,305.56641 c -11.71484,0 -21.25,-9.53516 -21.25,-21.25 0,-11.71485 9.53516,-21.25 21.25,-21.25 11.71875,0 21.25,9.53515 21.25,21.25 0,11.71484 -9.53125,21.25 -21.25,21.25 z m 0,-34.50391 c -7.30469,0 -13.25,5.94531 -13.25,13.25 0,7.30859 5.94531,13.25 13.25,13.25 7.3086,0 13.25,-5.94141 13.25,-13.25 0,-7.30469 -5.9414,-13.25 -13.25,-13.25 z m 0,0"
           id="path2023" />
        <path
           d="m 433.66406,288.3125 h -20.98437 c -2.20703,0 -4,-1.78906 -4,-4 V 124.08203 c 0,-2.20703 1.79297,-4 4,-4 h 20.98437 c 2.20703,0 4,1.79297 4,4 0,2.20703 -1.79297,4 -4,4 H 416.67969 V 280.3125 h 16.98437 c 2.20703,0 4,1.79297 4,4 0,2.21094 -1.78906,4 -4,4 z m 0,0"
           id="path2025" />
        <path
           d="m 465.5,208.19922 h -78.21875 c -2.21094,0 -4,-1.79297 -4,-4 0,-2.21094 1.78906,-4 4,-4 h 78.21484 c 2.21094,0 4,1.78906 4,4 0,2.20703 -1.78906,4 -3.99609,4 z m 0,0"
           id="path2027" />
      </g>
      <path
         id="path1983-2"
         fill="#7bac51"
         d="m 322.57477,289.03426 h -94.5078 c -5.879,0 -10.6485,-4.76563 -10.6485,-10.64453 0,-5.88282 4.7695,-10.64844 10.6485,-10.64844 h 94.5078 c 5.8828,0 10.6484,4.76562 10.6484,10.64844 0,5.8789 -4.7656,10.64453 -10.6484,10.64453 z m 0,0"
         css={css`fill:#ffcd00;fill-opacity:1;stroke-width:1;`}/>
      <path
         css={css`stroke-width:1;`}
         id="path1987-0"
         fill="#690589"
         d="m 322.57477,293.03426 h -94.5118 c -8.0742,0 -14.6445,-6.57032 -14.6445,-14.64453 0,-8.07813 6.5703,-14.64844 14.6445,-14.64844 h 94.5118 c 8.0781,0 14.6484,6.57031 14.6484,14.64844 0,8.07421 -6.5703,14.64453 -14.6484,14.64453 z m -94.5118,-21.29297 c -3.664,0 -6.6445,2.98047 -6.6445,6.64844 0,3.66406 2.9805,6.64453 6.6445,6.64453 h 94.5118 c 3.6679,0 6.6484,-2.98047 6.6484,-6.64453 0,-3.66797 -2.9805,-6.64844 -6.6484,-6.64844 h -51.7067 z" />
      <path
         id="path1983-6"
         fill="#7bac51"
         d="m 322.32031,161.60937 h -94.5078 c -5.879,0 -10.6485,-4.76563 -10.6485,-10.64453 0,-5.88282 4.7695,-10.64844 10.6485,-10.64844 h 94.5078 c 5.8828,0 10.6484,4.76562 10.6484,10.64844 0,5.8789 -4.7656,10.64453 -10.6484,10.64453 z m 0,0"
         css={css`fill:#ffcd00;fill-opacity:1;stroke-width:1;`}/>
      <path
         css={css`stroke-width:1;`}
         id="path1987-6"
         fill="#690589"
         d="m 322.32031,165.60937 h -94.5118 c -8.0742,0 -14.6445,-6.57032 -14.6445,-14.64453 0,-8.07813 6.5703,-14.64844 14.6445,-14.64844 h 94.5118 c 8.0781,0 14.6484,6.57031 14.6484,14.64844 0,8.07421 -6.5703,14.64453 -14.6484,14.64453 z m -94.5118,-21.29297 c -3.664,0 -6.6445,2.98047 -6.6445,6.64844 0,3.66406 2.9805,6.64453 6.6445,6.64453 h 94.5118 c 3.6679,0 6.6484,-2.98047 6.6484,-6.64453 0,-3.66797 -2.9805,-6.64844 -6.6484,-6.64844 h -51.7067 z" />
    </g>
    <g
       id="g1580-7"
       transform="matrix(0.02070534,0,0,0.02241663,17.620355,12.279603)">
      <path
         id="path1492-2"
         fill="#f4efed"
         d="M 353.54297,412.91016 H 94.09375 c -5.921875,0 -10.722656,-4.79688 -10.722656,-10.71875 v -65.64454 c 0,-5.92187 4.800781,-10.72265 10.722656,-10.72265 h 259.44922 c 5.92187,0 10.72265,4.80078 10.72265,10.72265 v 65.64454 c 0,5.92187 -4.80078,10.71875 -10.72265,10.71875 z m 0,0" />
      <path
         id="path1494-0"
         fill="#7bac51"
         d="M 319.67969,382.50391 H 190.84375 c -8.01953,0 -14.51563,-6.5 -14.51563,-14.51563 0,-8.01562 6.4961,-14.51562 14.51563,-14.51562 h 128.83594 c 8.01562,0 14.51172,6.5 14.51172,14.51562 0.004,8.01563 -6.4961,14.51563 -14.51172,14.51563 z m 0,0"
         css={css`fill:#ffcd00;fill-opacity:1;`}/>
      <path
         id="path1496-6"
         fill="#690589"
         d="M 319.67969,386.50391 H 190.84375 c -10.20703,0 -18.51563,-8.3086 -18.51563,-18.51954 0,-10.20703 8.30469,-18.51171 18.51563,-18.51171 h 128.83594 c 10.20703,0 18.51562,8.30468 18.51562,18.51171 0,10.21094 -8.30859,18.51954 -18.51562,18.51954 z M 190.84375,357.47266 c -5.79688,0 -10.51563,4.71484 -10.51563,10.51171 0,5.80079 4.71485,10.51954 10.51563,10.51954 h 128.83594 c 5.79687,0 10.51562,-4.71875 10.51562,-10.51954 0,-5.79296 -4.71875,-10.51171 -10.51562,-10.51171 z m 0,0" />
      <path
         id="path1498-4"
         fill="#ff5252"
         d="m 142.47266,367.98828 c 0,8.01563 -6.5,14.51563 -14.51563,14.51563 -8.01562,0 -14.51172,-6.5 -14.51172,-14.51563 0,-8.01562 6.4961,-14.51562 14.51172,-14.51562 8.01563,0 14.51563,6.5 14.51563,14.51562 z m 0,0" />
      <path
         id="path1500-0"
         fill="#690589"
         d="m 127.95703,386.50391 c -10.21094,0 -18.51562,-8.3086 -18.51562,-18.51954 0,-10.20703 8.30468,-18.51171 18.51562,-18.51171 10.20703,0 18.51563,8.30468 18.51563,18.51171 0,10.21094 -8.3086,18.51954 -18.51563,18.51954 z m 0,-29.03125 c -5.79687,0 -10.51562,4.71484 -10.51562,10.51171 0,5.80079 4.71484,10.51954 10.51562,10.51954 5.79688,0 10.51563,-4.71875 10.51563,-10.51954 0,-5.79296 -4.71485,-10.51171 -10.51563,-10.51171 z m 0,0" />
      <path
         id="path1502-7"
         fill="#f4efed"
         d="M 353.54297,500 H 94.09375 c -5.921875,0 -10.722656,-4.80078 -10.722656,-10.71875 v -65.64844 c 0,-5.92187 4.800781,-10.72265 10.722656,-10.72265 h 259.44922 c 5.92187,0 10.72265,4.80078 10.72265,10.72265 v 65.64844 c 0,5.91797 -4.80078,10.71875 -10.72265,10.71875 z m 0,0" />
      <path
         id="path1504-4"
         fill="#7bac51"
         d="M 319.67969,469.58984 H 190.84375 c -8.01953,0 -14.51563,-6.5 -14.51563,-14.51562 0,-8.01563 6.4961,-14.51563 14.51563,-14.51563 h 128.83594 c 8.01562,0 14.51172,6.5 14.51172,14.51563 0.004,8.01562 -6.4961,14.51562 -14.51172,14.51562 z m 0,0"
         css={css`fill:#ffcd00;fill-opacity:1;`}/>
      <path
         id="path1506-5"
         fill="#690589"
         d="M 319.67969,473.58984 H 190.84375 c -10.20703,0 -18.51563,-8.30468 -18.51563,-18.51562 0,-10.21094 8.30469,-18.51563 18.51563,-18.51563 h 128.83594 c 10.20703,0 18.51562,8.30469 18.51562,18.51563 0,10.21094 -8.30859,18.51562 -18.51562,18.51562 z M 190.84375,444.55859 c -5.79688,0 -10.51563,4.71875 -10.51563,10.51563 0,5.79687 4.71485,10.51562 10.51563,10.51562 h 128.83594 c 5.79687,0 10.51562,-4.71875 10.51562,-10.51562 0,-5.79688 -4.71875,-10.51563 -10.51562,-10.51563 z m 0,0" />
      <path
         id="path1508-3"
         fill="#ff5252"
         d="m 142.47266,455.07422 c 0,8.01562 -6.5,14.51562 -14.51563,14.51562 -8.01562,0 -14.51172,-6.5 -14.51172,-14.51562 0,-8.01563 6.4961,-14.51563 14.51172,-14.51563 8.01563,0 14.51563,6.5 14.51563,14.51563 z m 0,0" />
      <path
         id="path1510-1"
         fill="#690589"
         d="m 127.95703,473.58984 c -10.21094,0 -18.51562,-8.30468 -18.51562,-18.51562 0,-10.21094 8.30468,-18.51563 18.51562,-18.51563 10.20703,0 18.51563,8.30469 18.51563,18.51563 0,10.21094 -8.3086,18.51562 -18.51563,18.51562 z m 0,-29.03125 c -5.79687,0 -10.51562,4.71875 -10.51562,10.51563 0,5.79687 4.71484,10.51562 10.51562,10.51562 5.79688,0 10.51563,-4.71875 10.51563,-10.51562 0,-5.79688 -4.71485,-10.51563 -10.51563,-10.51563 z m 0,0" />
      <path
         id="path1512-3"
         fill="#f4efed"
         d="M 353.54297,325.82422 H 94.09375 c -5.921875,0 -10.722656,-4.79688 -10.722656,-10.71875 v -65.64844 c 0,-5.91797 4.800781,-10.71875 10.722656,-10.71875 h 259.44922 c 5.92187,0 10.72265,4.80078 10.72265,10.71875 v 65.64844 c 0,5.92187 -4.80078,10.71875 -10.72265,10.71875 z m 0,0" />
      <g
         id="g1520-7"
         fill="#d6d1cf">
        <path
           id="path1514-7"
           d="m 353.54297,238.73828 h -21.4375 c 5.91797,0 10.71875,4.80078 10.71875,10.71875 v 65.64844 c 0,5.92187 -4.80078,10.71875 -10.71875,10.71875 h 21.4375 c 5.92187,0 10.72265,-4.79688 10.72265,-10.71875 v -65.64844 c 0,-5.91797 -4.80078,-10.71875 -10.72265,-10.71875 z m 0,0" />
        <path
           id="path1516-4"
           d="m 353.54297,325.82422 h -21.4375 c 5.91797,0 10.71875,4.80078 10.71875,10.72265 v 65.64844 c 0,5.91797 -4.80078,10.71875 -10.71875,10.71875 h 21.4375 c 5.92187,0 10.72265,-4.80078 10.72265,-10.71875 v -65.64844 c 0,-5.92187 -4.80078,-10.72265 -10.72265,-10.72265 z m 0,0" />
        <path
           id="path1518-6"
           d="m 353.54297,412.91016 h -21.4375 c 5.91797,0 10.71875,4.80078 10.71875,10.72265 v 65.64844 c 0,5.91797 -4.80078,10.71875 -10.71875,10.71875 h 21.4375 c 5.92187,0 10.72265,-4.80078 10.72265,-10.71875 v -65.64844 c 0,-5.92187 -4.80078,-10.72265 -10.72265,-10.72265 z m 0,0" />
      </g>
      <path
         id="path1522-5"
         fill="#ffffff"
         d="m 94.09375,500 h 21.4375 c -5.91797,0 -10.71875,-4.80078 -10.71875,-10.71875 v -65.64844 c 0,-5.92187 4.80078,-10.72265 10.71875,-10.72265 h -21.4375 c -5.921875,0 -10.722656,4.80078 -10.722656,10.72265 v 65.64844 c 0,5.91797 4.800781,10.71875 10.722656,10.71875 z m 0,0" />
      <path
         id="path1524-1"
         fill="#ffffff"
         d="m 94.09375,412.91016 h 21.4375 c -5.91797,0 -10.71875,-4.79688 -10.71875,-10.71875 v -65.64844 c 0,-5.91797 4.80078,-10.71875 10.71875,-10.71875 h -21.4375 c -5.921875,0 -10.722656,4.80078 -10.722656,10.71875 v 65.64844 c 0,5.92187 4.800781,10.71875 10.722656,10.71875 z m 0,0" />
      <path
         id="path1526-2"
         fill="#ffffff"
         d="m 94.09375,325.82422 h 21.4375 c -5.91797,0 -10.71875,-4.79688 -10.71875,-10.71875 v -65.64844 c 0,-5.91797 4.80078,-10.71875 10.71875,-10.71875 h -21.4375 c -5.921875,0 -10.722656,4.80078 -10.722656,10.71875 v 65.64844 c 0,5.92187 4.800781,10.71875 10.722656,10.71875 z m 0,0" />
      <path
         id="path1528-6"
         fill="#7bac51"
         d="M 319.67969,295.41406 H 190.84375 c -8.01953,0 -14.51563,-6.5 -14.51563,-14.51562 0,-8.01563 6.4961,-14.51172 14.51563,-14.51172 h 128.83594 c 8.01562,0 14.51172,6.49609 14.51172,14.51172 0.004,8.01562 -6.4961,14.51562 -14.51172,14.51562 z m 0,0"
         css={css`fill:#ffcd00;fill-opacity:1;`}/>
      <path
         id="path1530-5"
         fill="#690589"
         d="M 353.54297,329.82422 H 94.09375 c -8.121094,0 -14.722656,-6.60156 -14.722656,-14.71875 v -65.64453 c 0,-8.11719 6.605468,-14.72266 14.722656,-14.72266 h 259.45312 c 8.11719,0 14.71875,6.60547 14.71875,14.72266 v 65.64453 c 0,8.11719 -6.60546,14.71875 -14.72265,14.71875 z M 94.09375,242.73828 c -3.707031,0 -6.722656,3.01563 -6.722656,6.71875 v 65.64844 c 0,3.70312 3.015625,6.71875 6.722656,6.71875 h 259.45312 c 3.70704,0 6.71875,-3.01563 6.71875,-6.71875 v -65.64844 c 0,-3.70312 -3.01562,-6.71875 -6.71875,-6.71875 z m 0,0" />
      <path
         id="path1532-7"
         fill="#690589"
         d="M 319.67969,299.41406 H 190.84375 c -10.20703,0 -18.51563,-8.30469 -18.51563,-18.51562 0,-10.20703 8.30469,-18.51563 18.51563,-18.51563 h 128.83594 c 10.20703,0 18.51562,8.3086 18.51562,18.51563 0,10.21093 -8.30859,18.51562 -18.51562,18.51562 z M 190.84375,270.38281 c -5.79688,0 -10.51563,4.71875 -10.51563,10.51563 0,5.79687 4.71485,10.51562 10.51563,10.51562 h 128.83594 c 5.79687,0 10.51562,-4.71484 10.51562,-10.51562 0,-5.79688 -4.71875,-10.51563 -10.51562,-10.51563 z m 0,0" />
      <path
         id="path1534-9"
         fill="#ff5252"
         d="m 142.47266,280.89844 c 0,8.01562 -6.5,14.51562 -14.51563,14.51562 -8.01562,0 -14.51172,-6.5 -14.51172,-14.51562 0,-8.01563 6.4961,-14.51172 14.51172,-14.51172 8.01563,0 14.51563,6.49609 14.51563,14.51172 z m 0,0" />
      <path
         id="path1536-5"
         fill="#690589"
         d="m 127.95703,299.41406 c -10.21094,0 -18.51562,-8.30469 -18.51562,-18.51562 0,-10.20703 8.30468,-18.51563 18.51562,-18.51563 10.20703,0 18.51563,8.3086 18.51563,18.51563 0,10.21093 -8.3086,18.51562 -18.51563,18.51562 z m 0,-29.03125 c -5.79687,0 -10.51562,4.71875 -10.51562,10.51563 0,5.79687 4.71484,10.51562 10.51562,10.51562 5.79688,0 10.51563,-4.71484 10.51563,-10.51562 0,-5.79688 -4.71485,-10.51563 -10.51563,-10.51563 z m 0,0" />
      <path
         id="path1538-0"
         fill="#690589"
         d="M 353.54297,416.91016 H 94.09375 c -8.121094,0 -14.722656,-6.60157 -14.722656,-14.71485 v -65.64844 c 0,-8.11718 6.605468,-14.71875 14.722656,-14.71875 h 259.45312 c 8.11719,0 14.71875,6.60547 14.71875,14.71875 v 65.64844 c 0,8.11328 -6.60546,14.71485 -14.72265,14.71485 z M 94.09375,329.82422 c -3.707031,0 -6.722656,3.01953 -6.722656,6.72265 v 65.64454 c 0,3.70703 3.015625,6.71875 6.722656,6.71875 h 259.45312 c 3.70704,0 6.71875,-3.01563 6.71875,-6.71875 v -65.64454 c 0,-3.70703 -3.01562,-6.72265 -6.71875,-6.72265 z m 0,0" />
      <path
         id="path1540-2"
         fill="#690589"
         d="M 353.54297,504 H 94.09375 c -8.121094,0 -14.722656,-6.60547 -14.722656,-14.71875 v -65.64844 c 0,-8.11719 6.605468,-14.71875 14.722656,-14.71875 h 259.45312 c 8.11719,0 14.71875,6.60547 14.71875,14.71875 v 65.64844 c 0,8.11328 -6.60546,14.71875 -14.72265,14.71875 z M 94.09375,416.91016 c -3.707031,0 -6.722656,3.01953 -6.722656,6.72265 v 65.64453 c 0,3.70703 3.015625,6.71875 6.722656,6.71875 h 259.45312 c 3.70704,0 6.71875,-3.01562 6.71875,-6.71875 v -65.64453 c 0,-3.70703 -3.01562,-6.72265 -6.71875,-6.72265 z m 0,0" />
    </g>
    <text
       transform="translate(1.9761663,-5.1427669)"
       css={css`font-size:2px;line-height:1.25;font-family:sans-serif;letter-spacing:-0.05820830000000000px;white-space:pre;shape-inside:url(#rect2304);fill:#4b4b4b;fill-opacity:1;;`}
       id="text2302"
       ><tspan
         x="72.666016"
         y="31.053023"><tspan
           css={css`font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-family:sans-serif;-inkscape-font-specification:sans-serif;fill:#4b4b4b;fill-opacity:1`}>Business users</tspan></tspan></text>
    <text
       id="text2302-3"
       css={css`font-size:2px;line-height:1.25;font-family:sans-serif;letter-spacing:-0.05820830000000000px;white-space:pre;shape-inside:url(#rect2304-6);fill:#4b4b4b;fill-opacity:1;;`}
       transform="translate(-0.19242916,10.801021)"><tspan
         x="72.666016"
         y="31.053023"><tspan
           css={css`fill:#4b4b4b;fill-opacity:1;`}>Reporting dashboards</tspan></tspan></text>
    <text

       id="text2302-5"
       css={css`font-size:2px;line-height:1.25;font-family:sans-serif;letter-spacing:-0.05820830000000000px;white-space:pre;shape-inside:url(#rect2304-1);fill:#3f51b5;fill-opacity:1;;`}
       transform="translate(-28.313857,-18.681122)"><tspan
         x="72.666016"
         y="31.053023"><tspan
           css={css`font-weight:bold;fill:#3f51b5;fill-opacity:1;`}>DATA WAREHOUSE</tspan></tspan></text>
    <text
       transform="translate(-57.036995,-18.957369)"
       css={css`font-size:2px;line-height:1.25;font-family:sans-serif;letter-spacing:-0.05820830000000000px;white-space:pre;shape-inside:url(#rect2304-1-0);fill:#3f51b5;fill-opacity:1;;`}
       id="text2302-5-9"
       ><tspan
         x="72.666016"
         y="31.053023"><tspan
           css={css`font-weight:bold;fill:#3f51b5;fill-opacity:1;`}>DATA SOURCES</tspan></tspan></text>
    <text
       transform="translate(-66.434338,6.7552437)"
       css={css`font-size:2px;line-height:1.25;font-family:sans-serif;letter-spacing:-0.05820830000000000px;white-space:pre;shape-inside:url(#rect2304-6-2);fill:#4b4b4b;fill-opacity:1;;`}
       id="text2302-3-1"
       ><tspan
         x="69.666016"
         y="31.053023"><tspan
           css={css`fill:#4b4b4b;fill-opacity:1;`}>Call Center Logs</tspan></tspan></text>
    <text
       transform="translate(-66.033434,-1.7973813)"
       css={css`font-size:2px;line-height:1.25;font-family:sans-serif;letter-spacing:-0.05820830000000000px;white-space:pre;shape-inside:url(#rect2304-6-6);fill:#4b4b4b;fill-opacity:1;;`}
       id="text2302-3-16"
       ><tspan
         x="69.666016"
         y="31.053023"><tspan
         css={css`fill:#4b4b4b;fill-opacity:1;`}>Order &amp; Billing</tspan></tspan></text>
    <text
       id="text2302-3-16-3"
       css={css`font-size:2px;line-height:1.25;font-family:sans-serif;letter-spacing:-0.05820830000000000px;white-space:pre;shape-inside:url(#rect2304-6-6-9);fill:#4b4b4b;fill-opacity:1;;`}
       transform="translate(-62.58631,-9.6269194)"><tspan
         x="69.666016"
         y="31.053023"><tspan
           css={css`shape-inside:url(#rect2304-6-6-9);`}>CRM</tspan></tspan></text>
    <text
       transform="translate(2.9017041,-19.180889)"
       css={css`font-size:2px;line-height:1.25;font-family:sans-serif;letter-spacing:-0.05820830000000000px;white-space:pre;shape-inside:url(#rect2304-1-4);fill:#3f51b5;fill-opacity:1;;`}
       id="text2302-5-3"
       ><tspan
         x="72.666016"
         y="31.053023"><tspan
           css={css`font-weight:bold;fill:#3f51b5;fill-opacity:1;`}>ANALYTICS</tspan></tspan></text>
    <g
       transform="matrix(0.00923924,0,0,0.00908814,24.967154,21.108171)"
       id="g2557">
      <g
         id="g2555" />
      <g
         transform="matrix(-2.1698489,0,0,2.3957587,-1221.0525,-1610.2407)"
         id="g2640">
        <g
           id="g2681" />
      </g>
    </g>
    <path
       id="path2687"
       d="m 27.266956,21.662296 c 0,0 7.188986,-0.313799 12.473742,4.814977"
       css={css`fill:none;stroke:#919191;stroke-width:0.137388;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;`}/>
    <path
       transform="matrix(0.45758887,0.1842785,-0.2141156,0.50717146,39.862769,16.707201)"
       d="m 7.0158253,17.038432 0.2774207,-0.561073 0.2774207,-0.561074 0.3471933,0.52079 0.3471933,0.52079 -0.624614,0.04028 z"
       id="path2722"
       css={css`fill:#919191;fill-opacity:1;stroke:none;stroke-width:0.165;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:fill markers stroke;`}/>
    <g
       transform="matrix(0.84253791,0,0,0.82289099,6.1881758,1.8446998)"
       id="g2745">
      <path
         id="path2724"
         d="m 25.018197,24.082893 c 0,0 8.532537,-0.381337 14.804963,5.851294"
         css={css`fill:none;fill-opacity:1;stroke:#690589;stroke-width:0.165;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;`}/>
      <path
         transform="matrix(0.54310775,0.22394036,-0.25413171,0.61632885,39.968044,18.061324)"
         d="m 7.0158253,17.038432 0.2774207,-0.561073 0.2774207,-0.561074 0.3471933,0.52079 0.3471933,0.52079 -0.624614,0.04028 z"
         id="path2726"
         css={css`fill:#690589;fill-opacity:1;stroke:none;stroke-width:0.165;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:fill markers stroke;`}/>
      <circle cx="0" cy="0" r="0.5" fill="#3f51b5">
        <animateMotion dur="1.7s" repeatCount="indefinite" keyPoints="0;1" keyTimes="0;1" calcMode="linear">
          <mpath xlinkHref="#path2724" />
        </animateMotion>
      </circle>
    </g>
    <g
       transform="matrix(0.84253791,0,0,-0.82289099,6.0693093,56.238589)"
       id="g2745-9">
      <path
         css={css`fill:none;stroke:#690589;stroke-width:0.165;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;`}
         d="m 25.018197,24.082893 c 0,0 8.532537,-0.381337 14.804963,5.851294"
         id="path2724-7" />
      <path
         css={css`fill:#690589;fill-opacity:1;stroke:none;stroke-width:0.165;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:fill markers stroke;`}
         id="path2726-3"
         d="m 7.0158253,17.038432 0.2774207,-0.561073 0.2774207,-0.561074 0.3471933,0.52079 0.3471933,0.52079 -0.624614,0.04028 z"
         transform="matrix(0.54310775,0.22394036,-0.25413171,0.61632885,39.968044,18.061324)" />
      <circle cx="0" cy="0" r="0.5" fill="#3f51b5">
        <animateMotion dur="1.7s" repeatCount="indefinite" keyPoints="0;1" keyTimes="0;1" calcMode="linear">
          <mpath xlinkHref="#path2724-7" />
        </animateMotion>
      </circle>
    </g>
    <g
       id="g2745-9-4"
       transform="matrix(0.8099461,0,0,-0.82289099,7.490577,51.816935)">
      <g
         transform="matrix(0.96275591,0,0,1,0.90935156,0.80180858)"
         id="g2791">
        <path
           css={css`fill:none;stroke:#690589;stroke-width:0.165;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;`}
           d="m 24.41684,26.621954 c 0,0 8.625707,-0.08823 15.594271,0.03779"
           id="path2724-7-8" />
        <path
           css={css`fill:#690589;fill-opacity:1;stroke:none;stroke-width:0.165;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:fill markers stroke;`}
           id="path2726-3-9"
           d="M 6.4767174,11.502872 6.0927285,12.030363 5.7337618,12.57519 5.4689361,11.978901 5.1765853,11.395613 l 0.6488146,0.0688 z"
           transform="matrix(0.54310775,0.22394036,-0.25413171,0.61632885,39.968044,18.061324)" />
        <circle cx="0" cy="0" r="0.5" fill="#3f51b5">
          <animateMotion dur="1.7s" repeatCount="indefinite" keyPoints="0;1" keyTimes="0;1" calcMode="linear">
            <mpath xlinkHref="#path2724-7-8" />
          </animateMotion>
        </circle>
      </g>
    </g>
    <g
       transform="matrix(-0.75695299,0,0,0.87118855,91.118482,-0.03659833)"
       id="g2745-2">
      <path
         css={css`fill:none;fill-opacity:1;stroke:#690589;stroke-width:0.165;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;`}
         d="m 25.018197,24.082893 c 0,0 8.532537,-0.381337 14.804963,5.851294"
         id="path2724-6" />
      <path
         css={css`fill:#690589;fill-opacity:1;stroke:none;stroke-width:0.165;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:fill markers stroke;`}
         id="path2726-8"
         d="m 7.0158253,17.038432 0.2774207,-0.561073 0.2774207,-0.561074 0.3471933,0.52079 0.3471933,0.52079 -0.624614,0.04028 z"
         transform="matrix(0.54310775,0.22394036,-0.25413171,0.61632885,39.968044,18.061324)" />
      <circle cx="0" cy="0" r="0.5" fill="#3f51b5">
        <animateMotion dur="1.6s" repeatCount="indefinite" keyPoints="1;0" keyTimes="0;1" calcMode="linear">
          <mpath xlinkHref="#path2724-6" />
        </animateMotion>
      </circle>
    </g>
    <g
       id="g2745-2-4"
       transform="matrix(-0.75695299,0,0,-0.87118855,91.320783,58.031126)">
      <path
         id="path2724-6-3"
         d="m 25.018197,24.082893 c 0,0 8.532537,-0.381337 14.804963,5.851294"
         css={css`fill:none;fill-opacity:1;stroke:#690589;stroke-width:0.165;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;`}/>
      <path
         transform="matrix(0.54310775,0.22394036,-0.25413171,0.61632885,39.968044,18.061324)"
         d="m 7.0158253,17.038432 0.2774207,-0.561073 0.2774207,-0.561074 0.3471933,0.52079 0.3471933,0.52079 -0.624614,0.04028 z"
         id="path2726-8-4"
         css={css`fill:#690589;fill-opacity:1;stroke:none;stroke-width:0.165;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:fill markers stroke;`}/>
      <circle cx="0" cy="0" r="0.5" fill="#3f51b5">
        <animateMotion dur="1.6s" repeatCount="indefinite" keyPoints="1;0" keyTimes="0;1" calcMode="linear">
          <mpath xlinkHref="#path2724-6-3" />
        </animateMotion>
      </circle>
    </g>
  </g>

</svg>


);


export default DataWarehouse;
