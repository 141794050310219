import React from 'react';
import { css } from '@emotion/core';
import Tooltip from './Tooltip';


const S3Animation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="svg1234"
    viewBox="0 0 347.90637 146.06736"
    height="70.06735mm"
    width="100.90637mm"
  >
    <defs
      id="defs1228"
    />
    <g
      css={css`display:inline`}
      id="layer2"
    >
      <g
        css={css`stroke-width:0.61561233`}
        transform="matrix(1.624399,0,0,1.624399,54.389429,-75.474816)"
        id="g5650"
      >
        <rect
          css={css`fill:#3a70bf;fill-opacity:1;stroke:#000000;stroke-width:0.61561233;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1`}
          id="rect5117"
          width="24.619135"
          height="2.6894319"
          x="62.876041"
          y="76.789948"
        />
        <path
          css={css`display:inline;fill:#6befae;fill-opacity:1;stroke-width:0.3257615`}
          d="m 61.279396,52.849929 h 28.79099 c 0.53221,0 0.97858,0.404577 0.97858,0.922442 v 6.101065 c 0,0.50168 -0.4292,0.922443 -0.97858,0.922443 h -28.79099 c -0.53222,0 -0.97859,-0.404581 -0.97859,-0.922443 v -6.101065 c 0,-0.517865 0.4292,-0.922442 0.97859,-0.922442 z"
          id="path1822"
        />
        <g
          transform="matrix(0.17168155,0,0,0.161832,58.154786,50.827027)"
          css={css`display:inline;fill:#173f7f;stroke-width:1.95436549`}
          id="g1848"
        >
          <path
            d="m 131.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1828"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 131.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1830"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1832"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1834"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 H 90.4 C 89,33.2 87.8,32 87.8,30.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1836"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1838"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1840"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1842"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1844"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1846"
            css={css`stroke-width:1.95436549`}
          />
        </g>
        <ellipse
          css={css`display:inline;fill:#70d6f9;stroke-width:0.3257615`}
          cx="69.382812"
          cy="56.733898"
          id="circle1850"
          rx="1.6824793"
          ry="1.5859536"
        />
        <ellipse
          css={css`display:inline;fill:#ef4460;stroke-width:0.3257615`}
          cx="64.249527"
          cy="56.814816"
          id="circle1852"
          rx="1.6824793"
          ry="1.5859536"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 90.053226,61.184275 c 0.77256,0 1.40778,-0.598779 1.40778,-1.327023 v -6.084881 c 0,-0.728244 -0.63522,-1.327023 -1.40778,-1.327023 h -28.77383 c -0.77257,0 -1.40779,0.598779 -1.40779,1.327023 v 6.101065 c 0,0.728244 0.63522,1.327024 1.40779,1.327024 h 28.77383 z m -29.32322,-1.327023 v -6.084881 c 0,-0.275116 0.24036,-0.517865 0.54939,-0.517865 h 28.79099 c 0.29186,0 0.54938,0.226567 0.54938,0.517865 v 6.101065 c 0,0.275114 -0.24035,0.517862 -0.54938,0.517862 h -28.79099 c -0.30903,-0.01618 -0.54939,-0.242748 -0.54939,-0.534046 z"
          id="path1926"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,56.604429 h 1.21894 c 0.48071,0 0.87557,-0.372213 0.87557,-0.825343 v -1.149007 c 0,-0.45313 -0.39486,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0172,-1.97435 c 0,-0.01618 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1928"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,59.824887 h 1.21894 c 0.48071,0 0.87557,-0.372213 0.87557,-0.825344 v -1.149005 c 0,-0.453131 -0.39486,-0.825344 -0.87557,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0172,-1.974349 c 0,0 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1930"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,56.604429 h 1.21894 c 0.4807,0 0.87557,-0.372213 0.87557,-0.825343 v -1.149007 c 0,-0.45313 -0.39487,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.97435 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1932"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,59.824887 h 1.21894 c 0.4807,0 0.87557,-0.372213 0.87557,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87557,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1934"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,56.604429 h 1.21893 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825343 v -1.149007 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21893 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.97435 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1936"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,59.824887 h 1.21893 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21893 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1938"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,56.604429 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825343 v -1.149007 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.97435 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1940"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,59.824887 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1942"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,56.604429 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825343 v -1.149007 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.97435 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1944"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,59.824887 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1946"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 69.382756,58.708246 c 1.16744,0 2.11168,-0.890077 2.11168,-1.990533 0,-1.100457 -0.94424,-1.990534 -2.11168,-1.990534 -1.16743,0 -2.11168,0.890077 -2.11168,1.990534 0,1.100456 0.94425,1.990533 2.11168,1.990533 z m 0,-3.155724 c 0.68673,0 1.25328,0.534045 1.25328,1.181375 0,0.647326 -0.56655,1.181371 -1.25328,1.181371 -0.68672,0 -1.25327,-0.534045 -1.25327,-1.181371 0,-0.663513 0.56655,-1.181375 1.25327,-1.181375 z"
          id="path1948"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 64.249476,58.805345 c 1.16744,0 2.11169,-0.890077 2.11169,-1.990534 0,-1.100456 -0.94425,-1.990533 -2.11169,-1.990533 -1.16743,0 -2.11168,0.906258 -2.11168,1.990533 0,1.100457 0.94425,1.990534 2.11168,1.990534 z m 0,-3.155724 c 0.68673,0 1.25328,0.534046 1.25328,1.181375 0,0.647325 -0.56655,1.181371 -1.25328,1.181371 -0.68672,0 -1.25327,-0.534046 -1.25327,-1.181371 0,-0.663514 0.56655,-1.181375 1.25327,-1.181375 z"
          id="path1950"
        />
        <path
          css={css`display:inline;fill:#6befae;fill-opacity:1;stroke-width:0.3257615`}
          d="m 61.279396,60.941534 h 28.79099 c 0.53221,0 0.97858,0.404578 0.97858,0.922442 v 6.101065 c 0,0.501681 -0.4292,0.922442 -0.97858,0.922442 h -28.79099 c -0.53222,0 -0.97859,-0.404581 -0.97859,-0.922442 v -6.101065 c 0,-0.517864 0.4292,-0.922442 0.97859,-0.922442 z"
          id="path1822-7"
        />
        <g
          transform="matrix(0.17168155,0,0,0.161832,58.154786,58.918632)"
          css={css`display:inline;fill:#173f7f;stroke-width:1.95436549`}
          id="g1848-9"
        >
          <path
            d="m 131.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1828-3"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 131.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1830-8"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1832-0"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1834-2"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 H 90.4 C 89,33.2 87.8,32 87.8,30.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1836-4"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1838-8"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1840-3"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1842-9"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1844-0"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1846-5"
            css={css`stroke-width:1.95436549`}
          />
        </g>
        <ellipse
          css={css`display:inline;fill:#70d6f9;stroke-width:0.3257615`}
          cx="69.382812"
          cy="64.825516"
          id="circle1850-2"
          rx="1.6824793"
          ry="1.5859536"
        />
        <ellipse
          css={css`display:inline;fill:#ef4460;stroke-width:0.3257615`}
          cx="64.249527"
          cy="64.906433"
          id="circle1852-2"
          rx="1.6824793"
          ry="1.5859536"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 90.053226,69.27588 c 0.77256,0 1.40778,-0.598779 1.40778,-1.327023 v -6.084881 c 0,-0.728244 -0.63522,-1.327023 -1.40778,-1.327023 h -28.77383 c -0.77257,0 -1.40779,0.598779 -1.40779,1.327023 v 6.101065 c 0,0.728244 0.63522,1.327024 1.40779,1.327024 h 28.77383 z m -29.32322,-1.327023 v -6.084881 c 0,-0.275116 0.24036,-0.517864 0.54939,-0.517864 h 28.79099 c 0.29186,0 0.54938,0.226566 0.54938,0.517864 v 6.101065 c 0,0.275114 -0.24035,0.517861 -0.54938,0.517861 h -28.79099 c -0.30903,-0.01618 -0.54939,-0.242747 -0.54939,-0.534045 z"
          id="path1926-7"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,64.696034 h 1.21894 c 0.48071,0 0.87557,-0.372213 0.87557,-0.825344 v -1.149005 c 0,-0.453131 -0.39486,-0.825344 -0.87557,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0172,-1.974349 c 0,-0.01618 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1928-3"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,67.916492 h 1.21894 c 0.48071,0 0.87557,-0.372212 0.87557,-0.825344 v -1.149006 c 0,-0.45313 -0.39486,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0172,-1.97435 c 0,0 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1930-7"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,64.696034 h 1.21894 c 0.4807,0 0.87557,-0.372213 0.87557,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87557,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1932-9"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,67.916492 h 1.21894 c 0.4807,0 0.87557,-0.372212 0.87557,-0.825344 v -1.149006 c 0,-0.45313 -0.39487,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.97435 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1934-0"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,64.696034 h 1.21893 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21893 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1936-2"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,67.916492 h 1.21893 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825344 v -1.149006 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21893 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.97435 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1938-3"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,64.696034 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1940-9"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,67.916492 h 1.21894 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825344 v -1.149006 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.97435 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1942-9"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,64.696034 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1944-7"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,67.916492 h 1.21894 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825344 v -1.149006 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.97435 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1946-0"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 69.382756,66.799851 c 1.16744,0 2.11168,-0.890077 2.11168,-1.990534 0,-1.100456 -0.94424,-1.990534 -2.11168,-1.990534 -1.16743,0 -2.11168,0.890078 -2.11168,1.990534 0,1.100457 0.94425,1.990534 2.11168,1.990534 z m 0,-3.155724 c 0.68673,0 1.25328,0.534046 1.25328,1.181375 0,0.647326 -0.56655,1.181371 -1.25328,1.181371 -0.68672,0 -1.25327,-0.534045 -1.25327,-1.181371 0,-0.663514 0.56655,-1.181375 1.25327,-1.181375 z"
          id="path1948-3"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 64.249476,66.89695 c 1.16744,0 2.11169,-0.890077 2.11169,-1.990533 0,-1.100457 -0.94425,-1.990534 -2.11169,-1.990534 -1.16743,0 -2.11168,0.906258 -2.11168,1.990534 0,1.100456 0.94425,1.990533 2.11168,1.990533 z m 0,-3.155724 c 0.68673,0 1.25328,0.534046 1.25328,1.181374 0,0.647327 -0.56655,1.181372 -1.25328,1.181372 -0.68672,0 -1.25327,-0.534045 -1.25327,-1.181372 0,-0.663513 0.56655,-1.181374 1.25327,-1.181374 z"
          id="path1950-9"
        />
        <path
          css={css`display:inline;fill:#6befae;fill-opacity:1;stroke-width:0.3257615`}
          d="m 61.279396,68.822759 h 28.79099 c 0.53221,0 0.97858,0.404578 0.97858,0.922442 v 6.101065 c 0,0.50168 -0.4292,0.922441 -0.97858,0.922441 h -28.79099 c -0.53222,0 -0.97859,-0.404581 -0.97859,-0.922441 v -6.101065 c 0,-0.517864 0.4292,-0.922442 0.97859,-0.922442 z"
          id="path1822-8"
        />
        <g
          transform="matrix(0.17168155,0,0,0.161832,58.154786,66.799858)"
          css={css`display:inline;fill:#173f7f;stroke-width:1.95436549`}
          id="g1848-6"
        >
          <path
            d="m 131.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1828-5"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 131.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1830-7"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1832-6"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1834-27"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 H 90.4 C 89,33.2 87.8,32 87.8,30.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1836-0"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1838-3"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1840-9"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1842-99"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1844-1"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1846-7"
            css={css`stroke-width:1.95436549`}
          />
        </g>
        <ellipse
          css={css`display:inline;fill:#70d6f9;stroke-width:0.3257615`}
          cx="69.382812"
          cy="72.706726"
          id="circle1850-23"
          rx="1.6824793"
          ry="1.5859536"
        />
        <ellipse
          css={css`display:inline;fill:#ef4460;stroke-width:0.3257615`}
          cx="64.249527"
          cy="72.787643"
          id="circle1852-6"
          rx="1.6824793"
          ry="1.5859536"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 90.053226,77.157102 c 0.77256,0 1.40778,-0.598779 1.40778,-1.327023 v -6.084878 c 0,-0.728244 -0.63522,-1.327023 -1.40778,-1.327023 h -28.77383 c -0.77257,0 -1.40779,0.598779 -1.40779,1.327023 v 6.101065 c 0,0.728244 0.63522,1.327023 1.40779,1.327023 h 28.77383 z m -29.32322,-1.327023 v -6.084878 c 0,-0.275116 0.24036,-0.517864 0.54939,-0.517864 h 28.79099 c 0.29186,0 0.54938,0.226567 0.54938,0.517864 v 6.101065 c 0,0.275117 -0.24035,0.51786 -0.54938,0.51786 h -28.79099 c -0.30903,-0.01615 -0.54939,-0.242743 -0.54939,-0.534047 z"
          id="path1926-5"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,72.577259 h 1.21894 c 0.48071,0 0.87557,-0.372212 0.87557,-0.825343 V 70.60291 c 0,-0.453131 -0.39486,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0172,-1.974349 c 0,-0.01618 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1928-5"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,75.797721 h 1.21894 c 0.48071,0 0.87557,-0.372216 0.87557,-0.825351 v -1.149002 c 0,-0.453131 -0.39486,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0172,-1.974353 c 0,0 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01615 z"
          id="path1930-8"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,72.577259 h 1.21894 c 0.4807,0 0.87557,-0.372212 0.87557,-0.825343 V 70.60291 c 0,-0.453131 -0.39487,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1932-1"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,75.797721 h 1.21894 c 0.4807,0 0.87557,-0.372216 0.87557,-0.825351 v -1.149002 c 0,-0.453131 -0.39487,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0171,-1.974353 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01615 z"
          id="path1934-4"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,72.577259 h 1.21893 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825343 V 70.60291 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21893 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1936-7"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,75.797721 h 1.21893 c 0.48071,0 0.87558,-0.372216 0.87558,-0.825351 v -1.149002 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21893 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0171,-1.974353 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01615 z"
          id="path1938-1"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,72.577259 h 1.21894 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825343 V 70.60291 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1940-3"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,75.797721 h 1.21894 c 0.48071,0 0.87558,-0.372216 0.87558,-0.825351 v -1.149002 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0171,-1.974353 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01615 z"
          id="path1942-8"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,72.577259 h 1.21894 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825343 V 70.60291 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1944-4"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,75.797721 h 1.21894 c 0.48071,0 0.87558,-0.372216 0.87558,-0.825351 v -1.149002 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0171,-1.974353 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01615 z"
          id="path1946-8"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 69.382756,74.681074 c 1.16744,0 2.11168,-0.890075 2.11168,-1.990531 0,-1.100457 -0.94424,-1.990534 -2.11168,-1.990534 -1.16743,0 -2.11168,0.890077 -2.11168,1.990534 0,1.100456 0.94425,1.990531 2.11168,1.990531 z m 0,-3.155722 c 0.68673,0 1.25328,0.534046 1.25328,1.181375 0,0.647326 -0.56655,1.181372 -1.25328,1.181372 -0.68672,0 -1.25327,-0.534046 -1.25327,-1.181372 0,-0.663513 0.56655,-1.181375 1.25327,-1.181375 z"
          id="path1948-0"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 64.249476,74.778173 c 1.16744,0 2.11169,-0.890074 2.11169,-1.990531 0,-1.100456 -0.94425,-1.990534 -2.11169,-1.990534 -1.16743,0 -2.11168,0.906259 -2.11168,1.990534 0,1.100457 0.94425,1.990531 2.11168,1.990531 z m 0,-3.155721 c 0.68673,0 1.25328,0.534045 1.25328,1.181374 0,0.647326 -0.56655,1.18137 -1.25328,1.18137 -0.68672,0 -1.25327,-0.534044 -1.25327,-1.18137 0,-0.663513 0.56655,-1.181374 1.25327,-1.181374 z"
          id="path1950-4"
        />
      </g>
      <g
        css={css`display:inline;stroke-width:0.61561233`}
        transform="matrix(1.624399,0,0,1.624399,163.00633,-75.234376)"
        id="g5650-8"
      >
        <rect
          css={css`fill:#3a70bf;fill-opacity:1;stroke:#000000;stroke-width:0.61561233;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1`}
          id="rect5117-9"
          width="24.619135"
          height="2.6894319"
          x="62.876041"
          y="76.789948"
        />
        <path
          css={css`display:inline;fill:#a890db;fill-opacity:0.89411765;stroke-width:0.3257615`}
          d="m 61.279396,52.849929 h 28.79099 c 0.53221,0 0.97858,0.404577 0.97858,0.922442 v 6.101065 c 0,0.50168 -0.4292,0.922443 -0.97858,0.922443 h -28.79099 c -0.53222,0 -0.97859,-0.404581 -0.97859,-0.922443 v -6.101065 c 0,-0.517865 0.4292,-0.922442 0.97859,-0.922442 z"
          id="path1822-6"
        />
        <g
          transform="matrix(0.17168155,0,0,0.161832,58.154786,50.827027)"
          css={css`display:inline;fill:#173f7f;stroke-width:1.95436549`}
          id="g1848-0"
        >
          <path
            d="m 131.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1828-4"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 131.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1830-2"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1832-3"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1834-3"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 H 90.4 C 89,33.2 87.8,32 87.8,30.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1836-7"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1838-0"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1840-0"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1842-1"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1844-03"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1846-0"
            css={css`stroke-width:1.95436549`}
          />
        </g>
        <ellipse
          css={css`display:inline;fill:#70d6f9;stroke-width:0.3257615`}
          cx="69.382812"
          cy="56.733898"
          id="circle1850-0"
          rx="1.6824793"
          ry="1.5859536"
        />
        <ellipse
          css={css`display:inline;fill:#ef4460;stroke-width:0.3257615`}
          cx="64.249527"
          cy="56.814816"
          id="circle1852-3"
          rx="1.6824793"
          ry="1.5859536"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 90.053226,61.184275 c 0.77256,0 1.40778,-0.598779 1.40778,-1.327023 v -6.084881 c 0,-0.728244 -0.63522,-1.327023 -1.40778,-1.327023 h -28.77383 c -0.77257,0 -1.40779,0.598779 -1.40779,1.327023 v 6.101065 c 0,0.728244 0.63522,1.327024 1.40779,1.327024 h 28.77383 z m -29.32322,-1.327023 v -6.084881 c 0,-0.275116 0.24036,-0.517865 0.54939,-0.517865 h 28.79099 c 0.29186,0 0.54938,0.226567 0.54938,0.517865 v 6.101065 c 0,0.275114 -0.24035,0.517862 -0.54938,0.517862 h -28.79099 c -0.30903,-0.01618 -0.54939,-0.242748 -0.54939,-0.534046 z"
          id="path1926-8"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,56.604429 h 1.21894 c 0.48071,0 0.87557,-0.372213 0.87557,-0.825343 v -1.149007 c 0,-0.45313 -0.39486,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0172,-1.97435 c 0,-0.01618 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1928-36"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,59.824887 h 1.21894 c 0.48071,0 0.87557,-0.372213 0.87557,-0.825344 v -1.149005 c 0,-0.453131 -0.39486,-0.825344 -0.87557,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0172,-1.974349 c 0,0 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1930-0"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,56.604429 h 1.21894 c 0.4807,0 0.87557,-0.372213 0.87557,-0.825343 v -1.149007 c 0,-0.45313 -0.39487,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.97435 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1932-4"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,59.824887 h 1.21894 c 0.4807,0 0.87557,-0.372213 0.87557,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87557,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1934-09"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,56.604429 h 1.21893 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825343 v -1.149007 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21893 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.97435 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1936-24"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,59.824887 h 1.21893 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21893 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1938-9"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,56.604429 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825343 v -1.149007 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.97435 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1940-2"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,59.824887 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1942-91"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,56.604429 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825343 v -1.149007 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.97435 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1944-0"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,59.824887 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1946-9"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 69.382756,58.708246 c 1.16744,0 2.11168,-0.890077 2.11168,-1.990533 0,-1.100457 -0.94424,-1.990534 -2.11168,-1.990534 -1.16743,0 -2.11168,0.890077 -2.11168,1.990534 0,1.100456 0.94425,1.990533 2.11168,1.990533 z m 0,-3.155724 c 0.68673,0 1.25328,0.534045 1.25328,1.181375 0,0.647326 -0.56655,1.181371 -1.25328,1.181371 -0.68672,0 -1.25327,-0.534045 -1.25327,-1.181371 0,-0.663513 0.56655,-1.181375 1.25327,-1.181375 z"
          id="path1948-30"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 64.249476,58.805345 c 1.16744,0 2.11169,-0.890077 2.11169,-1.990534 0,-1.100456 -0.94425,-1.990533 -2.11169,-1.990533 -1.16743,0 -2.11168,0.906258 -2.11168,1.990533 0,1.100457 0.94425,1.990534 2.11168,1.990534 z m 0,-3.155724 c 0.68673,0 1.25328,0.534046 1.25328,1.181375 0,0.647325 -0.56655,1.181371 -1.25328,1.181371 -0.68672,0 -1.25327,-0.534046 -1.25327,-1.181371 0,-0.663514 0.56655,-1.181375 1.25327,-1.181375 z"
          id="path1950-0"
        />
        <path
          css={css`display:inline;fill:#a890db;fill-opacity:0.89411765;stroke-width:0.3257615`}
          d="m 61.279396,60.941534 h 28.79099 c 0.53221,0 0.97858,0.404578 0.97858,0.922442 v 6.101065 c 0,0.501681 -0.4292,0.922442 -0.97858,0.922442 h -28.79099 c -0.53222,0 -0.97859,-0.404581 -0.97859,-0.922442 v -6.101065 c 0,-0.517864 0.4292,-0.922442 0.97859,-0.922442 z"
          id="path1822-7-3"
        />
        <g
          transform="matrix(0.17168155,0,0,0.161832,58.154786,58.918632)"
          css={css`display:inline;fill:#173f7f;stroke-width:1.95436549`}
          id="g1848-9-0"
        >
          <path
            d="m 131.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1828-3-1"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 131.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1830-8-9"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1832-0-8"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1834-2-6"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 H 90.4 C 89,33.2 87.8,32 87.8,30.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1836-4-9"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1838-8-0"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1840-3-1"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1842-9-6"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1844-0-4"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1846-5-4"
            css={css`stroke-width:1.95436549`}
          />
        </g>
        <ellipse
          css={css`display:inline;fill:#70d6f9;stroke-width:0.3257615`}
          cx="69.382812"
          cy="64.825516"
          id="circle1850-2-8"
          rx="1.6824793"
          ry="1.5859536"
        />
        <ellipse
          css={css`display:inline;fill:#ef4460;stroke-width:0.3257615`}
          cx="64.249527"
          cy="64.906433"
          id="circle1852-2-6"
          rx="1.6824793"
          ry="1.5859536"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 90.053226,69.27588 c 0.77256,0 1.40778,-0.598779 1.40778,-1.327023 v -6.084881 c 0,-0.728244 -0.63522,-1.327023 -1.40778,-1.327023 h -28.77383 c -0.77257,0 -1.40779,0.598779 -1.40779,1.327023 v 6.101065 c 0,0.728244 0.63522,1.327024 1.40779,1.327024 h 28.77383 z m -29.32322,-1.327023 v -6.084881 c 0,-0.275116 0.24036,-0.517864 0.54939,-0.517864 h 28.79099 c 0.29186,0 0.54938,0.226566 0.54938,0.517864 v 6.101065 c 0,0.275114 -0.24035,0.517861 -0.54938,0.517861 h -28.79099 c -0.30903,-0.01618 -0.54939,-0.242747 -0.54939,-0.534045 z"
          id="path1926-7-1"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,64.696034 h 1.21894 c 0.48071,0 0.87557,-0.372213 0.87557,-0.825344 v -1.149005 c 0,-0.453131 -0.39486,-0.825344 -0.87557,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0172,-1.974349 c 0,-0.01618 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1928-3-4"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,67.916492 h 1.21894 c 0.48071,0 0.87557,-0.372212 0.87557,-0.825344 v -1.149006 c 0,-0.45313 -0.39486,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0172,-1.97435 c 0,0 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1930-7-0"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,64.696034 h 1.21894 c 0.4807,0 0.87557,-0.372213 0.87557,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87557,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1932-9-8"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,67.916492 h 1.21894 c 0.4807,0 0.87557,-0.372212 0.87557,-0.825344 v -1.149006 c 0,-0.45313 -0.39487,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.97435 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1934-0-1"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,64.696034 h 1.21893 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21893 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1936-2-0"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,67.916492 h 1.21893 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825344 v -1.149006 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21893 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.97435 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1938-3-3"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,64.696034 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1940-9-1"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,67.916492 h 1.21894 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825344 v -1.149006 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.97435 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1942-9-1"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,64.696034 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1944-7-5"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,67.916492 h 1.21894 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825344 v -1.149006 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.97435 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1946-0-0"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 69.382756,66.799851 c 1.16744,0 2.11168,-0.890077 2.11168,-1.990534 0,-1.100456 -0.94424,-1.990534 -2.11168,-1.990534 -1.16743,0 -2.11168,0.890078 -2.11168,1.990534 0,1.100457 0.94425,1.990534 2.11168,1.990534 z m 0,-3.155724 c 0.68673,0 1.25328,0.534046 1.25328,1.181375 0,0.647326 -0.56655,1.181371 -1.25328,1.181371 -0.68672,0 -1.25327,-0.534045 -1.25327,-1.181371 0,-0.663514 0.56655,-1.181375 1.25327,-1.181375 z"
          id="path1948-3-8"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 64.249476,66.89695 c 1.16744,0 2.11169,-0.890077 2.11169,-1.990533 0,-1.100457 -0.94425,-1.990534 -2.11169,-1.990534 -1.16743,0 -2.11168,0.906258 -2.11168,1.990534 0,1.100456 0.94425,1.990533 2.11168,1.990533 z m 0,-3.155724 c 0.68673,0 1.25328,0.534046 1.25328,1.181374 0,0.647327 -0.56655,1.181372 -1.25328,1.181372 -0.68672,0 -1.25327,-0.534045 -1.25327,-1.181372 0,-0.663513 0.56655,-1.181374 1.25327,-1.181374 z"
          id="path1950-9-4"
        />
        <path
          css={css`display:inline;fill:#a890db;fill-opacity:0.89411765;stroke-width:0.3257615`}
          d="m 61.279396,68.822759 h 28.79099 c 0.53221,0 0.97858,0.404578 0.97858,0.922442 v 6.101065 c 0,0.50168 -0.4292,0.922441 -0.97858,0.922441 h -28.79099 c -0.53222,0 -0.97859,-0.404581 -0.97859,-0.922441 v -6.101065 c 0,-0.517864 0.4292,-0.922442 0.97859,-0.922442 z"
          id="path1822-8-9"
        />
        <g
          transform="matrix(0.17168155,0,0,0.161832,58.154786,66.799858)"
          css={css`display:inline;fill:#173f7f;stroke-width:1.95436549`}
          id="g1848-6-3"
        >
          <path
            d="m 131.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1828-5-0"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 131.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1830-7-5"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1832-6-2"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1834-27-3"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 H 90.4 C 89,33.2 87.8,32 87.8,30.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1836-0-8"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1838-3-7"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1840-9-8"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1842-99-0"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1844-1-4"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1846-7-5"
            css={css`stroke-width:1.95436549`}
          />
        </g>
        <ellipse
          css={css`display:inline;fill:#70d6f9;stroke-width:0.3257615`}
          cx="69.382812"
          cy="72.706726"
          id="circle1850-23-7"
          rx="1.6824793"
          ry="1.5859536"
        />
        <ellipse
          css={css`display:inline;fill:#ef4460;stroke-width:0.3257615`}
          cx="64.249527"
          cy="72.787643"
          id="circle1852-6-1"
          rx="1.6824793"
          ry="1.5859536"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 90.053226,77.157102 c 0.77256,0 1.40778,-0.598779 1.40778,-1.327023 v -6.084878 c 0,-0.728244 -0.63522,-1.327023 -1.40778,-1.327023 h -28.77383 c -0.77257,0 -1.40779,0.598779 -1.40779,1.327023 v 6.101065 c 0,0.728244 0.63522,1.327023 1.40779,1.327023 h 28.77383 z m -29.32322,-1.327023 v -6.084878 c 0,-0.275116 0.24036,-0.517864 0.54939,-0.517864 h 28.79099 c 0.29186,0 0.54938,0.226567 0.54938,0.517864 v 6.101065 c 0,0.275117 -0.24035,0.51786 -0.54938,0.51786 h -28.79099 c -0.30903,-0.01615 -0.54939,-0.242743 -0.54939,-0.534047 z"
          id="path1926-5-0"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,72.577259 h 1.21894 c 0.48071,0 0.87557,-0.372212 0.87557,-0.825343 V 70.60291 c 0,-0.453131 -0.39486,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0172,-1.974349 c 0,-0.01618 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1928-5-6"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,75.797721 h 1.21894 c 0.48071,0 0.87557,-0.372216 0.87557,-0.825351 v -1.149002 c 0,-0.453131 -0.39486,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0172,-1.974353 c 0,0 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01615 z"
          id="path1930-8-0"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,72.577259 h 1.21894 c 0.4807,0 0.87557,-0.372212 0.87557,-0.825343 V 70.60291 c 0,-0.453131 -0.39487,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1932-1-3"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,75.797721 h 1.21894 c 0.4807,0 0.87557,-0.372216 0.87557,-0.825351 v -1.149002 c 0,-0.453131 -0.39487,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0171,-1.974353 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01615 z"
          id="path1934-4-3"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,72.577259 h 1.21893 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825343 V 70.60291 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21893 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1936-7-5"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,75.797721 h 1.21893 c 0.48071,0 0.87558,-0.372216 0.87558,-0.825351 v -1.149002 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21893 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0171,-1.974353 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01615 z"
          id="path1938-1-0"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,72.577259 h 1.21894 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825343 V 70.60291 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1940-3-2"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,75.797721 h 1.21894 c 0.48071,0 0.87558,-0.372216 0.87558,-0.825351 v -1.149002 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0171,-1.974353 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01615 z"
          id="path1942-8-3"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,72.577259 h 1.21894 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825343 V 70.60291 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1944-4-2"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,75.797721 h 1.21894 c 0.48071,0 0.87558,-0.372216 0.87558,-0.825351 v -1.149002 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0171,-1.974353 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01615 z"
          id="path1946-8-7"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 69.382756,74.681074 c 1.16744,0 2.11168,-0.890075 2.11168,-1.990531 0,-1.100457 -0.94424,-1.990534 -2.11168,-1.990534 -1.16743,0 -2.11168,0.890077 -2.11168,1.990534 0,1.100456 0.94425,1.990531 2.11168,1.990531 z m 0,-3.155722 c 0.68673,0 1.25328,0.534046 1.25328,1.181375 0,0.647326 -0.56655,1.181372 -1.25328,1.181372 -0.68672,0 -1.25327,-0.534046 -1.25327,-1.181372 0,-0.663513 0.56655,-1.181375 1.25327,-1.181375 z"
          id="path1948-0-1"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 64.249476,74.778173 c 1.16744,0 2.11169,-0.890074 2.11169,-1.990531 0,-1.100456 -0.94425,-1.990534 -2.11169,-1.990534 -1.16743,0 -2.11168,0.906259 -2.11168,1.990534 0,1.100457 0.94425,1.990531 2.11168,1.990531 z m 0,-3.155721 c 0.68673,0 1.25328,0.534045 1.25328,1.181374 0,0.647326 -0.56655,1.18137 -1.25328,1.18137 -0.68672,0 -1.25327,-0.534044 -1.25327,-1.18137 0,-0.663513 0.56655,-1.181374 1.25327,-1.181374 z"
          id="path1950-4-0"
        />
      </g>
      <g
        css={css`display:inline;stroke-width:0.61561233`}
        transform="matrix(1.624399,0,0,1.624399,-56.662839,-76.009224)"
        id="g5650-0"
      >
        <rect
          css={css`fill:#3a70bf;fill-opacity:1;stroke:#000000;stroke-width:0.61561233;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1`}
          id="rect5117-97"
          width="24.619135"
          height="2.6894319"
          x="62.876041"
          y="76.789948"
        />
        <path
          css={css`display:inline;fill:#6590db;fill-opacity:0.89411765;stroke-width:0.3257615`}
          d="m 61.279396,52.849929 h 28.79099 c 0.53221,0 0.97858,0.404577 0.97858,0.922442 v 6.101065 c 0,0.50168 -0.4292,0.922443 -0.97858,0.922443 h -28.79099 c -0.53222,0 -0.97859,-0.404581 -0.97859,-0.922443 v -6.101065 c 0,-0.517865 0.4292,-0.922442 0.97859,-0.922442 z"
          id="path1822-1"
        />
        <g
          transform="matrix(0.17168155,0,0,0.161832,58.154786,50.827027)"
          css={css`display:inline;fill:#173f7f;stroke-width:1.95436549`}
          id="g1848-7"
        >
          <path
            d="m 131.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1828-45"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 131.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1830-1"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1832-7"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1834-8"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 H 90.4 C 89,33.2 87.8,32 87.8,30.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1836-2"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1838-5"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1840-4"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1842-5"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1844-3"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1846-01"
            css={css`stroke-width:1.95436549`}
          />
        </g>
        <ellipse
          css={css`display:inline;fill:#70d6f9;stroke-width:0.3257615`}
          cx="69.382812"
          cy="56.733898"
          id="circle1850-9"
          rx="1.6824793"
          ry="1.5859536"
        />
        <ellipse
          css={css`display:inline;fill:#ef4460;stroke-width:0.3257615`}
          cx="64.249527"
          cy="56.814816"
          id="circle1852-4"
          rx="1.6824793"
          ry="1.5859536"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 90.053226,61.184275 c 0.77256,0 1.40778,-0.598779 1.40778,-1.327023 v -6.084881 c 0,-0.728244 -0.63522,-1.327023 -1.40778,-1.327023 h -28.77383 c -0.77257,0 -1.40779,0.598779 -1.40779,1.327023 v 6.101065 c 0,0.728244 0.63522,1.327024 1.40779,1.327024 h 28.77383 z m -29.32322,-1.327023 v -6.084881 c 0,-0.275116 0.24036,-0.517865 0.54939,-0.517865 h 28.79099 c 0.29186,0 0.54938,0.226567 0.54938,0.517865 v 6.101065 c 0,0.275114 -0.24035,0.517862 -0.54938,0.517862 h -28.79099 c -0.30903,-0.01618 -0.54939,-0.242748 -0.54939,-0.534046 z"
          id="path1926-59"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,56.604429 h 1.21894 c 0.48071,0 0.87557,-0.372213 0.87557,-0.825343 v -1.149007 c 0,-0.45313 -0.39486,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0172,-1.97435 c 0,-0.01618 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1928-39"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,59.824887 h 1.21894 c 0.48071,0 0.87557,-0.372213 0.87557,-0.825344 v -1.149005 c 0,-0.453131 -0.39486,-0.825344 -0.87557,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0172,-1.974349 c 0,0 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1930-6"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,56.604429 h 1.21894 c 0.4807,0 0.87557,-0.372213 0.87557,-0.825343 v -1.149007 c 0,-0.45313 -0.39487,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.97435 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1932-6"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,59.824887 h 1.21894 c 0.4807,0 0.87557,-0.372213 0.87557,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87557,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1934-40"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,56.604429 h 1.21893 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825343 v -1.149007 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21893 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.97435 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1936-8"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,59.824887 h 1.21893 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21893 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1938-2"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,56.604429 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825343 v -1.149007 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.97435 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1940-0"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,59.824887 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1942-6"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,56.604429 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825343 v -1.149007 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149007 c 0,0.45313 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.97435 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149007 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1944-07"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,59.824887 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1946-2"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 69.382756,58.708246 c 1.16744,0 2.11168,-0.890077 2.11168,-1.990533 0,-1.100457 -0.94424,-1.990534 -2.11168,-1.990534 -1.16743,0 -2.11168,0.890077 -2.11168,1.990534 0,1.100456 0.94425,1.990533 2.11168,1.990533 z m 0,-3.155724 c 0.68673,0 1.25328,0.534045 1.25328,1.181375 0,0.647326 -0.56655,1.181371 -1.25328,1.181371 -0.68672,0 -1.25327,-0.534045 -1.25327,-1.181371 0,-0.663513 0.56655,-1.181375 1.25327,-1.181375 z"
          id="path1948-9"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 64.249476,58.805345 c 1.16744,0 2.11169,-0.890077 2.11169,-1.990534 0,-1.100456 -0.94425,-1.990533 -2.11169,-1.990533 -1.16743,0 -2.11168,0.906258 -2.11168,1.990533 0,1.100457 0.94425,1.990534 2.11168,1.990534 z m 0,-3.155724 c 0.68673,0 1.25328,0.534046 1.25328,1.181375 0,0.647325 -0.56655,1.181371 -1.25328,1.181371 -0.68672,0 -1.25327,-0.534046 -1.25327,-1.181371 0,-0.663514 0.56655,-1.181375 1.25327,-1.181375 z"
          id="path1950-8"
        />
        <path
          css={css`display:inline;fill:#6590db;fill-opacity:0.89411765;stroke-width:0.3257615`}
          d="m 61.279396,60.941534 h 28.79099 c 0.53221,0 0.97858,0.404578 0.97858,0.922442 v 6.101065 c 0,0.501681 -0.4292,0.922442 -0.97858,0.922442 h -28.79099 c -0.53222,0 -0.97859,-0.404581 -0.97859,-0.922442 v -6.101065 c 0,-0.517864 0.4292,-0.922442 0.97859,-0.922442 z"
          id="path1822-7-1"
        />
        <g
          transform="matrix(0.17168155,0,0,0.161832,58.154786,58.918632)"
          css={css`display:inline;fill:#173f7f;stroke-width:1.95436549`}
          id="g1848-9-1"
        >
          <path
            d="m 131.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1828-3-6"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 131.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1830-8-1"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1832-0-1"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1834-2-8"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 H 90.4 C 89,33.2 87.8,32 87.8,30.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1836-4-1"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1838-8-1"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1840-3-5"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1842-9-0"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1844-0-0"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1846-5-8"
            css={css`stroke-width:1.95436549`}
          />
        </g>
        <ellipse
          css={css`display:inline;fill:#70d6f9;stroke-width:0.3257615`}
          cx="69.382812"
          cy="64.825516"
          id="circle1850-2-3"
          rx="1.6824793"
          ry="1.5859536"
        />
        <ellipse
          css={css`display:inline;fill:#ef4460;stroke-width:0.3257615`}
          cx="64.249527"
          cy="64.906433"
          id="circle1852-2-7"
          rx="1.6824793"
          ry="1.5859536"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 90.053226,69.27588 c 0.77256,0 1.40778,-0.598779 1.40778,-1.327023 v -6.084881 c 0,-0.728244 -0.63522,-1.327023 -1.40778,-1.327023 h -28.77383 c -0.77257,0 -1.40779,0.598779 -1.40779,1.327023 v 6.101065 c 0,0.728244 0.63522,1.327024 1.40779,1.327024 h 28.77383 z m -29.32322,-1.327023 v -6.084881 c 0,-0.275116 0.24036,-0.517864 0.54939,-0.517864 h 28.79099 c 0.29186,0 0.54938,0.226566 0.54938,0.517864 v 6.101065 c 0,0.275114 -0.24035,0.517861 -0.54938,0.517861 h -28.79099 c -0.30903,-0.01618 -0.54939,-0.242747 -0.54939,-0.534045 z"
          id="path1926-7-0"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,64.696034 h 1.21894 c 0.48071,0 0.87557,-0.372213 0.87557,-0.825344 v -1.149005 c 0,-0.453131 -0.39486,-0.825344 -0.87557,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0172,-1.974349 c 0,-0.01618 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1928-3-1"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,67.916492 h 1.21894 c 0.48071,0 0.87557,-0.372212 0.87557,-0.825344 v -1.149006 c 0,-0.45313 -0.39486,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0172,-1.97435 c 0,0 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1930-7-3"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,64.696034 h 1.21894 c 0.4807,0 0.87557,-0.372213 0.87557,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87557,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1932-9-4"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,67.916492 h 1.21894 c 0.4807,0 0.87557,-0.372212 0.87557,-0.825344 v -1.149006 c 0,-0.45313 -0.39487,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.97435 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1934-0-3"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,64.696034 h 1.21893 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21893 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1936-2-7"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,67.916492 h 1.21893 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825344 v -1.149006 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21893 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.97435 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1938-3-7"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,64.696034 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1940-9-5"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,67.916492 h 1.21894 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825344 v -1.149006 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.97435 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1942-9-12"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,64.696034 h 1.21894 c 0.48071,0 0.87558,-0.372213 0.87558,-0.825344 v -1.149005 c 0,-0.453131 -0.39487,-0.825344 -0.87558,-0.825344 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825344 v 1.149005 c 0,0.453131 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149005 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1944-7-6"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,67.916492 h 1.21894 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825344 v -1.149006 c 0,-0.45313 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372213 -0.87558,0.825343 v 1.149006 c 0,0.469312 0.39487,0.825344 0.87558,0.825344 z m -0.0171,-1.97435 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1946-0-6"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 69.382756,66.799851 c 1.16744,0 2.11168,-0.890077 2.11168,-1.990534 0,-1.100456 -0.94424,-1.990534 -2.11168,-1.990534 -1.16743,0 -2.11168,0.890078 -2.11168,1.990534 0,1.100457 0.94425,1.990534 2.11168,1.990534 z m 0,-3.155724 c 0.68673,0 1.25328,0.534046 1.25328,1.181375 0,0.647326 -0.56655,1.181371 -1.25328,1.181371 -0.68672,0 -1.25327,-0.534045 -1.25327,-1.181371 0,-0.663514 0.56655,-1.181375 1.25327,-1.181375 z"
          id="path1948-3-5"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 64.249476,66.89695 c 1.16744,0 2.11169,-0.890077 2.11169,-1.990533 0,-1.100457 -0.94425,-1.990534 -2.11169,-1.990534 -1.16743,0 -2.11168,0.906258 -2.11168,1.990534 0,1.100456 0.94425,1.990533 2.11168,1.990533 z m 0,-3.155724 c 0.68673,0 1.25328,0.534046 1.25328,1.181374 0,0.647327 -0.56655,1.181372 -1.25328,1.181372 -0.68672,0 -1.25327,-0.534045 -1.25327,-1.181372 0,-0.663513 0.56655,-1.181374 1.25327,-1.181374 z"
          id="path1950-9-41"
        />
        <path
          css={css`display:inline;fill:#6590db;fill-opacity:0.89411765;stroke-width:0.3257615`}
          d="m 61.279396,68.822759 h 28.79099 c 0.53221,0 0.97858,0.404578 0.97858,0.922442 v 6.101065 c 0,0.50168 -0.4292,0.922441 -0.97858,0.922441 h -28.79099 c -0.53222,0 -0.97859,-0.404581 -0.97859,-0.922441 v -6.101065 c 0,-0.517864 0.4292,-0.922442 0.97859,-0.922442 z"
          id="path1822-8-7"
        />
        <g
          transform="matrix(0.17168155,0,0,0.161832,58.154786,66.799858)"
          css={css`display:inline;fill:#173f7f;stroke-width:1.95436549`}
          id="g1848-6-1"
        >
          <path
            d="m 131.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1828-5-3"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 131.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1830-7-4"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1832-6-8"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 110.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1834-27-6"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 H 90.4 C 89,33.2 87.8,32 87.8,30.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1836-0-1"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 90.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1838-3-5"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1840-9-0"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 151.9,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1842-99-6"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,20.9 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.5 1.2,-2.6 2.6,-2.6 z"
            id="path1844-1-5"
            css={css`stroke-width:1.95436549`}
          />
          <path
            d="m 172.4,40.8 h 7.1 c 1.4,0 2.6,1.2 2.6,2.6 v 7.1 c 0,1.4 -1.2,2.6 -2.6,2.6 h -7.1 c -1.4,0 -2.6,-1.2 -2.6,-2.6 v -7.1 c 0,-1.4 1.2,-2.6 2.6,-2.6 z"
            id="path1846-7-3"
            css={css`stroke-width:1.95436549`}
          />
        </g>
        <ellipse
          css={css`display:inline;fill:#70d6f9;stroke-width:0.3257615`}
          cx="69.382812"
          cy="72.706726"
          id="circle1850-23-1"
          rx="1.6824793"
          ry="1.5859536"
        />
        <ellipse
          css={css`display:inline;fill:#ef4460;stroke-width:0.3257615`}
          cx="64.249527"
          cy="72.787643"
          id="circle1852-6-0"
          rx="1.6824793"
          ry="1.5859536"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 90.053226,77.157102 c 0.77256,0 1.40778,-0.598779 1.40778,-1.327023 v -6.084878 c 0,-0.728244 -0.63522,-1.327023 -1.40778,-1.327023 h -28.77383 c -0.77257,0 -1.40779,0.598779 -1.40779,1.327023 v 6.101065 c 0,0.728244 0.63522,1.327023 1.40779,1.327023 h 28.77383 z m -29.32322,-1.327023 v -6.084878 c 0,-0.275116 0.24036,-0.517864 0.54939,-0.517864 h 28.79099 c 0.29186,0 0.54938,0.226567 0.54938,0.517864 v 6.101065 c 0,0.275117 -0.24035,0.51786 -0.54938,0.51786 h -28.79099 c -0.30903,-0.01615 -0.54939,-0.242743 -0.54939,-0.534047 z"
          id="path1926-5-1"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,72.577259 h 1.21894 c 0.48071,0 0.87557,-0.372212 0.87557,-0.825343 V 70.60291 c 0,-0.453131 -0.39486,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0172,-1.974349 c 0,-0.01618 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1928-5-9"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 80.713746,75.797721 h 1.21894 c 0.48071,0 0.87557,-0.372216 0.87557,-0.825351 v -1.149002 c 0,-0.453131 -0.39486,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0172,-1.974353 c 0,0 0.0172,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01615 z"
          id="path1930-8-5"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,72.577259 h 1.21894 c 0.4807,0 0.87557,-0.372212 0.87557,-0.825343 V 70.60291 c 0,-0.453131 -0.39487,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1932-1-7"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 77.194276,75.797721 h 1.21894 c 0.4807,0 0.87557,-0.372216 0.87557,-0.825351 v -1.149002 c 0,-0.453131 -0.39487,-0.825343 -0.87557,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0171,-1.974353 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21893 c 0,0 -0.0172,0 -0.0172,-0.01615 z"
          id="path1934-4-6"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,72.577259 h 1.21893 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825343 V 70.60291 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21893 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1936-7-8"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 73.674806,75.797721 h 1.21893 c 0.48071,0 0.87558,-0.372216 0.87558,-0.825351 v -1.149002 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21893 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0171,-1.974353 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21893 c 0,0 -0.0171,0 -0.0171,-0.01615 z"
          id="path1938-1-5"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,72.577259 h 1.21894 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825343 V 70.60291 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01618 z"
          id="path1940-3-4"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 84.233216,75.797721 h 1.21894 c 0.48071,0 0.87558,-0.372216 0.87558,-0.825351 v -1.149002 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0171,-1.974353 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21894 c 0,0 -0.0172,0 -0.0172,-0.01615 z"
          id="path1942-8-7"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,72.577259 h 1.21894 c 0.48071,0 0.87558,-0.372212 0.87558,-0.825343 V 70.60291 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149006 c 0,0.453131 0.39487,0.825343 0.87558,0.825343 z m -0.0171,-1.974349 c 0,-0.01618 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149006 c 0,0 0,0.01618 -0.0171,0.01618 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01618 z"
          id="path1944-4-9"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 87.752686,75.797721 h 1.21894 c 0.48071,0 0.87558,-0.372216 0.87558,-0.825351 v -1.149002 c 0,-0.453131 -0.39487,-0.825343 -0.87558,-0.825343 h -1.21894 c -0.48071,0 -0.87558,0.372212 -0.87558,0.825343 v 1.149002 c 0,0.469314 0.39487,0.825351 0.87558,0.825351 z m -0.0171,-1.974353 c 0,0 0.0171,-0.01618 0,0 l 1.23611,-0.01618 c 0,0 0.0171,0 0.0171,0.01618 v 1.149002 c 0,0 0,0.01615 -0.0171,0.01615 h -1.21894 c 0,0 -0.0171,0 -0.0171,-0.01615 z"
          id="path1946-8-6"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 69.382756,74.681074 c 1.16744,0 2.11168,-0.890075 2.11168,-1.990531 0,-1.100457 -0.94424,-1.990534 -2.11168,-1.990534 -1.16743,0 -2.11168,0.890077 -2.11168,1.990534 0,1.100456 0.94425,1.990531 2.11168,1.990531 z m 0,-3.155722 c 0.68673,0 1.25328,0.534046 1.25328,1.181375 0,0.647326 -0.56655,1.181372 -1.25328,1.181372 -0.68672,0 -1.25327,-0.534046 -1.25327,-1.181372 0,-0.663513 0.56655,-1.181375 1.25327,-1.181375 z"
          id="path1948-0-6"
        />
        <path
          css={css`display:inline;stroke-width:0.3257615`}
          d="m 64.249476,74.778173 c 1.16744,0 2.11169,-0.890074 2.11169,-1.990531 0,-1.100456 -0.94425,-1.990534 -2.11169,-1.990534 -1.16743,0 -2.11168,0.906259 -2.11168,1.990534 0,1.100457 0.94425,1.990531 2.11168,1.990531 z m 0,-3.155721 c 0.68673,0 1.25328,0.534045 1.25328,1.181374 0,0.647326 -0.56655,1.18137 -1.25328,1.18137 -0.68672,0 -1.25327,-0.534044 -1.25327,-1.18137 0,-0.663513 0.56655,-1.181374 1.25327,-1.181374 z"
          id="path1950-4-6"
        />
      </g>
    </g>
    <g transform="translate(-144,70)">
      <foreignObject
        width={400}
        height={400}
      >
        <Tooltip text="ALB or Application Load Balancer is a reverse proxy server" width={110} />
      </foreignObject>
    </g>
    <g transform="translate(-21,70)">
      <foreignObject
        width={400}
        height={400}
      >
        <Tooltip text="NLB or Network Load Balancer is a sophisticated network router" width={120} />
      </foreignObject>
    </g>
    <g transform="translate(100,70)">
      <foreignObject
        width={400}
        height={400}
      >
        <Tooltip text="Classic, A legacy option" width={100} />
      </foreignObject>
    </g>
  </svg>

);


export default S3Animation;
