import loadable from '@loadable/component'
import React from 'react';

const DiagramViewerClient = () => {
  const DiagramViewerComponent = loadable(() => import('./DiagramViewerComponent'))
  return (
    <DiagramViewerComponent/>
  );
};

export default DiagramViewerClient;