import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/core';
import useResizeObserver from 'use-resize-observer/polyfilled';


const sideBySide = css`
    display: flex;
`;
const verticalContainer = css`
    display: flex;
    flex-direction: column;
`;

const VerticalStorytelling = ({
  timelineX = 30,
  verticalOffset = 30,
  leftSectionWidth = '100px',
  elements,
  withLineToText = false,
  withCircles = false,
}) => {
  const ref = useRef();
  const { ref: parentRef, width, height } = useResizeObserver();
  const parentPosition = useRef({ x: 0, y: 0 });
  const [positions, setPositions] = useState([]);
  let linePath = '';

  useEffect(() => {
    if (parentRef && parentRef.current) {
      const { x, y } = parentRef.current.getBoundingClientRect();
      parentPosition.current = { x, y };
    }
  }, [parentRef, width, height]);


  useEffect(() => {
    if (ref && ref.current && ref.current.children) {
      const { children } = ref.current;
      const thePositions = Array.from(children).map((child) => {
        const { x, y } = child.getBoundingClientRect();
        return { x: x - parentPosition.current.x, y: y + verticalOffset - parentPosition.current.y };
      });
      setPositions(thePositions);
    }
  }, [ref, parentRef, width, height]);

  const getLineToText = (p) => `M ${timelineX} ${p.y} L ${p.x} ${p.y} `;

  if (positions.length > 1) {
    const [firstPoint, ...otherPoints] = positions;
    linePath = [`M ${timelineX} ${firstPoint.y} `, otherPoints.map((p) => `L ${timelineX} ${p.y} `)].join('');
  }

  return (
    <div ref={parentRef} css={sideBySide}>
      <svg style={{ minWidth: leftSectionWidth }}>
        {withLineToText && positions.map((position) => <path d={getLineToText(position)} stroke="#bbb" />)}
        {withCircles && positions.map((position) => <circle key={position.y} cx={timelineX} cy={position.y} r={9} />)}
        <path d={linePath} stroke="#c3c1c1" />
        {positions.map((position, i) => <g key={i} style={{ transform: `translate(${timelineX}px, ${position.y}px)` }}>{elements[i].title}</g>)}
      </svg>
      <div ref={ref} css={verticalContainer}>
        {elements.map((element, index) => (
          <div key={index}>
            <div>{element.components}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerticalStorytelling;
