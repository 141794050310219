import React from 'react';
import { css } from '@emotion/core';
import * as PropTypes from 'prop-types';
import colors from '../utils/colors';

const labelTypes = {
  like: {
    icon: '👍',
    color: colors.lightGreen,
  },
  avoid: {
    icon: '👎',
    color: colors.red,
  },
  warning: {
    icon: '⚠︎',
    color: colors.yellow,
  },
  important: {
    icon: '🔼️',
    color: colors.lightGreen,
  },
  low: {
    icon: '🔽',
    color: colors.lightGreen,
  },
  choice: {
    icon: '↔️',
    color: '#7cccef',
  },
  scary: {
    icon: '😱',
    color: colors.yellow,
  },
  do: {
    icon: '✅',
    color: colors.lightGreen,
  },
  none: {
    icon: '',
    color: '#eef8fe',
  },
};

const InlineLabel = ({
  label, labelType, icon, color,
}) => {
  const labelStyle = css`
    display: inline-block;
    border-radius: 5px;
    margin-right: 7px;
    padding: 0 7px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    font-family: 'Montserrat';
  `;
  const { icon: myIcon, color: myColor } = labelType ? labelTypes[labelType] : { icon, color };
  return (
    <div
      css={labelStyle}
      style={{
        backgroundColor: myColor,
        color: 'rgba(0,0,0,0.6)',
      }}
    >
      {myIcon}
      {' '}
      {label}
    </div>
  );
};

export default InlineLabel;

InlineLabel.propTypes = {
  label: PropTypes.string.isRequired,
  labelType: PropTypes.oneOf(Object.keys(labelTypes)).isRequired,
};
