import React, { useEffect, useLayoutEffect } from 'react';
import regression from 'regression';
import Slider from '@material-ui/core/Slider';
import { css } from '@emotion/core';
import datanice from './polynome';


const legendColor = css`
  width: 20px;
  height: 20px;
  margin: 5px;
  margin-bottom: -5px;
  margin-right: 20px;
  display: inline-block;
 `;
const legendText = css`
    margin: 5px;
    display: inline;
 `;
const box = css`
  text-align: center;
  height:20px;
  padding:3px;
  width:80px;
  border-radius:2px;
  color:white;
  background-color:#88D498;
  font-weight:bold;
  line-height: 15px;
 `;


const Regularization = () => {
  let barPlotGroup; let plot; let plot2; let
    plot3;

  const changeSlider = (value) => {
    barPlotGroup.sliderChange(value);
  };

  const inita = () => {
    const params = {};
    params.maxX = 5;
    params.maxY = 5;
    params.w = 300;
    params.h = 100;
    params.margin = {};
    params.margin.bottom = 60;
    params.margin.top = 20;
    params.margin.left = 30;
    params.margin.right = 20;


    const data = [[0.8, 0.9], [1, 2], [2.5, 3.2], [4, 3], [5, 3]];

    plot = new datanice.RegularizationPlot('plot1', params, regression.polynomial(data, { order: 1 }));
    plot.addPoints(data);
    plot2 = new datanice.RegularizationPlot('plot2', params, regression.polynomial(data, { order: 2 }));
    plot2.addPoints(data);
    plot3 = new datanice.RegularizationPlot('plot3', params, regression.polynomial(data, { order: 3 }));
    plot3.addPoints(data);

    const lambda = 0;
    const params2 = JSON.parse(JSON.stringify(params));
    params2.maxY = 1.4;
    params2.h = 200;
    params2.margin.bottom = 30;
    params2.margin.top = 40;
    const barPlot = new datanice.RegularizationBarPlot('bar1', params2, data, 1, lambda);
    const barPlot2 = new datanice.RegularizationBarPlot('bar2', params2, data, 2, lambda);
    const barPlot3 = new datanice.RegularizationBarPlot('bar3', params2, data, 3, lambda);
    //
    barPlotGroup = new datanice.BarPlotGroup([barPlot, barPlot2, barPlot3]);
    const resize = () => {
      plot.resize();
      plot2.resize();
      plot3.resize();
      barPlotGroup.resize();
    };
    resize();
  };

  useLayoutEffect(() => {
    const updateSize = () => {
      if (plot) {
        plot.resize();
        plot2.resize();
        plot3.resize();
        barPlotGroup.resize();
      }
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    inita();
  });

  useEffect(() => {
    barPlotGroup.sliderChange(0);
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <h4>Different fitting models</h4>
      <div id="parentContainer" style={{ display: 'flex', textAlign: 'center', alignItems: 'center' }}>
        <div id="plot1" />
        <div id="plot2" />
        <div id="plot3" />
      </div>
      <div className="row">
        <h4 className="merriTitle text-center">Cost function visualization</h4>
      </div>
      <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center' }}>
        <div style={{ marginRight: 80 }}>
          <div css={legendColor} style={{ backgroundColor: '#114B5F' }} />
          <div css={legendText}>Training error</div>
        </div>
        <div>
          <div css={legendColor} style={{ backgroundColor: '#88D498' }} />
          <div css={legendText}>Complexity term</div>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div id="bar1" />
        <div id="bar2" />
        <div id="bar3" />
      </div>
      <div className="row" style={{ marginBottom: 50 }}>
        <div className="jelly-best-target" css={box} style={{ opacity: 0 }}>BEST</div>
      </div>

      <Slider
        defaultValue={0}
        onChange={(ev, value) => changeSlider(value)}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={1}
        marks
        min={0}
        max={20}
      />
    </div>
  );
};
export default Regularization;
