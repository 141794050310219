import React from 'react';
import { css } from '@emotion/core';
import * as PropTypes from 'prop-types';


const Tooltip = ({ text, width, fontSize }) => {
  const tooltipStyle = css`
    display: inline-block;
    border-radius: 5px;
    margin-right: 7px;
    padding: 0 7px;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    font-family: 'Montserrat';
    background-color: #eef8fe;
    text-align: left;
  `;

  return (
    <div
      css={tooltipStyle}
      style={{
        maxWidth: width,
        fontSize: fontSize || null,
        color: 'rgba(0,0,0,0.6)',
      }}
    >
      {text}
    </div>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.number,
  fontSize: PropTypes.number,
};
