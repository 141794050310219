import React from 'react';
import { css } from '@emotion/core';

import { motion } from 'framer-motion';
import Tooltip from './Tooltip';


const Ec2Animation = () => {
  const bigCloud = {
    animation: { scale: [1, 1.3] },
    transition: {
      duration: 2, times: [0, 1], yoyo: Infinity,
    },
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg1234"
      viewBox="0 0 347.90637 146.06736"
      height="70.06735mm"
      width="100.90637mm"
    >
      <defs
        id="defs1228"
      />
      <g
        id="layer2"
      >
        <motion.g
          id="g6488"
          animate={bigCloud.animation}
          transition={bigCloud.transition}
        >
          <path
            css={css`fill:none;fill-opacity:1;stroke:#1a1d74;stroke-width:1.05833328;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;`}
            d="m 204.07084,23.74078 c 8.8e-4,-0.07831 0.007,-0.155212 0.007,-0.233801 -6e-5,-10.940922 -9.37807,-19.8101957 -20.94589,-19.8101957 -6.48873,0 -12.28749,2.791295 -16.12968,7.1728847 -2.06102,-0.8804717 -4.34135,-1.3820347 -6.74958,-1.3820347 -8.55211,0 -15.57264,6.1798627 -16.3561,14.0746387 -0.38015,-0.03341 -0.76327,-0.05529 -1.15262,-0.05529 -6.82229,-4e-6 -12.35277,5.230623 -12.35277,11.683019 0,6.452397 5.53048,11.683023 12.35277,11.683023 h 58.86296 c 6.82229,0 12.35277,-5.230626 12.35277,-11.683023 3e-4,-5.654162 -4.24668,-10.369191 -9.88814,-11.449222 z"
            id="path5158"
          />
          <g
            id="g5166"
            transform="matrix(0.31266622,0,0,0.29237168,96.277332,-29.144477)"
            css={css`fill:#eef8fe;fill-opacity:1;`}
          >
            <path
              css={css`fill:#eef8fe;fill-opacity:1;`}
              d="m 193.64957,144.26209 c 6.395,0 12.63,1.35 18.53,4.015 l 7.115,3.213 4.98,-6.008 c 1.361,-1.641 2.811,-3.186 4.318,-4.658 l -6.173,-2.787 c -5.9,-2.665 -12.135,-4.015 -18.53,-4.015 -15.08,0 -28.428,7.308 -36.626,18.715 7.428,-5.337 16.534,-8.475 26.386,-8.475 z"
              id="path5160"
            />
            <path
              css={css`fill:#eef8fe;fill-opacity:1;`}
              d="m 136.347,196.02 c 0.985,0 1.96,0.062 2.925,0.153 l 10.195,0.955 0.955,-10.192 c 0.029,-0.307 0.093,-0.601 0.127,-0.905 l -1.037,-0.097 c -0.965,-0.09 -1.94,-0.153 -2.925,-0.153 -11.273,0 -21.148,5.994 -26.682,14.943 4.789,-2.964 10.41,-4.704 16.442,-4.704 z"
              id="path5162"
            />
            <path
              css={css`fill:#eef8fe;fill-opacity:1;`}
              d="m 349.51457,186.412 -8.36,-1.692 0.175,-9.18 c 0,-33.272 -27.07,-60.34 -60.345,-60.34 -18.015,0 -34.95,7.965 -46.47,21.853 l -1.18,1.423 c 10.564,-8.369 23.663,-13.036 37.41,-13.036 33.275,0 60.345,27.068 60.345,60.34 l -0.175,9.18 8.36,1.692 c 14.549,2.945 25.115,15.878 25.115,30.753 0,6.032 -1.74,11.653 -4.702,16.442 8.947,-5.535 14.942,-15.41 14.942,-26.682 0,-14.875 -10.565,-27.808 -25.115,-30.753 z"
              id="path5164"
            />
          </g>
        </motion.g>
        <path
          id="path5168"
          d="m 196.50842,32.426494 h -48.66678 c -0.99356,0 -1.79868,0.761747 -1.79868,1.70116 V 68.264255 H 198.3071 V 34.127374 c 0,-0.939414 -0.80512,-1.70088 -1.79868,-1.70088 z"
          css={css`fill:#1a1d74;fill-opacity:1;stroke-width:0.28860605" /;`}
        />
        <polygon
          transform="matrix(0.29676313,0,0,0.28067323,99.242527,-25.762679)"
          id="polygon5170"
          points="168.237,217.855 168.237,324.468 323.279,324.468 "
          css={css`fill:#eef8fe;fill-opacity:1" /;`}
        />
        <polygon
          transform="matrix(0.29676313,0,0,0.28067323,99.242527,-25.762679)"
          id="polygon5172"
          points="168.237,217.855 323.279,324.468 323.279,217.855 "
          css={css`fill:#eef8fe;fill-opacity:1" /;`}
        />
        <path
          id="path5174"
          d="M 204.41389,80.969771 198.3071,68.263974 h -52.26414 l -6.1068,12.705797 c -0.39736,0.8263 0.24158,1.76459 1.20131,1.76459 h 62.07512 c 0.95973,0 1.59866,-0.93829 1.2013,-1.76459 z"
          css={css`fill:none;stroke:#1a1d74;stroke-width:1.05833328;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" /;`}
        />
        <polygon
          transform="matrix(0.29676313,0,0,0.28067323,99.242527,-25.762679)"
          id="polygon5176"
          points="164.486,345.537 327.031,345.537 340.891,376.028 150.626,376.028 "
          css={css`fill:#b7ddf9;fill-opacity:1" /;`}
        />
      </g>
      <g transform="translate(-150,7)">
        <foreignObject
          width={400}
          height={400}
        >
          <Tooltip text="A computer in the cloud, similar to your local one" width={100} />
        </foreignObject>
      </g>
      <g transform="translate(95,0)">
        <foreignObject
          width={400}
          height={400}
        >
          <Tooltip text="Pick a server type, don't bother with machine configs" width={100} />
        </foreignObject>
      </g>
      <g transform="translate(95,98)">
        <foreignObject
          width={400}
          height={400}
        >
          <Tooltip text="Pay for running time" width={100} />
        </foreignObject>
      </g>
    </svg>

  );
};

export default Ec2Animation;
