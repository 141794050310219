import React from 'react';
import { css } from '@emotion/core';


const LinkHighlight = css`
    color: black;
    box-shadow: none;
    text-decoration : none;
    background-position-y: -0%;
    background-image: linear-gradient( transparent 50%, rgb(255, 205, 0) 50%);
    transition: background 500ms ease;
    background-size: 2px;
    background-size: auto 175%;
    &:link {
        color: black;
        box-shadow: none;
        text-decoration : none;
        background-position-y: -0%;
        background-image: linear-gradient( transparent 50%, gold 50%);
        transition: background 500ms ease;
        background-size: 2px;
        background-size: auto 175%;
    }
    &:hover {
        background-position-y: 100%;
    }
`;
const TwitterHighlight = ({ text, tweetText, tweetUrl }) => {
  const linkProps = {
    href: `https://twitter.com/intent/tweet?text=${tweetText}${tweetUrl ? `&url=${tweetUrl}` : ''}`,

  };
  return (
    <>
      <a css={LinkHighlight} {...linkProps} target="blank">{text}</a>
    </>
  );
};


export default TwitterHighlight;
