import React from 'react';
import { css } from '@emotion/core';
import { motion } from 'framer-motion';
import Tooltip from './Tooltip';


const S3Animation = () => {
  const circleAnimation = {
    animation: { rx: [8, 12], ry: [8, 12] },
    transition: {
      duration: 2, times: [0, 1], yoyo: Infinity,
    },
  };

  const showArrow = {
    animation: { opacity: [0, 1] },
    transition: {
      duration: 8, times: [0.08, 0.16], loop: Infinity,
    },
  };
  const lineArrow = {
    animation: { pathOffset: [1, 0], pathLength: [0, 1] },
    transition: {
      duration: 8, times: [0, 0.1], loop: Infinity,
    },
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg1234"
      viewBox="0 0 347.90637 146.06736"
      height="70.06735mm"
      width="100.90637mm"
    >
      <defs
        id="defs1228"
      />
      <g
        transform="translate(0,10.050628)"
        id="layer7"
      >
        <path
          css={css`fill:#fcf7f9;fill-opacity:1;stroke:#ed3e4b;stroke-width:1.71733618;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`}
          d="m 138.94762,18.8094 65.69213,-1.784113 -6.61614,58.923736 c 0,0 -6.35752,6.324113 -26.00938,6.467407 -19.65186,0.143282 -23.80401,-5.575351 -23.80401,-5.575351 z"
          id="rect967"
        />
        <path
          css={css`fill:#f7bad6;fill-opacity:0.56796115;stroke:none;stroke-width:1.71733618;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`}
          d="M 203.78586,16.161812 188.3602,15.693904 c 2.84232,19.893329 1.06569,63.665531 1.06569,63.665531 8.59772,-2.868887 7.74401,-4.273874 7.74401,-4.273874 z"
          id="rect967-7"
        />
        <ellipse
          css={css`fill:#f7bad6;fill-opacity:1;stroke:#ed3e4b;stroke-width:1.71733618;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`}
          id="path965"
          cx="171.95839"
          cy="17.412046"
          rx="33.060093"
          ry="11.66941"
        />
        <motion.ellipse
          animate={circleAnimation.animation}
          transition={circleAnimation.transition}
          ry="7.8459482"
          rx="7.9442272"
          cy="3.3902562"
          cx="171.547"
          id="path1317"
          css={css`display:inline;opacity:1;fill:#ffcd00;fill-opacity:1;stroke:#ed3e4b;stroke-width:1.84620142;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`}
        />
        <g
          id="g1157"
          transform="matrix(1,0,0,-1,38.465324,90.129611)"
        >
          <g
            id="g4648"
          >
            <motion.path
              animate={showArrow.animation}
              transition={showArrow.transition}
              css={css`fill:none;fill-opacity:0.56796115;stroke:#1a1d74;stroke-width:1.63377464;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`}
              id="path1113-2"
              d="m -24.558638,-35.949581 -7.589513,-3.540335 -7.589513,-3.540335 6.860777,-4.802543 6.860776,-4.802544 0.728737,8.342879 z"
              transform="matrix(0.24876072,0.34063,0.34935289,-0.2425495,95.860054,59.31029)"
            />
            <motion.path
              animate={lineArrow.animation}
              transition={lineArrow.transition}
              css={css`fill:#1a1d74;fill-opacity:1;stroke:#1a1d74;stroke-width:1.05833328;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1`}
              d="M 68.575044,59.77638 H -19.910318"
              id="path4644"
            />
          </g>
        </g>
        <g
          id="g1157-7"
          transform="rotate(90,82.340599,143.83908)"
          css={css`stroke:#3eb39a;stroke-opacity:1`}
        >
          <g
            transform="translate(6.9581273)"
            id="g1253"
            css={css`stroke:#3eb39a;stroke-opacity:1`}
          >
            <motion.path
              animate={showArrow.animation}
              transition={showArrow.transition}
              css={css`fill:none;fill-opacity:0.56796115;stroke:#3eb39a;stroke-width:1.63377464;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`}
              id="path1113-2-9"
              d="m -24.558638,-35.949581 -7.589513,-3.540335 -7.589513,-3.540335 6.860777,-4.802543 6.860776,-4.802544 0.728737,8.342879 z"
              transform="matrix(0.24876072,0.34063,0.34935289,-0.2425495,83.184627,66.04411)"
            />
            <motion.path
              animate={lineArrow.animation}
              transition={lineArrow.transition}
              css={css`fill:none;stroke:#3eb39a;stroke-width:1.1879704;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1`}
              d="M 56.28618,66.510199 H 18.996344"
              id="path1115-4-0"
            />
          </g>
        </g>
        <g
          id="g1157-7-7"
          transform="rotate(90,87.864549,149.44595)"
          css={css`stroke:#3eb39a;stroke-opacity:1`}
        >
          <g
            transform="translate(6.9581273)"
            id="g1253-9"
            css={css`stroke:#3eb39a;stroke-opacity:1`}
          >
            <motion.path
              animate={showArrow.animation}
              transition={showArrow.transition}
              css={css`fill:none;fill-opacity:0.56796115;stroke:#3eb39a;stroke-width:1.63377464;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`}
              id="path1113-2-9-3"
              d="m -24.558638,-35.949581 -7.589513,-3.540335 -7.589513,-3.540335 6.860777,-4.802543 6.860776,-4.802544 0.728737,8.342879 z"
              transform="matrix(0.24876072,0.34063,0.34935289,-0.2425495,83.184627,66.04411)"
            />
            <motion.path
              animate={lineArrow.animation}
              transition={lineArrow.transition}
              css={css`fill:none;stroke:#3eb39a;stroke-width:1.1879704;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1`}
              d="M 56.28618,66.510199 H 18.996344"
              id="path1115-4-0-8"
            />
          </g>
        </g>
        <g
          id="g1157-7-0"
          transform="rotate(90,93.662989,155.2444)"
          css={css`stroke:#3eb39a;stroke-opacity:1`}
        >
          <g
            transform="translate(6.9581273)"
            id="g1253-2"
            css={css`stroke:#3eb39a;stroke-opacity:1`}
          >
            <motion.path
              animate={showArrow.animation}
              transition={showArrow.transition}
              css={css`fill:none;fill-opacity:0.56796115;stroke:#3eb39a;stroke-width:1.63377464;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`}
              id="path1113-2-9-4"
              d="m -24.558638,-35.949581 -7.589513,-3.540335 -7.589513,-3.540335 6.860777,-4.802543 6.860776,-4.802544 0.728737,8.342879 z"
              transform="matrix(0.24876072,0.34063,0.34935289,-0.2425495,83.184627,66.04411)"
            />
            <motion.path
              animate={lineArrow.animation}
              transition={lineArrow.transition}
              css={css`fill:none;stroke:#3eb39a;stroke-width:1.1879704;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1`}
              d="M 56.28618,66.510199 H 18.996344"
              id="path1115-4-0-83"
            />
          </g>
        </g>
        <g
          id="g1157-9"
          transform="matrix(1,0,0,-1,39.430548,104.11738)"
        >
          <g
            id="g4648-0"
          >
            <motion.path
              animate={showArrow.animation}
              transition={showArrow.transition}
              css={css`fill:none;fill-opacity:0.56796115;stroke:#1a1d74;stroke-width:1.63377464;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`}
              id="path1113-2-5"
              d="m -24.558638,-35.949581 -7.589513,-3.540335 -7.589513,-3.540335 6.860777,-4.802543 6.860776,-4.802544 0.728737,8.342879 z"
              transform="matrix(0.24876072,0.34063,0.34935289,-0.2425495,95.860054,59.31029)"
            />
            <motion.path
              animate={lineArrow.animation}
              transition={lineArrow.transition}
              css={css`fill:#1a1d74;fill-opacity:1;stroke:#1a1d74;stroke-width:1.05833328;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1`}
              d="M 68.575044,59.77638 H -19.910318"
              id="path4644-2"
            />
          </g>
        </g>
        <g
          id="g1157-2"
          transform="matrix(1,0,0,-1,38.657423,117.64707)"
        >
          <g
            id="g4648-7"
          >
            <motion.path
              animate={showArrow.animation}
              transition={showArrow.transition}
              css={css`fill:none;fill-opacity:0.56796115;stroke:#1a1d74;stroke-width:1.63377464;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`}
              id="path1113-2-3"
              d="m -24.558638,-35.949581 -7.589513,-3.540335 -7.589513,-3.540335 6.860777,-4.802543 6.860776,-4.802544 0.728737,8.342879 z"
              transform="matrix(0.24876072,0.34063,0.34935289,-0.2425495,95.860054,59.31029)"
            />
            <motion.path
              animate={lineArrow.animation}
              transition={lineArrow.transition}
              css={css`fill:#1a1d74;fill-opacity:1;stroke:#1a1d74;stroke-width:1.05833328;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1`}
              d="M 68.575044,59.77638 H -19.910318"
              id="path4644-7"
            />
          </g>
        </g>
        <g
          id="g1157-90"
          transform="matrix(1,0,0,-1,38.800027,130.79021)"
        >
          <g
            id="g4648-2"
          >
            <motion.path
              animate={showArrow.animation}
              transition={showArrow.transition}
              css={css`fill:none;fill-opacity:0.56796115;stroke:#1a1d74;stroke-width:1.63377464;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`}
              id="path1113-2-39"
              d="m -24.558638,-35.949581 -7.589513,-3.540335 -7.589513,-3.540335 6.860777,-4.802543 6.860776,-4.802544 0.728737,8.342879 z"
              transform="matrix(0.24876072,0.34063,0.34935289,-0.2425495,95.860054,59.31029)"
            />
            <motion.path
              animate={lineArrow.animation}
              transition={lineArrow.transition}
              css={css`fill:#1a1d74;fill-opacity:1;stroke:#1a1d74;stroke-width:1.05833328;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1`}
              d="M 68.575044,59.77638 H -19.910318"
              id="path4644-9"
            />
          </g>
        </g>
      </g>
      <g transform="translate(-130,100)">
        <foreignObject
          width={400}
          height={400}
        >
          <Tooltip text="As many parallel uploads and downloads as you want" width={100} />
        </foreignObject>
      </g>
      <g transform="translate(95,0)">
        <foreignObject
          width={400}
          height={400}
        >
          <Tooltip text="Consider using S3 when storing anything" width={100} />
        </foreignObject>
      </g>
      <g transform="translate(95,90)">
        <foreignObject
          width={400}
          height={400}
        >
          <Tooltip text="No capacity management" width={100} />
        </foreignObject>
      </g>
    </svg>
  );
};


export default S3Animation;
